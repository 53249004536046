.tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: $size12;

  .tabs-buttons {
    display: flex;
    align-items: center;
    margin-bottom: $size80;

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    > div {
      padding-top: $size4;
      padding-bottom: $size4;
      padding-left: $size12;
      padding-right: $size12;
      transition: 0.3s all;
      font-size: $size14;
      position: relative;
      cursor: pointer;
      transform: translateY(0);

      span {
        transform: translateX(0);
        display: inline-block;
        transition: 0.8s all;
      }

      &:hover {
        span {
          transform: translateX(10px);
        }
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: $primary;
        transition: 0.3s all;
      }

      &.active {
        font-weight: bold;

        &:before {
          width: 2px;
        }
      }
    }
  }

  .tab-content {
    overflow: auto;
    position: relative;

    a {
      text-decoration: underline !important;
    }
  }

  &.has-dropdown {
    .tabs-buttons {
      display: none;
    }
    .tabs-drodown {
      display: block;
      width: $size224;
    }
  }
}

@include media-breakpoint-up(md) {
  .tabs {
    &.has-dropdown {
      .tabs-buttons {
        display: flex;
      }
      .tabs-drodown {
        display: none;
      }
    }
  }
}
