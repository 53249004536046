/* Colors */
/* Spacers */
/* Sizes */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #444444;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1e1e1e;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 767px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 1281px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1400px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12, .col-xxs,
.col-xxs-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-ssm-1, .col-ssm-2, .col-ssm-3, .col-ssm-4, .col-ssm-5, .col-ssm-6, .col-ssm-7, .col-ssm-8, .col-ssm-9, .col-ssm-10, .col-ssm-11, .col-ssm-12, .col-ssm,
.col-ssm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-xxs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xxs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxs-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxs-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxs-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxs-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxs-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxs-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxs-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxs-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxs-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxs-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxs-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxs-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxs-first {
    order: -1; }
  .order-xxs-last {
    order: 13; }
  .order-xxs-0 {
    order: 0; }
  .order-xxs-1 {
    order: 1; }
  .order-xxs-2 {
    order: 2; }
  .order-xxs-3 {
    order: 3; }
  .order-xxs-4 {
    order: 4; }
  .order-xxs-5 {
    order: 5; }
  .order-xxs-6 {
    order: 6; }
  .order-xxs-7 {
    order: 7; }
  .order-xxs-8 {
    order: 8; }
  .order-xxs-9 {
    order: 9; }
  .order-xxs-10 {
    order: 10; }
  .order-xxs-11 {
    order: 11; }
  .order-xxs-12 {
    order: 12; }
  .offset-xxs-0 {
    margin-left: 0; }
  .offset-xxs-1 {
    margin-left: 8.33333%; }
  .offset-xxs-2 {
    margin-left: 16.66667%; }
  .offset-xxs-3 {
    margin-left: 25%; }
  .offset-xxs-4 {
    margin-left: 33.33333%; }
  .offset-xxs-5 {
    margin-left: 41.66667%; }
  .offset-xxs-6 {
    margin-left: 50%; }
  .offset-xxs-7 {
    margin-left: 58.33333%; }
  .offset-xxs-8 {
    margin-left: 66.66667%; }
  .offset-xxs-9 {
    margin-left: 75%; }
  .offset-xxs-10 {
    margin-left: 83.33333%; }
  .offset-xxs-11 {
    margin-left: 91.66667%; } }

@media (min-width: 767px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-ssm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-ssm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-ssm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-ssm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-ssm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-ssm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-ssm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-ssm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-ssm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-ssm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-ssm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-ssm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-ssm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-ssm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-ssm-first {
    order: -1; }
  .order-ssm-last {
    order: 13; }
  .order-ssm-0 {
    order: 0; }
  .order-ssm-1 {
    order: 1; }
  .order-ssm-2 {
    order: 2; }
  .order-ssm-3 {
    order: 3; }
  .order-ssm-4 {
    order: 4; }
  .order-ssm-5 {
    order: 5; }
  .order-ssm-6 {
    order: 6; }
  .order-ssm-7 {
    order: 7; }
  .order-ssm-8 {
    order: 8; }
  .order-ssm-9 {
    order: 9; }
  .order-ssm-10 {
    order: 10; }
  .order-ssm-11 {
    order: 11; }
  .order-ssm-12 {
    order: 12; }
  .offset-ssm-0 {
    margin-left: 0; }
  .offset-ssm-1 {
    margin-left: 8.33333%; }
  .offset-ssm-2 {
    margin-left: 16.66667%; }
  .offset-ssm-3 {
    margin-left: 25%; }
  .offset-ssm-4 {
    margin-left: 33.33333%; }
  .offset-ssm-5 {
    margin-left: 41.66667%; }
  .offset-ssm-6 {
    margin-left: 50%; }
  .offset-ssm-7 {
    margin-left: 58.33333%; }
  .offset-ssm-8 {
    margin-left: 66.66667%; }
  .offset-ssm-9 {
    margin-left: 75%; }
  .offset-ssm-10 {
    margin-left: 83.33333%; }
  .offset-ssm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1281px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1400px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #444444;
  border-color: #444444; }
  .btn-primary:hover {
    color: #fff;
    background-color: #313131;
    border-color: #2b2b2b; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(96, 96, 96, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2b2b2b;
    border-color: #242424; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 96, 96, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #444444;
  border-color: #444444; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #444444;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #444444;
    border-color: #444444; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 68, 68, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #444444;
  text-decoration: none; }
  .btn-link:hover {
    color: #1e1e1e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-xxs-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxs-right {
    right: 0;
    left: auto; } }

@media (min-width: 767px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-ssm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-ssm-right {
    right: 0;
    left: auto; } }

@media (min-width: 1281px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #444444; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 0.5rem; }
  @media (min-width: 767px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -0.5rem;
      margin-left: -0.5rem; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 0.5rem;
        margin-bottom: 0;
        margin-left: 0.5rem; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 0.5rem; }
  @media (min-width: 767px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 767px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #444444;
    border-color: #444444; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-xxs {
    flex-direction: row; }
    .list-group-horizontal-xxs .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xxs .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xxs .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 767px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-ssm {
    flex-direction: row; }
    .list-group-horizontal-ssm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-ssm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-ssm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1281px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1400px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1600px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #232323;
  background-color: #cbcbcb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #232323;
    background-color: #bebebe; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #232323;
    border-color: #232323; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 767px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1400px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #444444 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2b2b2b !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #444444 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex, .modal-open .modal {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-xxs-none {
    display: none !important; }
  .d-xxs-inline {
    display: inline !important; }
  .d-xxs-inline-block {
    display: inline-block !important; }
  .d-xxs-block {
    display: block !important; }
  .d-xxs-table {
    display: table !important; }
  .d-xxs-table-row {
    display: table-row !important; }
  .d-xxs-table-cell {
    display: table-cell !important; }
  .d-xxs-flex {
    display: flex !important; }
  .d-xxs-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 767px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-ssm-none {
    display: none !important; }
  .d-ssm-inline {
    display: inline !important; }
  .d-ssm-inline-block {
    display: inline-block !important; }
  .d-ssm-block {
    display: block !important; }
  .d-ssm-table {
    display: table !important; }
  .d-ssm-table-row {
    display: table-row !important; }
  .d-ssm-table-cell {
    display: table-cell !important; }
  .d-ssm-flex {
    display: flex !important; }
  .d-ssm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1281px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1400px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end, .modal-header {
  justify-content: flex-end !important; }

.justify-content-center, .modal-open .modal {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center, .modal-open .modal {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-xxs-row {
    flex-direction: row !important; }
  .flex-xxs-column {
    flex-direction: column !important; }
  .flex-xxs-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxs-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxs-wrap {
    flex-wrap: wrap !important; }
  .flex-xxs-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxs-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxs-fill {
    flex: 1 1 auto !important; }
  .flex-xxs-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxs-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxs-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxs-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxs-start {
    justify-content: flex-start !important; }
  .justify-content-xxs-end {
    justify-content: flex-end !important; }
  .justify-content-xxs-center {
    justify-content: center !important; }
  .justify-content-xxs-between {
    justify-content: space-between !important; }
  .justify-content-xxs-around {
    justify-content: space-around !important; }
  .align-items-xxs-start {
    align-items: flex-start !important; }
  .align-items-xxs-end {
    align-items: flex-end !important; }
  .align-items-xxs-center {
    align-items: center !important; }
  .align-items-xxs-baseline {
    align-items: baseline !important; }
  .align-items-xxs-stretch {
    align-items: stretch !important; }
  .align-content-xxs-start {
    align-content: flex-start !important; }
  .align-content-xxs-end {
    align-content: flex-end !important; }
  .align-content-xxs-center {
    align-content: center !important; }
  .align-content-xxs-between {
    align-content: space-between !important; }
  .align-content-xxs-around {
    align-content: space-around !important; }
  .align-content-xxs-stretch {
    align-content: stretch !important; }
  .align-self-xxs-auto {
    align-self: auto !important; }
  .align-self-xxs-start {
    align-self: flex-start !important; }
  .align-self-xxs-end {
    align-self: flex-end !important; }
  .align-self-xxs-center {
    align-self: center !important; }
  .align-self-xxs-baseline {
    align-self: baseline !important; }
  .align-self-xxs-stretch {
    align-self: stretch !important; } }

@media (min-width: 767px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-ssm-row {
    flex-direction: row !important; }
  .flex-ssm-column {
    flex-direction: column !important; }
  .flex-ssm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-ssm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-ssm-wrap {
    flex-wrap: wrap !important; }
  .flex-ssm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-ssm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-ssm-fill {
    flex: 1 1 auto !important; }
  .flex-ssm-grow-0 {
    flex-grow: 0 !important; }
  .flex-ssm-grow-1 {
    flex-grow: 1 !important; }
  .flex-ssm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-ssm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-ssm-start {
    justify-content: flex-start !important; }
  .justify-content-ssm-end {
    justify-content: flex-end !important; }
  .justify-content-ssm-center {
    justify-content: center !important; }
  .justify-content-ssm-between {
    justify-content: space-between !important; }
  .justify-content-ssm-around {
    justify-content: space-around !important; }
  .align-items-ssm-start {
    align-items: flex-start !important; }
  .align-items-ssm-end {
    align-items: flex-end !important; }
  .align-items-ssm-center {
    align-items: center !important; }
  .align-items-ssm-baseline {
    align-items: baseline !important; }
  .align-items-ssm-stretch {
    align-items: stretch !important; }
  .align-content-ssm-start {
    align-content: flex-start !important; }
  .align-content-ssm-end {
    align-content: flex-end !important; }
  .align-content-ssm-center {
    align-content: center !important; }
  .align-content-ssm-between {
    align-content: space-between !important; }
  .align-content-ssm-around {
    align-content: space-around !important; }
  .align-content-ssm-stretch {
    align-content: stretch !important; }
  .align-self-ssm-auto {
    align-self: auto !important; }
  .align-self-ssm-start {
    align-self: flex-start !important; }
  .align-self-ssm-end {
    align-self: flex-end !important; }
  .align-self-ssm-center {
    align-self: center !important; }
  .align-self-ssm-baseline {
    align-self: baseline !important; }
  .align-self-ssm-stretch {
    align-self: stretch !important; } }

@media (min-width: 1281px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1400px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-xxs-left {
    float: left !important; }
  .float-xxs-right {
    float: right !important; }
  .float-xxs-none {
    float: none !important; } }

@media (min-width: 767px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-ssm-left {
    float: left !important; }
  .float-ssm-right {
    float: right !important; }
  .float-ssm-none {
    float: none !important; } }

@media (min-width: 1281px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1400px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 1.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important; }

.m-4 {
  margin: 2rem !important; }

.mt-4,
.my-4 {
  margin-top: 2rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2rem !important; }

.m-5 {
  margin: 2.5rem !important; }

.mt-5,
.my-5 {
  margin-top: 2.5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2.5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2.5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2.5rem !important; }

.m-6 {
  margin: 3rem !important; }

.mt-6,
.my-6 {
  margin-top: 3rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3rem !important; }

.m-7 {
  margin: 3.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 3.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 3.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 3.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 3.5rem !important; }

.m-8 {
  margin: 4rem !important; }

.mt-8,
.my-8 {
  margin-top: 4rem !important; }

.mr-8,
.mx-8 {
  margin-right: 4rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 4rem !important; }

.ml-8,
.mx-8 {
  margin-left: 4rem !important; }

.m-9 {
  margin: 4.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 4.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 4.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 4.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 4.5rem !important; }

.m-10 {
  margin: 5rem !important; }

.mt-10,
.my-10 {
  margin-top: 5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 5rem !important; }

.m-11 {
  margin: 5.5rem !important; }

.mt-11,
.my-11 {
  margin-top: 5.5rem !important; }

.mr-11,
.mx-11 {
  margin-right: 5.5rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 5.5rem !important; }

.ml-11,
.mx-11 {
  margin-left: 5.5rem !important; }

.m-12 {
  margin: 6rem !important; }

.mt-12,
.my-12 {
  margin-top: 6rem !important; }

.mr-12,
.mx-12 {
  margin-right: 6rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 6rem !important; }

.ml-12,
.mx-12 {
  margin-left: 6rem !important; }

.m-13 {
  margin: 6.5rem !important; }

.mt-13,
.my-13 {
  margin-top: 6.5rem !important; }

.mr-13,
.mx-13 {
  margin-right: 6.5rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 6.5rem !important; }

.ml-13,
.mx-13 {
  margin-left: 6.5rem !important; }

.m-14 {
  margin: 7rem !important; }

.mt-14,
.my-14 {
  margin-top: 7rem !important; }

.mr-14,
.mx-14 {
  margin-right: 7rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 7rem !important; }

.ml-14,
.mx-14 {
  margin-left: 7rem !important; }

.m-15 {
  margin: 7.5rem !important; }

.mt-15,
.my-15 {
  margin-top: 7.5rem !important; }

.mr-15,
.mx-15 {
  margin-right: 7.5rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 7.5rem !important; }

.ml-15,
.mx-15 {
  margin-left: 7.5rem !important; }

.m-16 {
  margin: 8rem !important; }

.mt-16,
.my-16 {
  margin-top: 8rem !important; }

.mr-16,
.mx-16 {
  margin-right: 8rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 8rem !important; }

.ml-16,
.mx-16 {
  margin-left: 8rem !important; }

.m-17 {
  margin: 8.5rem !important; }

.mt-17,
.my-17 {
  margin-top: 8.5rem !important; }

.mr-17,
.mx-17 {
  margin-right: 8.5rem !important; }

.mb-17,
.my-17 {
  margin-bottom: 8.5rem !important; }

.ml-17,
.mx-17 {
  margin-left: 8.5rem !important; }

.m-18 {
  margin: 9rem !important; }

.mt-18,
.my-18 {
  margin-top: 9rem !important; }

.mr-18,
.mx-18 {
  margin-right: 9rem !important; }

.mb-18,
.my-18 {
  margin-bottom: 9rem !important; }

.ml-18,
.mx-18 {
  margin-left: 9rem !important; }

.m-19 {
  margin: 9.5rem !important; }

.mt-19,
.my-19 {
  margin-top: 9.5rem !important; }

.mr-19,
.mx-19 {
  margin-right: 9.5rem !important; }

.mb-19,
.my-19 {
  margin-bottom: 9.5rem !important; }

.ml-19,
.mx-19 {
  margin-left: 9.5rem !important; }

.m-20 {
  margin: 10rem !important; }

.mt-20,
.my-20 {
  margin-top: 10rem !important; }

.mr-20,
.mx-20 {
  margin-right: 10rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 10rem !important; }

.ml-20,
.mx-20 {
  margin-left: 10rem !important; }

.m-21 {
  margin: 10.5rem !important; }

.mt-21,
.my-21 {
  margin-top: 10.5rem !important; }

.mr-21,
.mx-21 {
  margin-right: 10.5rem !important; }

.mb-21,
.my-21 {
  margin-bottom: 10.5rem !important; }

.ml-21,
.mx-21 {
  margin-left: 10.5rem !important; }

.m-26 {
  margin: 13rem !important; }

.mt-26,
.my-26 {
  margin-top: 13rem !important; }

.mr-26,
.mx-26 {
  margin-right: 13rem !important; }

.mb-26,
.my-26 {
  margin-bottom: 13rem !important; }

.ml-26,
.mx-26 {
  margin-left: 13rem !important; }

.m-28 {
  margin: 14rem !important; }

.mt-28,
.my-28 {
  margin-top: 14rem !important; }

.mr-28,
.mx-28 {
  margin-right: 14rem !important; }

.mb-28,
.my-28 {
  margin-bottom: 14rem !important; }

.ml-28,
.mx-28 {
  margin-left: 14rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 1.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.5rem !important; }

.p-4 {
  padding: 2rem !important; }

.pt-4,
.py-4 {
  padding-top: 2rem !important; }

.pr-4,
.px-4 {
  padding-right: 2rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2rem !important; }

.pl-4,
.px-4 {
  padding-left: 2rem !important; }

.p-5 {
  padding: 2.5rem !important; }

.pt-5,
.py-5 {
  padding-top: 2.5rem !important; }

.pr-5,
.px-5 {
  padding-right: 2.5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2.5rem !important; }

.pl-5,
.px-5 {
  padding-left: 2.5rem !important; }

.p-6 {
  padding: 3rem !important; }

.pt-6,
.py-6 {
  padding-top: 3rem !important; }

.pr-6,
.px-6 {
  padding-right: 3rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3rem !important; }

.pl-6,
.px-6 {
  padding-left: 3rem !important; }

.p-7 {
  padding: 3.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 3.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 3.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 3.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 3.5rem !important; }

.p-8 {
  padding: 4rem !important; }

.pt-8,
.py-8 {
  padding-top: 4rem !important; }

.pr-8,
.px-8 {
  padding-right: 4rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 4rem !important; }

.pl-8,
.px-8 {
  padding-left: 4rem !important; }

.p-9 {
  padding: 4.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 4.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 4.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 4.5rem !important; }

.pl-9,
.px-9 {
  padding-left: 4.5rem !important; }

.p-10 {
  padding: 5rem !important; }

.pt-10,
.py-10 {
  padding-top: 5rem !important; }

.pr-10,
.px-10 {
  padding-right: 5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 5rem !important; }

.pl-10,
.px-10 {
  padding-left: 5rem !important; }

.p-11 {
  padding: 5.5rem !important; }

.pt-11,
.py-11 {
  padding-top: 5.5rem !important; }

.pr-11,
.px-11 {
  padding-right: 5.5rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 5.5rem !important; }

.pl-11,
.px-11 {
  padding-left: 5.5rem !important; }

.p-12 {
  padding: 6rem !important; }

.pt-12,
.py-12 {
  padding-top: 6rem !important; }

.pr-12,
.px-12 {
  padding-right: 6rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 6rem !important; }

.pl-12,
.px-12 {
  padding-left: 6rem !important; }

.p-13 {
  padding: 6.5rem !important; }

.pt-13,
.py-13 {
  padding-top: 6.5rem !important; }

.pr-13,
.px-13 {
  padding-right: 6.5rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 6.5rem !important; }

.pl-13,
.px-13 {
  padding-left: 6.5rem !important; }

.p-14 {
  padding: 7rem !important; }

.pt-14,
.py-14 {
  padding-top: 7rem !important; }

.pr-14,
.px-14 {
  padding-right: 7rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 7rem !important; }

.pl-14,
.px-14 {
  padding-left: 7rem !important; }

.p-15 {
  padding: 7.5rem !important; }

.pt-15,
.py-15 {
  padding-top: 7.5rem !important; }

.pr-15,
.px-15 {
  padding-right: 7.5rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 7.5rem !important; }

.pl-15,
.px-15 {
  padding-left: 7.5rem !important; }

.p-16 {
  padding: 8rem !important; }

.pt-16,
.py-16 {
  padding-top: 8rem !important; }

.pr-16,
.px-16 {
  padding-right: 8rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 8rem !important; }

.pl-16,
.px-16 {
  padding-left: 8rem !important; }

.p-17 {
  padding: 8.5rem !important; }

.pt-17,
.py-17 {
  padding-top: 8.5rem !important; }

.pr-17,
.px-17 {
  padding-right: 8.5rem !important; }

.pb-17,
.py-17 {
  padding-bottom: 8.5rem !important; }

.pl-17,
.px-17 {
  padding-left: 8.5rem !important; }

.p-18 {
  padding: 9rem !important; }

.pt-18,
.py-18 {
  padding-top: 9rem !important; }

.pr-18,
.px-18 {
  padding-right: 9rem !important; }

.pb-18,
.py-18 {
  padding-bottom: 9rem !important; }

.pl-18,
.px-18 {
  padding-left: 9rem !important; }

.p-19 {
  padding: 9.5rem !important; }

.pt-19,
.py-19 {
  padding-top: 9.5rem !important; }

.pr-19,
.px-19 {
  padding-right: 9.5rem !important; }

.pb-19,
.py-19 {
  padding-bottom: 9.5rem !important; }

.pl-19,
.px-19 {
  padding-left: 9.5rem !important; }

.p-20 {
  padding: 10rem !important; }

.pt-20,
.py-20 {
  padding-top: 10rem !important; }

.pr-20,
.px-20 {
  padding-right: 10rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 10rem !important; }

.pl-20,
.px-20 {
  padding-left: 10rem !important; }

.p-21 {
  padding: 10.5rem !important; }

.pt-21,
.py-21 {
  padding-top: 10.5rem !important; }

.pr-21,
.px-21 {
  padding-right: 10.5rem !important; }

.pb-21,
.py-21 {
  padding-bottom: 10.5rem !important; }

.pl-21,
.px-21 {
  padding-left: 10.5rem !important; }

.p-26 {
  padding: 13rem !important; }

.pt-26,
.py-26 {
  padding-top: 13rem !important; }

.pr-26,
.px-26 {
  padding-right: 13rem !important; }

.pb-26,
.py-26 {
  padding-bottom: 13rem !important; }

.pl-26,
.px-26 {
  padding-left: 13rem !important; }

.p-28 {
  padding: 14rem !important; }

.pt-28,
.py-28 {
  padding-top: 14rem !important; }

.pr-28,
.px-28 {
  padding-right: 14rem !important; }

.pb-28,
.py-28 {
  padding-bottom: 14rem !important; }

.pl-28,
.px-28 {
  padding-left: 14rem !important; }

.m-n1 {
  margin: -0.5rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important; }

.m-n2 {
  margin: -1rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -1rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important; }

.m-n3 {
  margin: -1.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important; }

.m-n4 {
  margin: -2rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -2rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -2rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -2rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -2rem !important; }

.m-n5 {
  margin: -2.5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2.5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2.5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2.5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2.5rem !important; }

.m-n6 {
  margin: -3rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -3rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -3rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -3rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -3rem !important; }

.m-n7 {
  margin: -3.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -3.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -3.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -3.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -3.5rem !important; }

.m-n8 {
  margin: -4rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -4rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -4rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -4rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -4rem !important; }

.m-n9 {
  margin: -4.5rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -4.5rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -4.5rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -4.5rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -4.5rem !important; }

.m-n10 {
  margin: -5rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -5rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -5rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -5rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -5rem !important; }

.m-n11 {
  margin: -5.5rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -5.5rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -5.5rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -5.5rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -5.5rem !important; }

.m-n12 {
  margin: -6rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -6rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -6rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -6rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -6rem !important; }

.m-n13 {
  margin: -6.5rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -6.5rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -6.5rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -6.5rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -6.5rem !important; }

.m-n14 {
  margin: -7rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -7rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -7rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -7rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -7rem !important; }

.m-n15 {
  margin: -7.5rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -7.5rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -7.5rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -7.5rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -7.5rem !important; }

.m-n16 {
  margin: -8rem !important; }

.mt-n16,
.my-n16 {
  margin-top: -8rem !important; }

.mr-n16,
.mx-n16 {
  margin-right: -8rem !important; }

.mb-n16,
.my-n16 {
  margin-bottom: -8rem !important; }

.ml-n16,
.mx-n16 {
  margin-left: -8rem !important; }

.m-n17 {
  margin: -8.5rem !important; }

.mt-n17,
.my-n17 {
  margin-top: -8.5rem !important; }

.mr-n17,
.mx-n17 {
  margin-right: -8.5rem !important; }

.mb-n17,
.my-n17 {
  margin-bottom: -8.5rem !important; }

.ml-n17,
.mx-n17 {
  margin-left: -8.5rem !important; }

.m-n18 {
  margin: -9rem !important; }

.mt-n18,
.my-n18 {
  margin-top: -9rem !important; }

.mr-n18,
.mx-n18 {
  margin-right: -9rem !important; }

.mb-n18,
.my-n18 {
  margin-bottom: -9rem !important; }

.ml-n18,
.mx-n18 {
  margin-left: -9rem !important; }

.m-n19 {
  margin: -9.5rem !important; }

.mt-n19,
.my-n19 {
  margin-top: -9.5rem !important; }

.mr-n19,
.mx-n19 {
  margin-right: -9.5rem !important; }

.mb-n19,
.my-n19 {
  margin-bottom: -9.5rem !important; }

.ml-n19,
.mx-n19 {
  margin-left: -9.5rem !important; }

.m-n20 {
  margin: -10rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -10rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -10rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -10rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -10rem !important; }

.m-n21 {
  margin: -10.5rem !important; }

.mt-n21,
.my-n21 {
  margin-top: -10.5rem !important; }

.mr-n21,
.mx-n21 {
  margin-right: -10.5rem !important; }

.mb-n21,
.my-n21 {
  margin-bottom: -10.5rem !important; }

.ml-n21,
.mx-n21 {
  margin-left: -10.5rem !important; }

.m-n26 {
  margin: -13rem !important; }

.mt-n26,
.my-n26 {
  margin-top: -13rem !important; }

.mr-n26,
.mx-n26 {
  margin-right: -13rem !important; }

.mb-n26,
.my-n26 {
  margin-bottom: -13rem !important; }

.ml-n26,
.mx-n26 {
  margin-left: -13rem !important; }

.m-n28 {
  margin: -14rem !important; }

.mt-n28,
.my-n28 {
  margin-top: -14rem !important; }

.mr-n28,
.mx-n28 {
  margin-right: -14rem !important; }

.mb-n28,
.my-n28 {
  margin-bottom: -14rem !important; }

.ml-n28,
.mx-n28 {
  margin-left: -14rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-xxs-0 {
    margin: 0 !important; }
  .mt-xxs-0,
  .my-xxs-0 {
    margin-top: 0 !important; }
  .mr-xxs-0,
  .mx-xxs-0 {
    margin-right: 0 !important; }
  .mb-xxs-0,
  .my-xxs-0 {
    margin-bottom: 0 !important; }
  .ml-xxs-0,
  .mx-xxs-0 {
    margin-left: 0 !important; }
  .m-xxs-1 {
    margin: 0.5rem !important; }
  .mt-xxs-1,
  .my-xxs-1 {
    margin-top: 0.5rem !important; }
  .mr-xxs-1,
  .mx-xxs-1 {
    margin-right: 0.5rem !important; }
  .mb-xxs-1,
  .my-xxs-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xxs-1,
  .mx-xxs-1 {
    margin-left: 0.5rem !important; }
  .m-xxs-2 {
    margin: 1rem !important; }
  .mt-xxs-2,
  .my-xxs-2 {
    margin-top: 1rem !important; }
  .mr-xxs-2,
  .mx-xxs-2 {
    margin-right: 1rem !important; }
  .mb-xxs-2,
  .my-xxs-2 {
    margin-bottom: 1rem !important; }
  .ml-xxs-2,
  .mx-xxs-2 {
    margin-left: 1rem !important; }
  .m-xxs-3 {
    margin: 1.5rem !important; }
  .mt-xxs-3,
  .my-xxs-3 {
    margin-top: 1.5rem !important; }
  .mr-xxs-3,
  .mx-xxs-3 {
    margin-right: 1.5rem !important; }
  .mb-xxs-3,
  .my-xxs-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xxs-3,
  .mx-xxs-3 {
    margin-left: 1.5rem !important; }
  .m-xxs-4 {
    margin: 2rem !important; }
  .mt-xxs-4,
  .my-xxs-4 {
    margin-top: 2rem !important; }
  .mr-xxs-4,
  .mx-xxs-4 {
    margin-right: 2rem !important; }
  .mb-xxs-4,
  .my-xxs-4 {
    margin-bottom: 2rem !important; }
  .ml-xxs-4,
  .mx-xxs-4 {
    margin-left: 2rem !important; }
  .m-xxs-5 {
    margin: 2.5rem !important; }
  .mt-xxs-5,
  .my-xxs-5 {
    margin-top: 2.5rem !important; }
  .mr-xxs-5,
  .mx-xxs-5 {
    margin-right: 2.5rem !important; }
  .mb-xxs-5,
  .my-xxs-5 {
    margin-bottom: 2.5rem !important; }
  .ml-xxs-5,
  .mx-xxs-5 {
    margin-left: 2.5rem !important; }
  .m-xxs-6 {
    margin: 3rem !important; }
  .mt-xxs-6,
  .my-xxs-6 {
    margin-top: 3rem !important; }
  .mr-xxs-6,
  .mx-xxs-6 {
    margin-right: 3rem !important; }
  .mb-xxs-6,
  .my-xxs-6 {
    margin-bottom: 3rem !important; }
  .ml-xxs-6,
  .mx-xxs-6 {
    margin-left: 3rem !important; }
  .m-xxs-7 {
    margin: 3.5rem !important; }
  .mt-xxs-7,
  .my-xxs-7 {
    margin-top: 3.5rem !important; }
  .mr-xxs-7,
  .mx-xxs-7 {
    margin-right: 3.5rem !important; }
  .mb-xxs-7,
  .my-xxs-7 {
    margin-bottom: 3.5rem !important; }
  .ml-xxs-7,
  .mx-xxs-7 {
    margin-left: 3.5rem !important; }
  .m-xxs-8 {
    margin: 4rem !important; }
  .mt-xxs-8,
  .my-xxs-8 {
    margin-top: 4rem !important; }
  .mr-xxs-8,
  .mx-xxs-8 {
    margin-right: 4rem !important; }
  .mb-xxs-8,
  .my-xxs-8 {
    margin-bottom: 4rem !important; }
  .ml-xxs-8,
  .mx-xxs-8 {
    margin-left: 4rem !important; }
  .m-xxs-9 {
    margin: 4.5rem !important; }
  .mt-xxs-9,
  .my-xxs-9 {
    margin-top: 4.5rem !important; }
  .mr-xxs-9,
  .mx-xxs-9 {
    margin-right: 4.5rem !important; }
  .mb-xxs-9,
  .my-xxs-9 {
    margin-bottom: 4.5rem !important; }
  .ml-xxs-9,
  .mx-xxs-9 {
    margin-left: 4.5rem !important; }
  .m-xxs-10 {
    margin: 5rem !important; }
  .mt-xxs-10,
  .my-xxs-10 {
    margin-top: 5rem !important; }
  .mr-xxs-10,
  .mx-xxs-10 {
    margin-right: 5rem !important; }
  .mb-xxs-10,
  .my-xxs-10 {
    margin-bottom: 5rem !important; }
  .ml-xxs-10,
  .mx-xxs-10 {
    margin-left: 5rem !important; }
  .m-xxs-11 {
    margin: 5.5rem !important; }
  .mt-xxs-11,
  .my-xxs-11 {
    margin-top: 5.5rem !important; }
  .mr-xxs-11,
  .mx-xxs-11 {
    margin-right: 5.5rem !important; }
  .mb-xxs-11,
  .my-xxs-11 {
    margin-bottom: 5.5rem !important; }
  .ml-xxs-11,
  .mx-xxs-11 {
    margin-left: 5.5rem !important; }
  .m-xxs-12 {
    margin: 6rem !important; }
  .mt-xxs-12,
  .my-xxs-12 {
    margin-top: 6rem !important; }
  .mr-xxs-12,
  .mx-xxs-12 {
    margin-right: 6rem !important; }
  .mb-xxs-12,
  .my-xxs-12 {
    margin-bottom: 6rem !important; }
  .ml-xxs-12,
  .mx-xxs-12 {
    margin-left: 6rem !important; }
  .m-xxs-13 {
    margin: 6.5rem !important; }
  .mt-xxs-13,
  .my-xxs-13 {
    margin-top: 6.5rem !important; }
  .mr-xxs-13,
  .mx-xxs-13 {
    margin-right: 6.5rem !important; }
  .mb-xxs-13,
  .my-xxs-13 {
    margin-bottom: 6.5rem !important; }
  .ml-xxs-13,
  .mx-xxs-13 {
    margin-left: 6.5rem !important; }
  .m-xxs-14 {
    margin: 7rem !important; }
  .mt-xxs-14,
  .my-xxs-14 {
    margin-top: 7rem !important; }
  .mr-xxs-14,
  .mx-xxs-14 {
    margin-right: 7rem !important; }
  .mb-xxs-14,
  .my-xxs-14 {
    margin-bottom: 7rem !important; }
  .ml-xxs-14,
  .mx-xxs-14 {
    margin-left: 7rem !important; }
  .m-xxs-15 {
    margin: 7.5rem !important; }
  .mt-xxs-15,
  .my-xxs-15 {
    margin-top: 7.5rem !important; }
  .mr-xxs-15,
  .mx-xxs-15 {
    margin-right: 7.5rem !important; }
  .mb-xxs-15,
  .my-xxs-15 {
    margin-bottom: 7.5rem !important; }
  .ml-xxs-15,
  .mx-xxs-15 {
    margin-left: 7.5rem !important; }
  .m-xxs-16 {
    margin: 8rem !important; }
  .mt-xxs-16,
  .my-xxs-16 {
    margin-top: 8rem !important; }
  .mr-xxs-16,
  .mx-xxs-16 {
    margin-right: 8rem !important; }
  .mb-xxs-16,
  .my-xxs-16 {
    margin-bottom: 8rem !important; }
  .ml-xxs-16,
  .mx-xxs-16 {
    margin-left: 8rem !important; }
  .m-xxs-17 {
    margin: 8.5rem !important; }
  .mt-xxs-17,
  .my-xxs-17 {
    margin-top: 8.5rem !important; }
  .mr-xxs-17,
  .mx-xxs-17 {
    margin-right: 8.5rem !important; }
  .mb-xxs-17,
  .my-xxs-17 {
    margin-bottom: 8.5rem !important; }
  .ml-xxs-17,
  .mx-xxs-17 {
    margin-left: 8.5rem !important; }
  .m-xxs-18 {
    margin: 9rem !important; }
  .mt-xxs-18,
  .my-xxs-18 {
    margin-top: 9rem !important; }
  .mr-xxs-18,
  .mx-xxs-18 {
    margin-right: 9rem !important; }
  .mb-xxs-18,
  .my-xxs-18 {
    margin-bottom: 9rem !important; }
  .ml-xxs-18,
  .mx-xxs-18 {
    margin-left: 9rem !important; }
  .m-xxs-19 {
    margin: 9.5rem !important; }
  .mt-xxs-19,
  .my-xxs-19 {
    margin-top: 9.5rem !important; }
  .mr-xxs-19,
  .mx-xxs-19 {
    margin-right: 9.5rem !important; }
  .mb-xxs-19,
  .my-xxs-19 {
    margin-bottom: 9.5rem !important; }
  .ml-xxs-19,
  .mx-xxs-19 {
    margin-left: 9.5rem !important; }
  .m-xxs-20 {
    margin: 10rem !important; }
  .mt-xxs-20,
  .my-xxs-20 {
    margin-top: 10rem !important; }
  .mr-xxs-20,
  .mx-xxs-20 {
    margin-right: 10rem !important; }
  .mb-xxs-20,
  .my-xxs-20 {
    margin-bottom: 10rem !important; }
  .ml-xxs-20,
  .mx-xxs-20 {
    margin-left: 10rem !important; }
  .m-xxs-21 {
    margin: 10.5rem !important; }
  .mt-xxs-21,
  .my-xxs-21 {
    margin-top: 10.5rem !important; }
  .mr-xxs-21,
  .mx-xxs-21 {
    margin-right: 10.5rem !important; }
  .mb-xxs-21,
  .my-xxs-21 {
    margin-bottom: 10.5rem !important; }
  .ml-xxs-21,
  .mx-xxs-21 {
    margin-left: 10.5rem !important; }
  .m-xxs-26 {
    margin: 13rem !important; }
  .mt-xxs-26,
  .my-xxs-26 {
    margin-top: 13rem !important; }
  .mr-xxs-26,
  .mx-xxs-26 {
    margin-right: 13rem !important; }
  .mb-xxs-26,
  .my-xxs-26 {
    margin-bottom: 13rem !important; }
  .ml-xxs-26,
  .mx-xxs-26 {
    margin-left: 13rem !important; }
  .m-xxs-28 {
    margin: 14rem !important; }
  .mt-xxs-28,
  .my-xxs-28 {
    margin-top: 14rem !important; }
  .mr-xxs-28,
  .mx-xxs-28 {
    margin-right: 14rem !important; }
  .mb-xxs-28,
  .my-xxs-28 {
    margin-bottom: 14rem !important; }
  .ml-xxs-28,
  .mx-xxs-28 {
    margin-left: 14rem !important; }
  .p-xxs-0 {
    padding: 0 !important; }
  .pt-xxs-0,
  .py-xxs-0 {
    padding-top: 0 !important; }
  .pr-xxs-0,
  .px-xxs-0 {
    padding-right: 0 !important; }
  .pb-xxs-0,
  .py-xxs-0 {
    padding-bottom: 0 !important; }
  .pl-xxs-0,
  .px-xxs-0 {
    padding-left: 0 !important; }
  .p-xxs-1 {
    padding: 0.5rem !important; }
  .pt-xxs-1,
  .py-xxs-1 {
    padding-top: 0.5rem !important; }
  .pr-xxs-1,
  .px-xxs-1 {
    padding-right: 0.5rem !important; }
  .pb-xxs-1,
  .py-xxs-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xxs-1,
  .px-xxs-1 {
    padding-left: 0.5rem !important; }
  .p-xxs-2 {
    padding: 1rem !important; }
  .pt-xxs-2,
  .py-xxs-2 {
    padding-top: 1rem !important; }
  .pr-xxs-2,
  .px-xxs-2 {
    padding-right: 1rem !important; }
  .pb-xxs-2,
  .py-xxs-2 {
    padding-bottom: 1rem !important; }
  .pl-xxs-2,
  .px-xxs-2 {
    padding-left: 1rem !important; }
  .p-xxs-3 {
    padding: 1.5rem !important; }
  .pt-xxs-3,
  .py-xxs-3 {
    padding-top: 1.5rem !important; }
  .pr-xxs-3,
  .px-xxs-3 {
    padding-right: 1.5rem !important; }
  .pb-xxs-3,
  .py-xxs-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xxs-3,
  .px-xxs-3 {
    padding-left: 1.5rem !important; }
  .p-xxs-4 {
    padding: 2rem !important; }
  .pt-xxs-4,
  .py-xxs-4 {
    padding-top: 2rem !important; }
  .pr-xxs-4,
  .px-xxs-4 {
    padding-right: 2rem !important; }
  .pb-xxs-4,
  .py-xxs-4 {
    padding-bottom: 2rem !important; }
  .pl-xxs-4,
  .px-xxs-4 {
    padding-left: 2rem !important; }
  .p-xxs-5 {
    padding: 2.5rem !important; }
  .pt-xxs-5,
  .py-xxs-5 {
    padding-top: 2.5rem !important; }
  .pr-xxs-5,
  .px-xxs-5 {
    padding-right: 2.5rem !important; }
  .pb-xxs-5,
  .py-xxs-5 {
    padding-bottom: 2.5rem !important; }
  .pl-xxs-5,
  .px-xxs-5 {
    padding-left: 2.5rem !important; }
  .p-xxs-6 {
    padding: 3rem !important; }
  .pt-xxs-6,
  .py-xxs-6 {
    padding-top: 3rem !important; }
  .pr-xxs-6,
  .px-xxs-6 {
    padding-right: 3rem !important; }
  .pb-xxs-6,
  .py-xxs-6 {
    padding-bottom: 3rem !important; }
  .pl-xxs-6,
  .px-xxs-6 {
    padding-left: 3rem !important; }
  .p-xxs-7 {
    padding: 3.5rem !important; }
  .pt-xxs-7,
  .py-xxs-7 {
    padding-top: 3.5rem !important; }
  .pr-xxs-7,
  .px-xxs-7 {
    padding-right: 3.5rem !important; }
  .pb-xxs-7,
  .py-xxs-7 {
    padding-bottom: 3.5rem !important; }
  .pl-xxs-7,
  .px-xxs-7 {
    padding-left: 3.5rem !important; }
  .p-xxs-8 {
    padding: 4rem !important; }
  .pt-xxs-8,
  .py-xxs-8 {
    padding-top: 4rem !important; }
  .pr-xxs-8,
  .px-xxs-8 {
    padding-right: 4rem !important; }
  .pb-xxs-8,
  .py-xxs-8 {
    padding-bottom: 4rem !important; }
  .pl-xxs-8,
  .px-xxs-8 {
    padding-left: 4rem !important; }
  .p-xxs-9 {
    padding: 4.5rem !important; }
  .pt-xxs-9,
  .py-xxs-9 {
    padding-top: 4.5rem !important; }
  .pr-xxs-9,
  .px-xxs-9 {
    padding-right: 4.5rem !important; }
  .pb-xxs-9,
  .py-xxs-9 {
    padding-bottom: 4.5rem !important; }
  .pl-xxs-9,
  .px-xxs-9 {
    padding-left: 4.5rem !important; }
  .p-xxs-10 {
    padding: 5rem !important; }
  .pt-xxs-10,
  .py-xxs-10 {
    padding-top: 5rem !important; }
  .pr-xxs-10,
  .px-xxs-10 {
    padding-right: 5rem !important; }
  .pb-xxs-10,
  .py-xxs-10 {
    padding-bottom: 5rem !important; }
  .pl-xxs-10,
  .px-xxs-10 {
    padding-left: 5rem !important; }
  .p-xxs-11 {
    padding: 5.5rem !important; }
  .pt-xxs-11,
  .py-xxs-11 {
    padding-top: 5.5rem !important; }
  .pr-xxs-11,
  .px-xxs-11 {
    padding-right: 5.5rem !important; }
  .pb-xxs-11,
  .py-xxs-11 {
    padding-bottom: 5.5rem !important; }
  .pl-xxs-11,
  .px-xxs-11 {
    padding-left: 5.5rem !important; }
  .p-xxs-12 {
    padding: 6rem !important; }
  .pt-xxs-12,
  .py-xxs-12 {
    padding-top: 6rem !important; }
  .pr-xxs-12,
  .px-xxs-12 {
    padding-right: 6rem !important; }
  .pb-xxs-12,
  .py-xxs-12 {
    padding-bottom: 6rem !important; }
  .pl-xxs-12,
  .px-xxs-12 {
    padding-left: 6rem !important; }
  .p-xxs-13 {
    padding: 6.5rem !important; }
  .pt-xxs-13,
  .py-xxs-13 {
    padding-top: 6.5rem !important; }
  .pr-xxs-13,
  .px-xxs-13 {
    padding-right: 6.5rem !important; }
  .pb-xxs-13,
  .py-xxs-13 {
    padding-bottom: 6.5rem !important; }
  .pl-xxs-13,
  .px-xxs-13 {
    padding-left: 6.5rem !important; }
  .p-xxs-14 {
    padding: 7rem !important; }
  .pt-xxs-14,
  .py-xxs-14 {
    padding-top: 7rem !important; }
  .pr-xxs-14,
  .px-xxs-14 {
    padding-right: 7rem !important; }
  .pb-xxs-14,
  .py-xxs-14 {
    padding-bottom: 7rem !important; }
  .pl-xxs-14,
  .px-xxs-14 {
    padding-left: 7rem !important; }
  .p-xxs-15 {
    padding: 7.5rem !important; }
  .pt-xxs-15,
  .py-xxs-15 {
    padding-top: 7.5rem !important; }
  .pr-xxs-15,
  .px-xxs-15 {
    padding-right: 7.5rem !important; }
  .pb-xxs-15,
  .py-xxs-15 {
    padding-bottom: 7.5rem !important; }
  .pl-xxs-15,
  .px-xxs-15 {
    padding-left: 7.5rem !important; }
  .p-xxs-16 {
    padding: 8rem !important; }
  .pt-xxs-16,
  .py-xxs-16 {
    padding-top: 8rem !important; }
  .pr-xxs-16,
  .px-xxs-16 {
    padding-right: 8rem !important; }
  .pb-xxs-16,
  .py-xxs-16 {
    padding-bottom: 8rem !important; }
  .pl-xxs-16,
  .px-xxs-16 {
    padding-left: 8rem !important; }
  .p-xxs-17 {
    padding: 8.5rem !important; }
  .pt-xxs-17,
  .py-xxs-17 {
    padding-top: 8.5rem !important; }
  .pr-xxs-17,
  .px-xxs-17 {
    padding-right: 8.5rem !important; }
  .pb-xxs-17,
  .py-xxs-17 {
    padding-bottom: 8.5rem !important; }
  .pl-xxs-17,
  .px-xxs-17 {
    padding-left: 8.5rem !important; }
  .p-xxs-18 {
    padding: 9rem !important; }
  .pt-xxs-18,
  .py-xxs-18 {
    padding-top: 9rem !important; }
  .pr-xxs-18,
  .px-xxs-18 {
    padding-right: 9rem !important; }
  .pb-xxs-18,
  .py-xxs-18 {
    padding-bottom: 9rem !important; }
  .pl-xxs-18,
  .px-xxs-18 {
    padding-left: 9rem !important; }
  .p-xxs-19 {
    padding: 9.5rem !important; }
  .pt-xxs-19,
  .py-xxs-19 {
    padding-top: 9.5rem !important; }
  .pr-xxs-19,
  .px-xxs-19 {
    padding-right: 9.5rem !important; }
  .pb-xxs-19,
  .py-xxs-19 {
    padding-bottom: 9.5rem !important; }
  .pl-xxs-19,
  .px-xxs-19 {
    padding-left: 9.5rem !important; }
  .p-xxs-20 {
    padding: 10rem !important; }
  .pt-xxs-20,
  .py-xxs-20 {
    padding-top: 10rem !important; }
  .pr-xxs-20,
  .px-xxs-20 {
    padding-right: 10rem !important; }
  .pb-xxs-20,
  .py-xxs-20 {
    padding-bottom: 10rem !important; }
  .pl-xxs-20,
  .px-xxs-20 {
    padding-left: 10rem !important; }
  .p-xxs-21 {
    padding: 10.5rem !important; }
  .pt-xxs-21,
  .py-xxs-21 {
    padding-top: 10.5rem !important; }
  .pr-xxs-21,
  .px-xxs-21 {
    padding-right: 10.5rem !important; }
  .pb-xxs-21,
  .py-xxs-21 {
    padding-bottom: 10.5rem !important; }
  .pl-xxs-21,
  .px-xxs-21 {
    padding-left: 10.5rem !important; }
  .p-xxs-26 {
    padding: 13rem !important; }
  .pt-xxs-26,
  .py-xxs-26 {
    padding-top: 13rem !important; }
  .pr-xxs-26,
  .px-xxs-26 {
    padding-right: 13rem !important; }
  .pb-xxs-26,
  .py-xxs-26 {
    padding-bottom: 13rem !important; }
  .pl-xxs-26,
  .px-xxs-26 {
    padding-left: 13rem !important; }
  .p-xxs-28 {
    padding: 14rem !important; }
  .pt-xxs-28,
  .py-xxs-28 {
    padding-top: 14rem !important; }
  .pr-xxs-28,
  .px-xxs-28 {
    padding-right: 14rem !important; }
  .pb-xxs-28,
  .py-xxs-28 {
    padding-bottom: 14rem !important; }
  .pl-xxs-28,
  .px-xxs-28 {
    padding-left: 14rem !important; }
  .m-xxs-n1 {
    margin: -0.5rem !important; }
  .mt-xxs-n1,
  .my-xxs-n1 {
    margin-top: -0.5rem !important; }
  .mr-xxs-n1,
  .mx-xxs-n1 {
    margin-right: -0.5rem !important; }
  .mb-xxs-n1,
  .my-xxs-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xxs-n1,
  .mx-xxs-n1 {
    margin-left: -0.5rem !important; }
  .m-xxs-n2 {
    margin: -1rem !important; }
  .mt-xxs-n2,
  .my-xxs-n2 {
    margin-top: -1rem !important; }
  .mr-xxs-n2,
  .mx-xxs-n2 {
    margin-right: -1rem !important; }
  .mb-xxs-n2,
  .my-xxs-n2 {
    margin-bottom: -1rem !important; }
  .ml-xxs-n2,
  .mx-xxs-n2 {
    margin-left: -1rem !important; }
  .m-xxs-n3 {
    margin: -1.5rem !important; }
  .mt-xxs-n3,
  .my-xxs-n3 {
    margin-top: -1.5rem !important; }
  .mr-xxs-n3,
  .mx-xxs-n3 {
    margin-right: -1.5rem !important; }
  .mb-xxs-n3,
  .my-xxs-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-xxs-n3,
  .mx-xxs-n3 {
    margin-left: -1.5rem !important; }
  .m-xxs-n4 {
    margin: -2rem !important; }
  .mt-xxs-n4,
  .my-xxs-n4 {
    margin-top: -2rem !important; }
  .mr-xxs-n4,
  .mx-xxs-n4 {
    margin-right: -2rem !important; }
  .mb-xxs-n4,
  .my-xxs-n4 {
    margin-bottom: -2rem !important; }
  .ml-xxs-n4,
  .mx-xxs-n4 {
    margin-left: -2rem !important; }
  .m-xxs-n5 {
    margin: -2.5rem !important; }
  .mt-xxs-n5,
  .my-xxs-n5 {
    margin-top: -2.5rem !important; }
  .mr-xxs-n5,
  .mx-xxs-n5 {
    margin-right: -2.5rem !important; }
  .mb-xxs-n5,
  .my-xxs-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-xxs-n5,
  .mx-xxs-n5 {
    margin-left: -2.5rem !important; }
  .m-xxs-n6 {
    margin: -3rem !important; }
  .mt-xxs-n6,
  .my-xxs-n6 {
    margin-top: -3rem !important; }
  .mr-xxs-n6,
  .mx-xxs-n6 {
    margin-right: -3rem !important; }
  .mb-xxs-n6,
  .my-xxs-n6 {
    margin-bottom: -3rem !important; }
  .ml-xxs-n6,
  .mx-xxs-n6 {
    margin-left: -3rem !important; }
  .m-xxs-n7 {
    margin: -3.5rem !important; }
  .mt-xxs-n7,
  .my-xxs-n7 {
    margin-top: -3.5rem !important; }
  .mr-xxs-n7,
  .mx-xxs-n7 {
    margin-right: -3.5rem !important; }
  .mb-xxs-n7,
  .my-xxs-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-xxs-n7,
  .mx-xxs-n7 {
    margin-left: -3.5rem !important; }
  .m-xxs-n8 {
    margin: -4rem !important; }
  .mt-xxs-n8,
  .my-xxs-n8 {
    margin-top: -4rem !important; }
  .mr-xxs-n8,
  .mx-xxs-n8 {
    margin-right: -4rem !important; }
  .mb-xxs-n8,
  .my-xxs-n8 {
    margin-bottom: -4rem !important; }
  .ml-xxs-n8,
  .mx-xxs-n8 {
    margin-left: -4rem !important; }
  .m-xxs-n9 {
    margin: -4.5rem !important; }
  .mt-xxs-n9,
  .my-xxs-n9 {
    margin-top: -4.5rem !important; }
  .mr-xxs-n9,
  .mx-xxs-n9 {
    margin-right: -4.5rem !important; }
  .mb-xxs-n9,
  .my-xxs-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-xxs-n9,
  .mx-xxs-n9 {
    margin-left: -4.5rem !important; }
  .m-xxs-n10 {
    margin: -5rem !important; }
  .mt-xxs-n10,
  .my-xxs-n10 {
    margin-top: -5rem !important; }
  .mr-xxs-n10,
  .mx-xxs-n10 {
    margin-right: -5rem !important; }
  .mb-xxs-n10,
  .my-xxs-n10 {
    margin-bottom: -5rem !important; }
  .ml-xxs-n10,
  .mx-xxs-n10 {
    margin-left: -5rem !important; }
  .m-xxs-n11 {
    margin: -5.5rem !important; }
  .mt-xxs-n11,
  .my-xxs-n11 {
    margin-top: -5.5rem !important; }
  .mr-xxs-n11,
  .mx-xxs-n11 {
    margin-right: -5.5rem !important; }
  .mb-xxs-n11,
  .my-xxs-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-xxs-n11,
  .mx-xxs-n11 {
    margin-left: -5.5rem !important; }
  .m-xxs-n12 {
    margin: -6rem !important; }
  .mt-xxs-n12,
  .my-xxs-n12 {
    margin-top: -6rem !important; }
  .mr-xxs-n12,
  .mx-xxs-n12 {
    margin-right: -6rem !important; }
  .mb-xxs-n12,
  .my-xxs-n12 {
    margin-bottom: -6rem !important; }
  .ml-xxs-n12,
  .mx-xxs-n12 {
    margin-left: -6rem !important; }
  .m-xxs-n13 {
    margin: -6.5rem !important; }
  .mt-xxs-n13,
  .my-xxs-n13 {
    margin-top: -6.5rem !important; }
  .mr-xxs-n13,
  .mx-xxs-n13 {
    margin-right: -6.5rem !important; }
  .mb-xxs-n13,
  .my-xxs-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-xxs-n13,
  .mx-xxs-n13 {
    margin-left: -6.5rem !important; }
  .m-xxs-n14 {
    margin: -7rem !important; }
  .mt-xxs-n14,
  .my-xxs-n14 {
    margin-top: -7rem !important; }
  .mr-xxs-n14,
  .mx-xxs-n14 {
    margin-right: -7rem !important; }
  .mb-xxs-n14,
  .my-xxs-n14 {
    margin-bottom: -7rem !important; }
  .ml-xxs-n14,
  .mx-xxs-n14 {
    margin-left: -7rem !important; }
  .m-xxs-n15 {
    margin: -7.5rem !important; }
  .mt-xxs-n15,
  .my-xxs-n15 {
    margin-top: -7.5rem !important; }
  .mr-xxs-n15,
  .mx-xxs-n15 {
    margin-right: -7.5rem !important; }
  .mb-xxs-n15,
  .my-xxs-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-xxs-n15,
  .mx-xxs-n15 {
    margin-left: -7.5rem !important; }
  .m-xxs-n16 {
    margin: -8rem !important; }
  .mt-xxs-n16,
  .my-xxs-n16 {
    margin-top: -8rem !important; }
  .mr-xxs-n16,
  .mx-xxs-n16 {
    margin-right: -8rem !important; }
  .mb-xxs-n16,
  .my-xxs-n16 {
    margin-bottom: -8rem !important; }
  .ml-xxs-n16,
  .mx-xxs-n16 {
    margin-left: -8rem !important; }
  .m-xxs-n17 {
    margin: -8.5rem !important; }
  .mt-xxs-n17,
  .my-xxs-n17 {
    margin-top: -8.5rem !important; }
  .mr-xxs-n17,
  .mx-xxs-n17 {
    margin-right: -8.5rem !important; }
  .mb-xxs-n17,
  .my-xxs-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-xxs-n17,
  .mx-xxs-n17 {
    margin-left: -8.5rem !important; }
  .m-xxs-n18 {
    margin: -9rem !important; }
  .mt-xxs-n18,
  .my-xxs-n18 {
    margin-top: -9rem !important; }
  .mr-xxs-n18,
  .mx-xxs-n18 {
    margin-right: -9rem !important; }
  .mb-xxs-n18,
  .my-xxs-n18 {
    margin-bottom: -9rem !important; }
  .ml-xxs-n18,
  .mx-xxs-n18 {
    margin-left: -9rem !important; }
  .m-xxs-n19 {
    margin: -9.5rem !important; }
  .mt-xxs-n19,
  .my-xxs-n19 {
    margin-top: -9.5rem !important; }
  .mr-xxs-n19,
  .mx-xxs-n19 {
    margin-right: -9.5rem !important; }
  .mb-xxs-n19,
  .my-xxs-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-xxs-n19,
  .mx-xxs-n19 {
    margin-left: -9.5rem !important; }
  .m-xxs-n20 {
    margin: -10rem !important; }
  .mt-xxs-n20,
  .my-xxs-n20 {
    margin-top: -10rem !important; }
  .mr-xxs-n20,
  .mx-xxs-n20 {
    margin-right: -10rem !important; }
  .mb-xxs-n20,
  .my-xxs-n20 {
    margin-bottom: -10rem !important; }
  .ml-xxs-n20,
  .mx-xxs-n20 {
    margin-left: -10rem !important; }
  .m-xxs-n21 {
    margin: -10.5rem !important; }
  .mt-xxs-n21,
  .my-xxs-n21 {
    margin-top: -10.5rem !important; }
  .mr-xxs-n21,
  .mx-xxs-n21 {
    margin-right: -10.5rem !important; }
  .mb-xxs-n21,
  .my-xxs-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-xxs-n21,
  .mx-xxs-n21 {
    margin-left: -10.5rem !important; }
  .m-xxs-n26 {
    margin: -13rem !important; }
  .mt-xxs-n26,
  .my-xxs-n26 {
    margin-top: -13rem !important; }
  .mr-xxs-n26,
  .mx-xxs-n26 {
    margin-right: -13rem !important; }
  .mb-xxs-n26,
  .my-xxs-n26 {
    margin-bottom: -13rem !important; }
  .ml-xxs-n26,
  .mx-xxs-n26 {
    margin-left: -13rem !important; }
  .m-xxs-n28 {
    margin: -14rem !important; }
  .mt-xxs-n28,
  .my-xxs-n28 {
    margin-top: -14rem !important; }
  .mr-xxs-n28,
  .mx-xxs-n28 {
    margin-right: -14rem !important; }
  .mb-xxs-n28,
  .my-xxs-n28 {
    margin-bottom: -14rem !important; }
  .ml-xxs-n28,
  .mx-xxs-n28 {
    margin-left: -14rem !important; }
  .m-xxs-auto {
    margin: auto !important; }
  .mt-xxs-auto,
  .my-xxs-auto {
    margin-top: auto !important; }
  .mr-xxs-auto,
  .mx-xxs-auto {
    margin-right: auto !important; }
  .mb-xxs-auto,
  .my-xxs-auto {
    margin-bottom: auto !important; }
  .ml-xxs-auto,
  .mx-xxs-auto {
    margin-left: auto !important; } }

@media (min-width: 767px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 1.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important; }
  .m-sm-4 {
    margin: 2rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2rem !important; }
  .m-sm-5 {
    margin: 2.5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.5rem !important; }
  .m-sm-6 {
    margin: 3rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3rem !important; }
  .m-sm-7 {
    margin: 3.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3.5rem !important; }
  .m-sm-8 {
    margin: 4rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 4rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 4rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 4rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 4rem !important; }
  .m-sm-9 {
    margin: 4.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 4.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 4.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 4.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 4.5rem !important; }
  .m-sm-10 {
    margin: 5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 5rem !important; }
  .m-sm-11 {
    margin: 5.5rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem !important; }
  .m-sm-12 {
    margin: 6rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 6rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 6rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 6rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 6rem !important; }
  .m-sm-13 {
    margin: 6.5rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 6.5rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 6.5rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 6.5rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 6.5rem !important; }
  .m-sm-14 {
    margin: 7rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 7rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 7rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 7rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 7rem !important; }
  .m-sm-15 {
    margin: 7.5rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 7.5rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 7.5rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 7.5rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 7.5rem !important; }
  .m-sm-16 {
    margin: 8rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 8rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 8rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 8rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 8rem !important; }
  .m-sm-17 {
    margin: 8.5rem !important; }
  .mt-sm-17,
  .my-sm-17 {
    margin-top: 8.5rem !important; }
  .mr-sm-17,
  .mx-sm-17 {
    margin-right: 8.5rem !important; }
  .mb-sm-17,
  .my-sm-17 {
    margin-bottom: 8.5rem !important; }
  .ml-sm-17,
  .mx-sm-17 {
    margin-left: 8.5rem !important; }
  .m-sm-18 {
    margin: 9rem !important; }
  .mt-sm-18,
  .my-sm-18 {
    margin-top: 9rem !important; }
  .mr-sm-18,
  .mx-sm-18 {
    margin-right: 9rem !important; }
  .mb-sm-18,
  .my-sm-18 {
    margin-bottom: 9rem !important; }
  .ml-sm-18,
  .mx-sm-18 {
    margin-left: 9rem !important; }
  .m-sm-19 {
    margin: 9.5rem !important; }
  .mt-sm-19,
  .my-sm-19 {
    margin-top: 9.5rem !important; }
  .mr-sm-19,
  .mx-sm-19 {
    margin-right: 9.5rem !important; }
  .mb-sm-19,
  .my-sm-19 {
    margin-bottom: 9.5rem !important; }
  .ml-sm-19,
  .mx-sm-19 {
    margin-left: 9.5rem !important; }
  .m-sm-20 {
    margin: 10rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 10rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 10rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 10rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 10rem !important; }
  .m-sm-21 {
    margin: 10.5rem !important; }
  .mt-sm-21,
  .my-sm-21 {
    margin-top: 10.5rem !important; }
  .mr-sm-21,
  .mx-sm-21 {
    margin-right: 10.5rem !important; }
  .mb-sm-21,
  .my-sm-21 {
    margin-bottom: 10.5rem !important; }
  .ml-sm-21,
  .mx-sm-21 {
    margin-left: 10.5rem !important; }
  .m-sm-26 {
    margin: 13rem !important; }
  .mt-sm-26,
  .my-sm-26 {
    margin-top: 13rem !important; }
  .mr-sm-26,
  .mx-sm-26 {
    margin-right: 13rem !important; }
  .mb-sm-26,
  .my-sm-26 {
    margin-bottom: 13rem !important; }
  .ml-sm-26,
  .mx-sm-26 {
    margin-left: 13rem !important; }
  .m-sm-28 {
    margin: 14rem !important; }
  .mt-sm-28,
  .my-sm-28 {
    margin-top: 14rem !important; }
  .mr-sm-28,
  .mx-sm-28 {
    margin-right: 14rem !important; }
  .mb-sm-28,
  .my-sm-28 {
    margin-bottom: 14rem !important; }
  .ml-sm-28,
  .mx-sm-28 {
    margin-left: 14rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 1.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important; }
  .p-sm-4 {
    padding: 2rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2rem !important; }
  .p-sm-5 {
    padding: 2.5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.5rem !important; }
  .p-sm-6 {
    padding: 3rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3rem !important; }
  .p-sm-7 {
    padding: 3.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3.5rem !important; }
  .p-sm-8 {
    padding: 4rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 4rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 4rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 4rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 4rem !important; }
  .p-sm-9 {
    padding: 4.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 4.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 4.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 4.5rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 4.5rem !important; }
  .p-sm-10 {
    padding: 5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 5rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 5rem !important; }
  .p-sm-11 {
    padding: 5.5rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem !important; }
  .p-sm-12 {
    padding: 6rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 6rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 6rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 6rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 6rem !important; }
  .p-sm-13 {
    padding: 6.5rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 6.5rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 6.5rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 6.5rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 6.5rem !important; }
  .p-sm-14 {
    padding: 7rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 7rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 7rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 7rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 7rem !important; }
  .p-sm-15 {
    padding: 7.5rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 7.5rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 7.5rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 7.5rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 7.5rem !important; }
  .p-sm-16 {
    padding: 8rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 8rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 8rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 8rem !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 8rem !important; }
  .p-sm-17 {
    padding: 8.5rem !important; }
  .pt-sm-17,
  .py-sm-17 {
    padding-top: 8.5rem !important; }
  .pr-sm-17,
  .px-sm-17 {
    padding-right: 8.5rem !important; }
  .pb-sm-17,
  .py-sm-17 {
    padding-bottom: 8.5rem !important; }
  .pl-sm-17,
  .px-sm-17 {
    padding-left: 8.5rem !important; }
  .p-sm-18 {
    padding: 9rem !important; }
  .pt-sm-18,
  .py-sm-18 {
    padding-top: 9rem !important; }
  .pr-sm-18,
  .px-sm-18 {
    padding-right: 9rem !important; }
  .pb-sm-18,
  .py-sm-18 {
    padding-bottom: 9rem !important; }
  .pl-sm-18,
  .px-sm-18 {
    padding-left: 9rem !important; }
  .p-sm-19 {
    padding: 9.5rem !important; }
  .pt-sm-19,
  .py-sm-19 {
    padding-top: 9.5rem !important; }
  .pr-sm-19,
  .px-sm-19 {
    padding-right: 9.5rem !important; }
  .pb-sm-19,
  .py-sm-19 {
    padding-bottom: 9.5rem !important; }
  .pl-sm-19,
  .px-sm-19 {
    padding-left: 9.5rem !important; }
  .p-sm-20 {
    padding: 10rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 10rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 10rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 10rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 10rem !important; }
  .p-sm-21 {
    padding: 10.5rem !important; }
  .pt-sm-21,
  .py-sm-21 {
    padding-top: 10.5rem !important; }
  .pr-sm-21,
  .px-sm-21 {
    padding-right: 10.5rem !important; }
  .pb-sm-21,
  .py-sm-21 {
    padding-bottom: 10.5rem !important; }
  .pl-sm-21,
  .px-sm-21 {
    padding-left: 10.5rem !important; }
  .p-sm-26 {
    padding: 13rem !important; }
  .pt-sm-26,
  .py-sm-26 {
    padding-top: 13rem !important; }
  .pr-sm-26,
  .px-sm-26 {
    padding-right: 13rem !important; }
  .pb-sm-26,
  .py-sm-26 {
    padding-bottom: 13rem !important; }
  .pl-sm-26,
  .px-sm-26 {
    padding-left: 13rem !important; }
  .p-sm-28 {
    padding: 14rem !important; }
  .pt-sm-28,
  .py-sm-28 {
    padding-top: 14rem !important; }
  .pr-sm-28,
  .px-sm-28 {
    padding-right: 14rem !important; }
  .pb-sm-28,
  .py-sm-28 {
    padding-bottom: 14rem !important; }
  .pl-sm-28,
  .px-sm-28 {
    padding-left: 14rem !important; }
  .m-sm-n1 {
    margin: -0.5rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important; }
  .m-sm-n2 {
    margin: -1rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important; }
  .m-sm-n3 {
    margin: -1.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important; }
  .m-sm-n4 {
    margin: -2rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -2rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -2rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -2rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -2rem !important; }
  .m-sm-n5 {
    margin: -2.5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.5rem !important; }
  .m-sm-n6 {
    margin: -3rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -3rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -3rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -3rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -3rem !important; }
  .m-sm-n7 {
    margin: -3.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3.5rem !important; }
  .m-sm-n8 {
    margin: -4rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -4rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -4rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -4rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -4rem !important; }
  .m-sm-n9 {
    margin: -4.5rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -4.5rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -4.5rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -4.5rem !important; }
  .m-sm-n10 {
    margin: -5rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -5rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -5rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -5rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -5rem !important; }
  .m-sm-n11 {
    margin: -5.5rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5.5rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5.5rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5.5rem !important; }
  .m-sm-n12 {
    margin: -6rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -6rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -6rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -6rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -6rem !important; }
  .m-sm-n13 {
    margin: -6.5rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -6.5rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -6.5rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -6.5rem !important; }
  .m-sm-n14 {
    margin: -7rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -7rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -7rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -7rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -7rem !important; }
  .m-sm-n15 {
    margin: -7.5rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -7.5rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -7.5rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -7.5rem !important; }
  .m-sm-n16 {
    margin: -8rem !important; }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -8rem !important; }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -8rem !important; }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -8rem !important; }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -8rem !important; }
  .m-sm-n17 {
    margin: -8.5rem !important; }
  .mt-sm-n17,
  .my-sm-n17 {
    margin-top: -8.5rem !important; }
  .mr-sm-n17,
  .mx-sm-n17 {
    margin-right: -8.5rem !important; }
  .mb-sm-n17,
  .my-sm-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-sm-n17,
  .mx-sm-n17 {
    margin-left: -8.5rem !important; }
  .m-sm-n18 {
    margin: -9rem !important; }
  .mt-sm-n18,
  .my-sm-n18 {
    margin-top: -9rem !important; }
  .mr-sm-n18,
  .mx-sm-n18 {
    margin-right: -9rem !important; }
  .mb-sm-n18,
  .my-sm-n18 {
    margin-bottom: -9rem !important; }
  .ml-sm-n18,
  .mx-sm-n18 {
    margin-left: -9rem !important; }
  .m-sm-n19 {
    margin: -9.5rem !important; }
  .mt-sm-n19,
  .my-sm-n19 {
    margin-top: -9.5rem !important; }
  .mr-sm-n19,
  .mx-sm-n19 {
    margin-right: -9.5rem !important; }
  .mb-sm-n19,
  .my-sm-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-sm-n19,
  .mx-sm-n19 {
    margin-left: -9.5rem !important; }
  .m-sm-n20 {
    margin: -10rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -10rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -10rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -10rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -10rem !important; }
  .m-sm-n21 {
    margin: -10.5rem !important; }
  .mt-sm-n21,
  .my-sm-n21 {
    margin-top: -10.5rem !important; }
  .mr-sm-n21,
  .mx-sm-n21 {
    margin-right: -10.5rem !important; }
  .mb-sm-n21,
  .my-sm-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-sm-n21,
  .mx-sm-n21 {
    margin-left: -10.5rem !important; }
  .m-sm-n26 {
    margin: -13rem !important; }
  .mt-sm-n26,
  .my-sm-n26 {
    margin-top: -13rem !important; }
  .mr-sm-n26,
  .mx-sm-n26 {
    margin-right: -13rem !important; }
  .mb-sm-n26,
  .my-sm-n26 {
    margin-bottom: -13rem !important; }
  .ml-sm-n26,
  .mx-sm-n26 {
    margin-left: -13rem !important; }
  .m-sm-n28 {
    margin: -14rem !important; }
  .mt-sm-n28,
  .my-sm-n28 {
    margin-top: -14rem !important; }
  .mr-sm-n28,
  .mx-sm-n28 {
    margin-right: -14rem !important; }
  .mb-sm-n28,
  .my-sm-n28 {
    margin-bottom: -14rem !important; }
  .ml-sm-n28,
  .mx-sm-n28 {
    margin-left: -14rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-ssm-0 {
    margin: 0 !important; }
  .mt-ssm-0,
  .my-ssm-0 {
    margin-top: 0 !important; }
  .mr-ssm-0,
  .mx-ssm-0 {
    margin-right: 0 !important; }
  .mb-ssm-0,
  .my-ssm-0 {
    margin-bottom: 0 !important; }
  .ml-ssm-0,
  .mx-ssm-0 {
    margin-left: 0 !important; }
  .m-ssm-1 {
    margin: 0.5rem !important; }
  .mt-ssm-1,
  .my-ssm-1 {
    margin-top: 0.5rem !important; }
  .mr-ssm-1,
  .mx-ssm-1 {
    margin-right: 0.5rem !important; }
  .mb-ssm-1,
  .my-ssm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-ssm-1,
  .mx-ssm-1 {
    margin-left: 0.5rem !important; }
  .m-ssm-2 {
    margin: 1rem !important; }
  .mt-ssm-2,
  .my-ssm-2 {
    margin-top: 1rem !important; }
  .mr-ssm-2,
  .mx-ssm-2 {
    margin-right: 1rem !important; }
  .mb-ssm-2,
  .my-ssm-2 {
    margin-bottom: 1rem !important; }
  .ml-ssm-2,
  .mx-ssm-2 {
    margin-left: 1rem !important; }
  .m-ssm-3 {
    margin: 1.5rem !important; }
  .mt-ssm-3,
  .my-ssm-3 {
    margin-top: 1.5rem !important; }
  .mr-ssm-3,
  .mx-ssm-3 {
    margin-right: 1.5rem !important; }
  .mb-ssm-3,
  .my-ssm-3 {
    margin-bottom: 1.5rem !important; }
  .ml-ssm-3,
  .mx-ssm-3 {
    margin-left: 1.5rem !important; }
  .m-ssm-4 {
    margin: 2rem !important; }
  .mt-ssm-4,
  .my-ssm-4 {
    margin-top: 2rem !important; }
  .mr-ssm-4,
  .mx-ssm-4 {
    margin-right: 2rem !important; }
  .mb-ssm-4,
  .my-ssm-4 {
    margin-bottom: 2rem !important; }
  .ml-ssm-4,
  .mx-ssm-4 {
    margin-left: 2rem !important; }
  .m-ssm-5 {
    margin: 2.5rem !important; }
  .mt-ssm-5,
  .my-ssm-5 {
    margin-top: 2.5rem !important; }
  .mr-ssm-5,
  .mx-ssm-5 {
    margin-right: 2.5rem !important; }
  .mb-ssm-5,
  .my-ssm-5 {
    margin-bottom: 2.5rem !important; }
  .ml-ssm-5,
  .mx-ssm-5 {
    margin-left: 2.5rem !important; }
  .m-ssm-6 {
    margin: 3rem !important; }
  .mt-ssm-6,
  .my-ssm-6 {
    margin-top: 3rem !important; }
  .mr-ssm-6,
  .mx-ssm-6 {
    margin-right: 3rem !important; }
  .mb-ssm-6,
  .my-ssm-6 {
    margin-bottom: 3rem !important; }
  .ml-ssm-6,
  .mx-ssm-6 {
    margin-left: 3rem !important; }
  .m-ssm-7 {
    margin: 3.5rem !important; }
  .mt-ssm-7,
  .my-ssm-7 {
    margin-top: 3.5rem !important; }
  .mr-ssm-7,
  .mx-ssm-7 {
    margin-right: 3.5rem !important; }
  .mb-ssm-7,
  .my-ssm-7 {
    margin-bottom: 3.5rem !important; }
  .ml-ssm-7,
  .mx-ssm-7 {
    margin-left: 3.5rem !important; }
  .m-ssm-8 {
    margin: 4rem !important; }
  .mt-ssm-8,
  .my-ssm-8 {
    margin-top: 4rem !important; }
  .mr-ssm-8,
  .mx-ssm-8 {
    margin-right: 4rem !important; }
  .mb-ssm-8,
  .my-ssm-8 {
    margin-bottom: 4rem !important; }
  .ml-ssm-8,
  .mx-ssm-8 {
    margin-left: 4rem !important; }
  .m-ssm-9 {
    margin: 4.5rem !important; }
  .mt-ssm-9,
  .my-ssm-9 {
    margin-top: 4.5rem !important; }
  .mr-ssm-9,
  .mx-ssm-9 {
    margin-right: 4.5rem !important; }
  .mb-ssm-9,
  .my-ssm-9 {
    margin-bottom: 4.5rem !important; }
  .ml-ssm-9,
  .mx-ssm-9 {
    margin-left: 4.5rem !important; }
  .m-ssm-10 {
    margin: 5rem !important; }
  .mt-ssm-10,
  .my-ssm-10 {
    margin-top: 5rem !important; }
  .mr-ssm-10,
  .mx-ssm-10 {
    margin-right: 5rem !important; }
  .mb-ssm-10,
  .my-ssm-10 {
    margin-bottom: 5rem !important; }
  .ml-ssm-10,
  .mx-ssm-10 {
    margin-left: 5rem !important; }
  .m-ssm-11 {
    margin: 5.5rem !important; }
  .mt-ssm-11,
  .my-ssm-11 {
    margin-top: 5.5rem !important; }
  .mr-ssm-11,
  .mx-ssm-11 {
    margin-right: 5.5rem !important; }
  .mb-ssm-11,
  .my-ssm-11 {
    margin-bottom: 5.5rem !important; }
  .ml-ssm-11,
  .mx-ssm-11 {
    margin-left: 5.5rem !important; }
  .m-ssm-12 {
    margin: 6rem !important; }
  .mt-ssm-12,
  .my-ssm-12 {
    margin-top: 6rem !important; }
  .mr-ssm-12,
  .mx-ssm-12 {
    margin-right: 6rem !important; }
  .mb-ssm-12,
  .my-ssm-12 {
    margin-bottom: 6rem !important; }
  .ml-ssm-12,
  .mx-ssm-12 {
    margin-left: 6rem !important; }
  .m-ssm-13 {
    margin: 6.5rem !important; }
  .mt-ssm-13,
  .my-ssm-13 {
    margin-top: 6.5rem !important; }
  .mr-ssm-13,
  .mx-ssm-13 {
    margin-right: 6.5rem !important; }
  .mb-ssm-13,
  .my-ssm-13 {
    margin-bottom: 6.5rem !important; }
  .ml-ssm-13,
  .mx-ssm-13 {
    margin-left: 6.5rem !important; }
  .m-ssm-14 {
    margin: 7rem !important; }
  .mt-ssm-14,
  .my-ssm-14 {
    margin-top: 7rem !important; }
  .mr-ssm-14,
  .mx-ssm-14 {
    margin-right: 7rem !important; }
  .mb-ssm-14,
  .my-ssm-14 {
    margin-bottom: 7rem !important; }
  .ml-ssm-14,
  .mx-ssm-14 {
    margin-left: 7rem !important; }
  .m-ssm-15 {
    margin: 7.5rem !important; }
  .mt-ssm-15,
  .my-ssm-15 {
    margin-top: 7.5rem !important; }
  .mr-ssm-15,
  .mx-ssm-15 {
    margin-right: 7.5rem !important; }
  .mb-ssm-15,
  .my-ssm-15 {
    margin-bottom: 7.5rem !important; }
  .ml-ssm-15,
  .mx-ssm-15 {
    margin-left: 7.5rem !important; }
  .m-ssm-16 {
    margin: 8rem !important; }
  .mt-ssm-16,
  .my-ssm-16 {
    margin-top: 8rem !important; }
  .mr-ssm-16,
  .mx-ssm-16 {
    margin-right: 8rem !important; }
  .mb-ssm-16,
  .my-ssm-16 {
    margin-bottom: 8rem !important; }
  .ml-ssm-16,
  .mx-ssm-16 {
    margin-left: 8rem !important; }
  .m-ssm-17 {
    margin: 8.5rem !important; }
  .mt-ssm-17,
  .my-ssm-17 {
    margin-top: 8.5rem !important; }
  .mr-ssm-17,
  .mx-ssm-17 {
    margin-right: 8.5rem !important; }
  .mb-ssm-17,
  .my-ssm-17 {
    margin-bottom: 8.5rem !important; }
  .ml-ssm-17,
  .mx-ssm-17 {
    margin-left: 8.5rem !important; }
  .m-ssm-18 {
    margin: 9rem !important; }
  .mt-ssm-18,
  .my-ssm-18 {
    margin-top: 9rem !important; }
  .mr-ssm-18,
  .mx-ssm-18 {
    margin-right: 9rem !important; }
  .mb-ssm-18,
  .my-ssm-18 {
    margin-bottom: 9rem !important; }
  .ml-ssm-18,
  .mx-ssm-18 {
    margin-left: 9rem !important; }
  .m-ssm-19 {
    margin: 9.5rem !important; }
  .mt-ssm-19,
  .my-ssm-19 {
    margin-top: 9.5rem !important; }
  .mr-ssm-19,
  .mx-ssm-19 {
    margin-right: 9.5rem !important; }
  .mb-ssm-19,
  .my-ssm-19 {
    margin-bottom: 9.5rem !important; }
  .ml-ssm-19,
  .mx-ssm-19 {
    margin-left: 9.5rem !important; }
  .m-ssm-20 {
    margin: 10rem !important; }
  .mt-ssm-20,
  .my-ssm-20 {
    margin-top: 10rem !important; }
  .mr-ssm-20,
  .mx-ssm-20 {
    margin-right: 10rem !important; }
  .mb-ssm-20,
  .my-ssm-20 {
    margin-bottom: 10rem !important; }
  .ml-ssm-20,
  .mx-ssm-20 {
    margin-left: 10rem !important; }
  .m-ssm-21 {
    margin: 10.5rem !important; }
  .mt-ssm-21,
  .my-ssm-21 {
    margin-top: 10.5rem !important; }
  .mr-ssm-21,
  .mx-ssm-21 {
    margin-right: 10.5rem !important; }
  .mb-ssm-21,
  .my-ssm-21 {
    margin-bottom: 10.5rem !important; }
  .ml-ssm-21,
  .mx-ssm-21 {
    margin-left: 10.5rem !important; }
  .m-ssm-26 {
    margin: 13rem !important; }
  .mt-ssm-26,
  .my-ssm-26 {
    margin-top: 13rem !important; }
  .mr-ssm-26,
  .mx-ssm-26 {
    margin-right: 13rem !important; }
  .mb-ssm-26,
  .my-ssm-26 {
    margin-bottom: 13rem !important; }
  .ml-ssm-26,
  .mx-ssm-26 {
    margin-left: 13rem !important; }
  .m-ssm-28 {
    margin: 14rem !important; }
  .mt-ssm-28,
  .my-ssm-28 {
    margin-top: 14rem !important; }
  .mr-ssm-28,
  .mx-ssm-28 {
    margin-right: 14rem !important; }
  .mb-ssm-28,
  .my-ssm-28 {
    margin-bottom: 14rem !important; }
  .ml-ssm-28,
  .mx-ssm-28 {
    margin-left: 14rem !important; }
  .p-ssm-0 {
    padding: 0 !important; }
  .pt-ssm-0,
  .py-ssm-0 {
    padding-top: 0 !important; }
  .pr-ssm-0,
  .px-ssm-0 {
    padding-right: 0 !important; }
  .pb-ssm-0,
  .py-ssm-0 {
    padding-bottom: 0 !important; }
  .pl-ssm-0,
  .px-ssm-0 {
    padding-left: 0 !important; }
  .p-ssm-1 {
    padding: 0.5rem !important; }
  .pt-ssm-1,
  .py-ssm-1 {
    padding-top: 0.5rem !important; }
  .pr-ssm-1,
  .px-ssm-1 {
    padding-right: 0.5rem !important; }
  .pb-ssm-1,
  .py-ssm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-ssm-1,
  .px-ssm-1 {
    padding-left: 0.5rem !important; }
  .p-ssm-2 {
    padding: 1rem !important; }
  .pt-ssm-2,
  .py-ssm-2 {
    padding-top: 1rem !important; }
  .pr-ssm-2,
  .px-ssm-2 {
    padding-right: 1rem !important; }
  .pb-ssm-2,
  .py-ssm-2 {
    padding-bottom: 1rem !important; }
  .pl-ssm-2,
  .px-ssm-2 {
    padding-left: 1rem !important; }
  .p-ssm-3 {
    padding: 1.5rem !important; }
  .pt-ssm-3,
  .py-ssm-3 {
    padding-top: 1.5rem !important; }
  .pr-ssm-3,
  .px-ssm-3 {
    padding-right: 1.5rem !important; }
  .pb-ssm-3,
  .py-ssm-3 {
    padding-bottom: 1.5rem !important; }
  .pl-ssm-3,
  .px-ssm-3 {
    padding-left: 1.5rem !important; }
  .p-ssm-4 {
    padding: 2rem !important; }
  .pt-ssm-4,
  .py-ssm-4 {
    padding-top: 2rem !important; }
  .pr-ssm-4,
  .px-ssm-4 {
    padding-right: 2rem !important; }
  .pb-ssm-4,
  .py-ssm-4 {
    padding-bottom: 2rem !important; }
  .pl-ssm-4,
  .px-ssm-4 {
    padding-left: 2rem !important; }
  .p-ssm-5 {
    padding: 2.5rem !important; }
  .pt-ssm-5,
  .py-ssm-5 {
    padding-top: 2.5rem !important; }
  .pr-ssm-5,
  .px-ssm-5 {
    padding-right: 2.5rem !important; }
  .pb-ssm-5,
  .py-ssm-5 {
    padding-bottom: 2.5rem !important; }
  .pl-ssm-5,
  .px-ssm-5 {
    padding-left: 2.5rem !important; }
  .p-ssm-6 {
    padding: 3rem !important; }
  .pt-ssm-6,
  .py-ssm-6 {
    padding-top: 3rem !important; }
  .pr-ssm-6,
  .px-ssm-6 {
    padding-right: 3rem !important; }
  .pb-ssm-6,
  .py-ssm-6 {
    padding-bottom: 3rem !important; }
  .pl-ssm-6,
  .px-ssm-6 {
    padding-left: 3rem !important; }
  .p-ssm-7 {
    padding: 3.5rem !important; }
  .pt-ssm-7,
  .py-ssm-7 {
    padding-top: 3.5rem !important; }
  .pr-ssm-7,
  .px-ssm-7 {
    padding-right: 3.5rem !important; }
  .pb-ssm-7,
  .py-ssm-7 {
    padding-bottom: 3.5rem !important; }
  .pl-ssm-7,
  .px-ssm-7 {
    padding-left: 3.5rem !important; }
  .p-ssm-8 {
    padding: 4rem !important; }
  .pt-ssm-8,
  .py-ssm-8 {
    padding-top: 4rem !important; }
  .pr-ssm-8,
  .px-ssm-8 {
    padding-right: 4rem !important; }
  .pb-ssm-8,
  .py-ssm-8 {
    padding-bottom: 4rem !important; }
  .pl-ssm-8,
  .px-ssm-8 {
    padding-left: 4rem !important; }
  .p-ssm-9 {
    padding: 4.5rem !important; }
  .pt-ssm-9,
  .py-ssm-9 {
    padding-top: 4.5rem !important; }
  .pr-ssm-9,
  .px-ssm-9 {
    padding-right: 4.5rem !important; }
  .pb-ssm-9,
  .py-ssm-9 {
    padding-bottom: 4.5rem !important; }
  .pl-ssm-9,
  .px-ssm-9 {
    padding-left: 4.5rem !important; }
  .p-ssm-10 {
    padding: 5rem !important; }
  .pt-ssm-10,
  .py-ssm-10 {
    padding-top: 5rem !important; }
  .pr-ssm-10,
  .px-ssm-10 {
    padding-right: 5rem !important; }
  .pb-ssm-10,
  .py-ssm-10 {
    padding-bottom: 5rem !important; }
  .pl-ssm-10,
  .px-ssm-10 {
    padding-left: 5rem !important; }
  .p-ssm-11 {
    padding: 5.5rem !important; }
  .pt-ssm-11,
  .py-ssm-11 {
    padding-top: 5.5rem !important; }
  .pr-ssm-11,
  .px-ssm-11 {
    padding-right: 5.5rem !important; }
  .pb-ssm-11,
  .py-ssm-11 {
    padding-bottom: 5.5rem !important; }
  .pl-ssm-11,
  .px-ssm-11 {
    padding-left: 5.5rem !important; }
  .p-ssm-12 {
    padding: 6rem !important; }
  .pt-ssm-12,
  .py-ssm-12 {
    padding-top: 6rem !important; }
  .pr-ssm-12,
  .px-ssm-12 {
    padding-right: 6rem !important; }
  .pb-ssm-12,
  .py-ssm-12 {
    padding-bottom: 6rem !important; }
  .pl-ssm-12,
  .px-ssm-12 {
    padding-left: 6rem !important; }
  .p-ssm-13 {
    padding: 6.5rem !important; }
  .pt-ssm-13,
  .py-ssm-13 {
    padding-top: 6.5rem !important; }
  .pr-ssm-13,
  .px-ssm-13 {
    padding-right: 6.5rem !important; }
  .pb-ssm-13,
  .py-ssm-13 {
    padding-bottom: 6.5rem !important; }
  .pl-ssm-13,
  .px-ssm-13 {
    padding-left: 6.5rem !important; }
  .p-ssm-14 {
    padding: 7rem !important; }
  .pt-ssm-14,
  .py-ssm-14 {
    padding-top: 7rem !important; }
  .pr-ssm-14,
  .px-ssm-14 {
    padding-right: 7rem !important; }
  .pb-ssm-14,
  .py-ssm-14 {
    padding-bottom: 7rem !important; }
  .pl-ssm-14,
  .px-ssm-14 {
    padding-left: 7rem !important; }
  .p-ssm-15 {
    padding: 7.5rem !important; }
  .pt-ssm-15,
  .py-ssm-15 {
    padding-top: 7.5rem !important; }
  .pr-ssm-15,
  .px-ssm-15 {
    padding-right: 7.5rem !important; }
  .pb-ssm-15,
  .py-ssm-15 {
    padding-bottom: 7.5rem !important; }
  .pl-ssm-15,
  .px-ssm-15 {
    padding-left: 7.5rem !important; }
  .p-ssm-16 {
    padding: 8rem !important; }
  .pt-ssm-16,
  .py-ssm-16 {
    padding-top: 8rem !important; }
  .pr-ssm-16,
  .px-ssm-16 {
    padding-right: 8rem !important; }
  .pb-ssm-16,
  .py-ssm-16 {
    padding-bottom: 8rem !important; }
  .pl-ssm-16,
  .px-ssm-16 {
    padding-left: 8rem !important; }
  .p-ssm-17 {
    padding: 8.5rem !important; }
  .pt-ssm-17,
  .py-ssm-17 {
    padding-top: 8.5rem !important; }
  .pr-ssm-17,
  .px-ssm-17 {
    padding-right: 8.5rem !important; }
  .pb-ssm-17,
  .py-ssm-17 {
    padding-bottom: 8.5rem !important; }
  .pl-ssm-17,
  .px-ssm-17 {
    padding-left: 8.5rem !important; }
  .p-ssm-18 {
    padding: 9rem !important; }
  .pt-ssm-18,
  .py-ssm-18 {
    padding-top: 9rem !important; }
  .pr-ssm-18,
  .px-ssm-18 {
    padding-right: 9rem !important; }
  .pb-ssm-18,
  .py-ssm-18 {
    padding-bottom: 9rem !important; }
  .pl-ssm-18,
  .px-ssm-18 {
    padding-left: 9rem !important; }
  .p-ssm-19 {
    padding: 9.5rem !important; }
  .pt-ssm-19,
  .py-ssm-19 {
    padding-top: 9.5rem !important; }
  .pr-ssm-19,
  .px-ssm-19 {
    padding-right: 9.5rem !important; }
  .pb-ssm-19,
  .py-ssm-19 {
    padding-bottom: 9.5rem !important; }
  .pl-ssm-19,
  .px-ssm-19 {
    padding-left: 9.5rem !important; }
  .p-ssm-20 {
    padding: 10rem !important; }
  .pt-ssm-20,
  .py-ssm-20 {
    padding-top: 10rem !important; }
  .pr-ssm-20,
  .px-ssm-20 {
    padding-right: 10rem !important; }
  .pb-ssm-20,
  .py-ssm-20 {
    padding-bottom: 10rem !important; }
  .pl-ssm-20,
  .px-ssm-20 {
    padding-left: 10rem !important; }
  .p-ssm-21 {
    padding: 10.5rem !important; }
  .pt-ssm-21,
  .py-ssm-21 {
    padding-top: 10.5rem !important; }
  .pr-ssm-21,
  .px-ssm-21 {
    padding-right: 10.5rem !important; }
  .pb-ssm-21,
  .py-ssm-21 {
    padding-bottom: 10.5rem !important; }
  .pl-ssm-21,
  .px-ssm-21 {
    padding-left: 10.5rem !important; }
  .p-ssm-26 {
    padding: 13rem !important; }
  .pt-ssm-26,
  .py-ssm-26 {
    padding-top: 13rem !important; }
  .pr-ssm-26,
  .px-ssm-26 {
    padding-right: 13rem !important; }
  .pb-ssm-26,
  .py-ssm-26 {
    padding-bottom: 13rem !important; }
  .pl-ssm-26,
  .px-ssm-26 {
    padding-left: 13rem !important; }
  .p-ssm-28 {
    padding: 14rem !important; }
  .pt-ssm-28,
  .py-ssm-28 {
    padding-top: 14rem !important; }
  .pr-ssm-28,
  .px-ssm-28 {
    padding-right: 14rem !important; }
  .pb-ssm-28,
  .py-ssm-28 {
    padding-bottom: 14rem !important; }
  .pl-ssm-28,
  .px-ssm-28 {
    padding-left: 14rem !important; }
  .m-ssm-n1 {
    margin: -0.5rem !important; }
  .mt-ssm-n1,
  .my-ssm-n1 {
    margin-top: -0.5rem !important; }
  .mr-ssm-n1,
  .mx-ssm-n1 {
    margin-right: -0.5rem !important; }
  .mb-ssm-n1,
  .my-ssm-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-ssm-n1,
  .mx-ssm-n1 {
    margin-left: -0.5rem !important; }
  .m-ssm-n2 {
    margin: -1rem !important; }
  .mt-ssm-n2,
  .my-ssm-n2 {
    margin-top: -1rem !important; }
  .mr-ssm-n2,
  .mx-ssm-n2 {
    margin-right: -1rem !important; }
  .mb-ssm-n2,
  .my-ssm-n2 {
    margin-bottom: -1rem !important; }
  .ml-ssm-n2,
  .mx-ssm-n2 {
    margin-left: -1rem !important; }
  .m-ssm-n3 {
    margin: -1.5rem !important; }
  .mt-ssm-n3,
  .my-ssm-n3 {
    margin-top: -1.5rem !important; }
  .mr-ssm-n3,
  .mx-ssm-n3 {
    margin-right: -1.5rem !important; }
  .mb-ssm-n3,
  .my-ssm-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-ssm-n3,
  .mx-ssm-n3 {
    margin-left: -1.5rem !important; }
  .m-ssm-n4 {
    margin: -2rem !important; }
  .mt-ssm-n4,
  .my-ssm-n4 {
    margin-top: -2rem !important; }
  .mr-ssm-n4,
  .mx-ssm-n4 {
    margin-right: -2rem !important; }
  .mb-ssm-n4,
  .my-ssm-n4 {
    margin-bottom: -2rem !important; }
  .ml-ssm-n4,
  .mx-ssm-n4 {
    margin-left: -2rem !important; }
  .m-ssm-n5 {
    margin: -2.5rem !important; }
  .mt-ssm-n5,
  .my-ssm-n5 {
    margin-top: -2.5rem !important; }
  .mr-ssm-n5,
  .mx-ssm-n5 {
    margin-right: -2.5rem !important; }
  .mb-ssm-n5,
  .my-ssm-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-ssm-n5,
  .mx-ssm-n5 {
    margin-left: -2.5rem !important; }
  .m-ssm-n6 {
    margin: -3rem !important; }
  .mt-ssm-n6,
  .my-ssm-n6 {
    margin-top: -3rem !important; }
  .mr-ssm-n6,
  .mx-ssm-n6 {
    margin-right: -3rem !important; }
  .mb-ssm-n6,
  .my-ssm-n6 {
    margin-bottom: -3rem !important; }
  .ml-ssm-n6,
  .mx-ssm-n6 {
    margin-left: -3rem !important; }
  .m-ssm-n7 {
    margin: -3.5rem !important; }
  .mt-ssm-n7,
  .my-ssm-n7 {
    margin-top: -3.5rem !important; }
  .mr-ssm-n7,
  .mx-ssm-n7 {
    margin-right: -3.5rem !important; }
  .mb-ssm-n7,
  .my-ssm-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-ssm-n7,
  .mx-ssm-n7 {
    margin-left: -3.5rem !important; }
  .m-ssm-n8 {
    margin: -4rem !important; }
  .mt-ssm-n8,
  .my-ssm-n8 {
    margin-top: -4rem !important; }
  .mr-ssm-n8,
  .mx-ssm-n8 {
    margin-right: -4rem !important; }
  .mb-ssm-n8,
  .my-ssm-n8 {
    margin-bottom: -4rem !important; }
  .ml-ssm-n8,
  .mx-ssm-n8 {
    margin-left: -4rem !important; }
  .m-ssm-n9 {
    margin: -4.5rem !important; }
  .mt-ssm-n9,
  .my-ssm-n9 {
    margin-top: -4.5rem !important; }
  .mr-ssm-n9,
  .mx-ssm-n9 {
    margin-right: -4.5rem !important; }
  .mb-ssm-n9,
  .my-ssm-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-ssm-n9,
  .mx-ssm-n9 {
    margin-left: -4.5rem !important; }
  .m-ssm-n10 {
    margin: -5rem !important; }
  .mt-ssm-n10,
  .my-ssm-n10 {
    margin-top: -5rem !important; }
  .mr-ssm-n10,
  .mx-ssm-n10 {
    margin-right: -5rem !important; }
  .mb-ssm-n10,
  .my-ssm-n10 {
    margin-bottom: -5rem !important; }
  .ml-ssm-n10,
  .mx-ssm-n10 {
    margin-left: -5rem !important; }
  .m-ssm-n11 {
    margin: -5.5rem !important; }
  .mt-ssm-n11,
  .my-ssm-n11 {
    margin-top: -5.5rem !important; }
  .mr-ssm-n11,
  .mx-ssm-n11 {
    margin-right: -5.5rem !important; }
  .mb-ssm-n11,
  .my-ssm-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-ssm-n11,
  .mx-ssm-n11 {
    margin-left: -5.5rem !important; }
  .m-ssm-n12 {
    margin: -6rem !important; }
  .mt-ssm-n12,
  .my-ssm-n12 {
    margin-top: -6rem !important; }
  .mr-ssm-n12,
  .mx-ssm-n12 {
    margin-right: -6rem !important; }
  .mb-ssm-n12,
  .my-ssm-n12 {
    margin-bottom: -6rem !important; }
  .ml-ssm-n12,
  .mx-ssm-n12 {
    margin-left: -6rem !important; }
  .m-ssm-n13 {
    margin: -6.5rem !important; }
  .mt-ssm-n13,
  .my-ssm-n13 {
    margin-top: -6.5rem !important; }
  .mr-ssm-n13,
  .mx-ssm-n13 {
    margin-right: -6.5rem !important; }
  .mb-ssm-n13,
  .my-ssm-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-ssm-n13,
  .mx-ssm-n13 {
    margin-left: -6.5rem !important; }
  .m-ssm-n14 {
    margin: -7rem !important; }
  .mt-ssm-n14,
  .my-ssm-n14 {
    margin-top: -7rem !important; }
  .mr-ssm-n14,
  .mx-ssm-n14 {
    margin-right: -7rem !important; }
  .mb-ssm-n14,
  .my-ssm-n14 {
    margin-bottom: -7rem !important; }
  .ml-ssm-n14,
  .mx-ssm-n14 {
    margin-left: -7rem !important; }
  .m-ssm-n15 {
    margin: -7.5rem !important; }
  .mt-ssm-n15,
  .my-ssm-n15 {
    margin-top: -7.5rem !important; }
  .mr-ssm-n15,
  .mx-ssm-n15 {
    margin-right: -7.5rem !important; }
  .mb-ssm-n15,
  .my-ssm-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-ssm-n15,
  .mx-ssm-n15 {
    margin-left: -7.5rem !important; }
  .m-ssm-n16 {
    margin: -8rem !important; }
  .mt-ssm-n16,
  .my-ssm-n16 {
    margin-top: -8rem !important; }
  .mr-ssm-n16,
  .mx-ssm-n16 {
    margin-right: -8rem !important; }
  .mb-ssm-n16,
  .my-ssm-n16 {
    margin-bottom: -8rem !important; }
  .ml-ssm-n16,
  .mx-ssm-n16 {
    margin-left: -8rem !important; }
  .m-ssm-n17 {
    margin: -8.5rem !important; }
  .mt-ssm-n17,
  .my-ssm-n17 {
    margin-top: -8.5rem !important; }
  .mr-ssm-n17,
  .mx-ssm-n17 {
    margin-right: -8.5rem !important; }
  .mb-ssm-n17,
  .my-ssm-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-ssm-n17,
  .mx-ssm-n17 {
    margin-left: -8.5rem !important; }
  .m-ssm-n18 {
    margin: -9rem !important; }
  .mt-ssm-n18,
  .my-ssm-n18 {
    margin-top: -9rem !important; }
  .mr-ssm-n18,
  .mx-ssm-n18 {
    margin-right: -9rem !important; }
  .mb-ssm-n18,
  .my-ssm-n18 {
    margin-bottom: -9rem !important; }
  .ml-ssm-n18,
  .mx-ssm-n18 {
    margin-left: -9rem !important; }
  .m-ssm-n19 {
    margin: -9.5rem !important; }
  .mt-ssm-n19,
  .my-ssm-n19 {
    margin-top: -9.5rem !important; }
  .mr-ssm-n19,
  .mx-ssm-n19 {
    margin-right: -9.5rem !important; }
  .mb-ssm-n19,
  .my-ssm-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-ssm-n19,
  .mx-ssm-n19 {
    margin-left: -9.5rem !important; }
  .m-ssm-n20 {
    margin: -10rem !important; }
  .mt-ssm-n20,
  .my-ssm-n20 {
    margin-top: -10rem !important; }
  .mr-ssm-n20,
  .mx-ssm-n20 {
    margin-right: -10rem !important; }
  .mb-ssm-n20,
  .my-ssm-n20 {
    margin-bottom: -10rem !important; }
  .ml-ssm-n20,
  .mx-ssm-n20 {
    margin-left: -10rem !important; }
  .m-ssm-n21 {
    margin: -10.5rem !important; }
  .mt-ssm-n21,
  .my-ssm-n21 {
    margin-top: -10.5rem !important; }
  .mr-ssm-n21,
  .mx-ssm-n21 {
    margin-right: -10.5rem !important; }
  .mb-ssm-n21,
  .my-ssm-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-ssm-n21,
  .mx-ssm-n21 {
    margin-left: -10.5rem !important; }
  .m-ssm-n26 {
    margin: -13rem !important; }
  .mt-ssm-n26,
  .my-ssm-n26 {
    margin-top: -13rem !important; }
  .mr-ssm-n26,
  .mx-ssm-n26 {
    margin-right: -13rem !important; }
  .mb-ssm-n26,
  .my-ssm-n26 {
    margin-bottom: -13rem !important; }
  .ml-ssm-n26,
  .mx-ssm-n26 {
    margin-left: -13rem !important; }
  .m-ssm-n28 {
    margin: -14rem !important; }
  .mt-ssm-n28,
  .my-ssm-n28 {
    margin-top: -14rem !important; }
  .mr-ssm-n28,
  .mx-ssm-n28 {
    margin-right: -14rem !important; }
  .mb-ssm-n28,
  .my-ssm-n28 {
    margin-bottom: -14rem !important; }
  .ml-ssm-n28,
  .mx-ssm-n28 {
    margin-left: -14rem !important; }
  .m-ssm-auto {
    margin: auto !important; }
  .mt-ssm-auto,
  .my-ssm-auto {
    margin-top: auto !important; }
  .mr-ssm-auto,
  .mx-ssm-auto {
    margin-right: auto !important; }
  .mb-ssm-auto,
  .my-ssm-auto {
    margin-bottom: auto !important; }
  .ml-ssm-auto,
  .mx-ssm-auto {
    margin-left: auto !important; } }

@media (min-width: 1281px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 1.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important; }
  .m-md-4 {
    margin: 2rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2rem !important; }
  .m-md-5 {
    margin: 2.5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.5rem !important; }
  .m-md-6 {
    margin: 3rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3rem !important; }
  .m-md-7 {
    margin: 3.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3.5rem !important; }
  .m-md-8 {
    margin: 4rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 4rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 4rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 4rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 4rem !important; }
  .m-md-9 {
    margin: 4.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 4.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 4.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 4.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 4.5rem !important; }
  .m-md-10 {
    margin: 5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 5rem !important; }
  .m-md-11 {
    margin: 5.5rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem !important; }
  .m-md-12 {
    margin: 6rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 6rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 6rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 6rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 6rem !important; }
  .m-md-13 {
    margin: 6.5rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 6.5rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 6.5rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 6.5rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 6.5rem !important; }
  .m-md-14 {
    margin: 7rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 7rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 7rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 7rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 7rem !important; }
  .m-md-15 {
    margin: 7.5rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 7.5rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 7.5rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 7.5rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 7.5rem !important; }
  .m-md-16 {
    margin: 8rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 8rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 8rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 8rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 8rem !important; }
  .m-md-17 {
    margin: 8.5rem !important; }
  .mt-md-17,
  .my-md-17 {
    margin-top: 8.5rem !important; }
  .mr-md-17,
  .mx-md-17 {
    margin-right: 8.5rem !important; }
  .mb-md-17,
  .my-md-17 {
    margin-bottom: 8.5rem !important; }
  .ml-md-17,
  .mx-md-17 {
    margin-left: 8.5rem !important; }
  .m-md-18 {
    margin: 9rem !important; }
  .mt-md-18,
  .my-md-18 {
    margin-top: 9rem !important; }
  .mr-md-18,
  .mx-md-18 {
    margin-right: 9rem !important; }
  .mb-md-18,
  .my-md-18 {
    margin-bottom: 9rem !important; }
  .ml-md-18,
  .mx-md-18 {
    margin-left: 9rem !important; }
  .m-md-19 {
    margin: 9.5rem !important; }
  .mt-md-19,
  .my-md-19 {
    margin-top: 9.5rem !important; }
  .mr-md-19,
  .mx-md-19 {
    margin-right: 9.5rem !important; }
  .mb-md-19,
  .my-md-19 {
    margin-bottom: 9.5rem !important; }
  .ml-md-19,
  .mx-md-19 {
    margin-left: 9.5rem !important; }
  .m-md-20 {
    margin: 10rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 10rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 10rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 10rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 10rem !important; }
  .m-md-21 {
    margin: 10.5rem !important; }
  .mt-md-21,
  .my-md-21 {
    margin-top: 10.5rem !important; }
  .mr-md-21,
  .mx-md-21 {
    margin-right: 10.5rem !important; }
  .mb-md-21,
  .my-md-21 {
    margin-bottom: 10.5rem !important; }
  .ml-md-21,
  .mx-md-21 {
    margin-left: 10.5rem !important; }
  .m-md-26 {
    margin: 13rem !important; }
  .mt-md-26,
  .my-md-26 {
    margin-top: 13rem !important; }
  .mr-md-26,
  .mx-md-26 {
    margin-right: 13rem !important; }
  .mb-md-26,
  .my-md-26 {
    margin-bottom: 13rem !important; }
  .ml-md-26,
  .mx-md-26 {
    margin-left: 13rem !important; }
  .m-md-28 {
    margin: 14rem !important; }
  .mt-md-28,
  .my-md-28 {
    margin-top: 14rem !important; }
  .mr-md-28,
  .mx-md-28 {
    margin-right: 14rem !important; }
  .mb-md-28,
  .my-md-28 {
    margin-bottom: 14rem !important; }
  .ml-md-28,
  .mx-md-28 {
    margin-left: 14rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 1.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important; }
  .p-md-4 {
    padding: 2rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2rem !important; }
  .p-md-5 {
    padding: 2.5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.5rem !important; }
  .p-md-6 {
    padding: 3rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3rem !important; }
  .p-md-7 {
    padding: 3.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3.5rem !important; }
  .p-md-8 {
    padding: 4rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 4rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 4rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 4rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 4rem !important; }
  .p-md-9 {
    padding: 4.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 4.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 4.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 4.5rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 4.5rem !important; }
  .p-md-10 {
    padding: 5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 5rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 5rem !important; }
  .p-md-11 {
    padding: 5.5rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem !important; }
  .p-md-12 {
    padding: 6rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 6rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 6rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 6rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 6rem !important; }
  .p-md-13 {
    padding: 6.5rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 6.5rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 6.5rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 6.5rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 6.5rem !important; }
  .p-md-14 {
    padding: 7rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 7rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 7rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 7rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 7rem !important; }
  .p-md-15 {
    padding: 7.5rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 7.5rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 7.5rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 7.5rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 7.5rem !important; }
  .p-md-16 {
    padding: 8rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 8rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 8rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 8rem !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 8rem !important; }
  .p-md-17 {
    padding: 8.5rem !important; }
  .pt-md-17,
  .py-md-17 {
    padding-top: 8.5rem !important; }
  .pr-md-17,
  .px-md-17 {
    padding-right: 8.5rem !important; }
  .pb-md-17,
  .py-md-17 {
    padding-bottom: 8.5rem !important; }
  .pl-md-17,
  .px-md-17 {
    padding-left: 8.5rem !important; }
  .p-md-18 {
    padding: 9rem !important; }
  .pt-md-18,
  .py-md-18 {
    padding-top: 9rem !important; }
  .pr-md-18,
  .px-md-18 {
    padding-right: 9rem !important; }
  .pb-md-18,
  .py-md-18 {
    padding-bottom: 9rem !important; }
  .pl-md-18,
  .px-md-18 {
    padding-left: 9rem !important; }
  .p-md-19 {
    padding: 9.5rem !important; }
  .pt-md-19,
  .py-md-19 {
    padding-top: 9.5rem !important; }
  .pr-md-19,
  .px-md-19 {
    padding-right: 9.5rem !important; }
  .pb-md-19,
  .py-md-19 {
    padding-bottom: 9.5rem !important; }
  .pl-md-19,
  .px-md-19 {
    padding-left: 9.5rem !important; }
  .p-md-20 {
    padding: 10rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 10rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 10rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 10rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 10rem !important; }
  .p-md-21 {
    padding: 10.5rem !important; }
  .pt-md-21,
  .py-md-21 {
    padding-top: 10.5rem !important; }
  .pr-md-21,
  .px-md-21 {
    padding-right: 10.5rem !important; }
  .pb-md-21,
  .py-md-21 {
    padding-bottom: 10.5rem !important; }
  .pl-md-21,
  .px-md-21 {
    padding-left: 10.5rem !important; }
  .p-md-26 {
    padding: 13rem !important; }
  .pt-md-26,
  .py-md-26 {
    padding-top: 13rem !important; }
  .pr-md-26,
  .px-md-26 {
    padding-right: 13rem !important; }
  .pb-md-26,
  .py-md-26 {
    padding-bottom: 13rem !important; }
  .pl-md-26,
  .px-md-26 {
    padding-left: 13rem !important; }
  .p-md-28 {
    padding: 14rem !important; }
  .pt-md-28,
  .py-md-28 {
    padding-top: 14rem !important; }
  .pr-md-28,
  .px-md-28 {
    padding-right: 14rem !important; }
  .pb-md-28,
  .py-md-28 {
    padding-bottom: 14rem !important; }
  .pl-md-28,
  .px-md-28 {
    padding-left: 14rem !important; }
  .m-md-n1 {
    margin: -0.5rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important; }
  .m-md-n2 {
    margin: -1rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important; }
  .m-md-n3 {
    margin: -1.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important; }
  .m-md-n4 {
    margin: -2rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -2rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -2rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -2rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -2rem !important; }
  .m-md-n5 {
    margin: -2.5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.5rem !important; }
  .m-md-n6 {
    margin: -3rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -3rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -3rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -3rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -3rem !important; }
  .m-md-n7 {
    margin: -3.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3.5rem !important; }
  .m-md-n8 {
    margin: -4rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -4rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -4rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -4rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -4rem !important; }
  .m-md-n9 {
    margin: -4.5rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -4.5rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -4.5rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -4.5rem !important; }
  .m-md-n10 {
    margin: -5rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -5rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -5rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -5rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -5rem !important; }
  .m-md-n11 {
    margin: -5.5rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5.5rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5.5rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5.5rem !important; }
  .m-md-n12 {
    margin: -6rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -6rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -6rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -6rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -6rem !important; }
  .m-md-n13 {
    margin: -6.5rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -6.5rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -6.5rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -6.5rem !important; }
  .m-md-n14 {
    margin: -7rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -7rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -7rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -7rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -7rem !important; }
  .m-md-n15 {
    margin: -7.5rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -7.5rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -7.5rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -7.5rem !important; }
  .m-md-n16 {
    margin: -8rem !important; }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -8rem !important; }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -8rem !important; }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -8rem !important; }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -8rem !important; }
  .m-md-n17 {
    margin: -8.5rem !important; }
  .mt-md-n17,
  .my-md-n17 {
    margin-top: -8.5rem !important; }
  .mr-md-n17,
  .mx-md-n17 {
    margin-right: -8.5rem !important; }
  .mb-md-n17,
  .my-md-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-md-n17,
  .mx-md-n17 {
    margin-left: -8.5rem !important; }
  .m-md-n18 {
    margin: -9rem !important; }
  .mt-md-n18,
  .my-md-n18 {
    margin-top: -9rem !important; }
  .mr-md-n18,
  .mx-md-n18 {
    margin-right: -9rem !important; }
  .mb-md-n18,
  .my-md-n18 {
    margin-bottom: -9rem !important; }
  .ml-md-n18,
  .mx-md-n18 {
    margin-left: -9rem !important; }
  .m-md-n19 {
    margin: -9.5rem !important; }
  .mt-md-n19,
  .my-md-n19 {
    margin-top: -9.5rem !important; }
  .mr-md-n19,
  .mx-md-n19 {
    margin-right: -9.5rem !important; }
  .mb-md-n19,
  .my-md-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-md-n19,
  .mx-md-n19 {
    margin-left: -9.5rem !important; }
  .m-md-n20 {
    margin: -10rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -10rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -10rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -10rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -10rem !important; }
  .m-md-n21 {
    margin: -10.5rem !important; }
  .mt-md-n21,
  .my-md-n21 {
    margin-top: -10.5rem !important; }
  .mr-md-n21,
  .mx-md-n21 {
    margin-right: -10.5rem !important; }
  .mb-md-n21,
  .my-md-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-md-n21,
  .mx-md-n21 {
    margin-left: -10.5rem !important; }
  .m-md-n26 {
    margin: -13rem !important; }
  .mt-md-n26,
  .my-md-n26 {
    margin-top: -13rem !important; }
  .mr-md-n26,
  .mx-md-n26 {
    margin-right: -13rem !important; }
  .mb-md-n26,
  .my-md-n26 {
    margin-bottom: -13rem !important; }
  .ml-md-n26,
  .mx-md-n26 {
    margin-left: -13rem !important; }
  .m-md-n28 {
    margin: -14rem !important; }
  .mt-md-n28,
  .my-md-n28 {
    margin-top: -14rem !important; }
  .mr-md-n28,
  .mx-md-n28 {
    margin-right: -14rem !important; }
  .mb-md-n28,
  .my-md-n28 {
    margin-bottom: -14rem !important; }
  .ml-md-n28,
  .mx-md-n28 {
    margin-left: -14rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1400px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 1.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important; }
  .m-lg-4 {
    margin: 2rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2rem !important; }
  .m-lg-5 {
    margin: 2.5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.5rem !important; }
  .m-lg-6 {
    margin: 3rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3rem !important; }
  .m-lg-7 {
    margin: 3.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3.5rem !important; }
  .m-lg-8 {
    margin: 4rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 4rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 4rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 4rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 4rem !important; }
  .m-lg-9 {
    margin: 4.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 4.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 4.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 4.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 4.5rem !important; }
  .m-lg-10 {
    margin: 5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 5rem !important; }
  .m-lg-11 {
    margin: 5.5rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem !important; }
  .m-lg-12 {
    margin: 6rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 6rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 6rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 6rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 6rem !important; }
  .m-lg-13 {
    margin: 6.5rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 6.5rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 6.5rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 6.5rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 6.5rem !important; }
  .m-lg-14 {
    margin: 7rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 7rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 7rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 7rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 7rem !important; }
  .m-lg-15 {
    margin: 7.5rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 7.5rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 7.5rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 7.5rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 7.5rem !important; }
  .m-lg-16 {
    margin: 8rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 8rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 8rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 8rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 8rem !important; }
  .m-lg-17 {
    margin: 8.5rem !important; }
  .mt-lg-17,
  .my-lg-17 {
    margin-top: 8.5rem !important; }
  .mr-lg-17,
  .mx-lg-17 {
    margin-right: 8.5rem !important; }
  .mb-lg-17,
  .my-lg-17 {
    margin-bottom: 8.5rem !important; }
  .ml-lg-17,
  .mx-lg-17 {
    margin-left: 8.5rem !important; }
  .m-lg-18 {
    margin: 9rem !important; }
  .mt-lg-18,
  .my-lg-18 {
    margin-top: 9rem !important; }
  .mr-lg-18,
  .mx-lg-18 {
    margin-right: 9rem !important; }
  .mb-lg-18,
  .my-lg-18 {
    margin-bottom: 9rem !important; }
  .ml-lg-18,
  .mx-lg-18 {
    margin-left: 9rem !important; }
  .m-lg-19 {
    margin: 9.5rem !important; }
  .mt-lg-19,
  .my-lg-19 {
    margin-top: 9.5rem !important; }
  .mr-lg-19,
  .mx-lg-19 {
    margin-right: 9.5rem !important; }
  .mb-lg-19,
  .my-lg-19 {
    margin-bottom: 9.5rem !important; }
  .ml-lg-19,
  .mx-lg-19 {
    margin-left: 9.5rem !important; }
  .m-lg-20 {
    margin: 10rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 10rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 10rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 10rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 10rem !important; }
  .m-lg-21 {
    margin: 10.5rem !important; }
  .mt-lg-21,
  .my-lg-21 {
    margin-top: 10.5rem !important; }
  .mr-lg-21,
  .mx-lg-21 {
    margin-right: 10.5rem !important; }
  .mb-lg-21,
  .my-lg-21 {
    margin-bottom: 10.5rem !important; }
  .ml-lg-21,
  .mx-lg-21 {
    margin-left: 10.5rem !important; }
  .m-lg-26 {
    margin: 13rem !important; }
  .mt-lg-26,
  .my-lg-26 {
    margin-top: 13rem !important; }
  .mr-lg-26,
  .mx-lg-26 {
    margin-right: 13rem !important; }
  .mb-lg-26,
  .my-lg-26 {
    margin-bottom: 13rem !important; }
  .ml-lg-26,
  .mx-lg-26 {
    margin-left: 13rem !important; }
  .m-lg-28 {
    margin: 14rem !important; }
  .mt-lg-28,
  .my-lg-28 {
    margin-top: 14rem !important; }
  .mr-lg-28,
  .mx-lg-28 {
    margin-right: 14rem !important; }
  .mb-lg-28,
  .my-lg-28 {
    margin-bottom: 14rem !important; }
  .ml-lg-28,
  .mx-lg-28 {
    margin-left: 14rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 1.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important; }
  .p-lg-4 {
    padding: 2rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2rem !important; }
  .p-lg-5 {
    padding: 2.5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.5rem !important; }
  .p-lg-6 {
    padding: 3rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3rem !important; }
  .p-lg-7 {
    padding: 3.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3.5rem !important; }
  .p-lg-8 {
    padding: 4rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 4rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 4rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 4rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 4rem !important; }
  .p-lg-9 {
    padding: 4.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 4.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 4.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 4.5rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 4.5rem !important; }
  .p-lg-10 {
    padding: 5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 5rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 5rem !important; }
  .p-lg-11 {
    padding: 5.5rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem !important; }
  .p-lg-12 {
    padding: 6rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 6rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 6rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 6rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 6rem !important; }
  .p-lg-13 {
    padding: 6.5rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6.5rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 6.5rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6.5rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 6.5rem !important; }
  .p-lg-14 {
    padding: 7rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 7rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 7rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 7rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 7rem !important; }
  .p-lg-15 {
    padding: 7.5rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 7.5rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 7.5rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 7.5rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 7.5rem !important; }
  .p-lg-16 {
    padding: 8rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 8rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 8rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 8rem !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 8rem !important; }
  .p-lg-17 {
    padding: 8.5rem !important; }
  .pt-lg-17,
  .py-lg-17 {
    padding-top: 8.5rem !important; }
  .pr-lg-17,
  .px-lg-17 {
    padding-right: 8.5rem !important; }
  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 8.5rem !important; }
  .pl-lg-17,
  .px-lg-17 {
    padding-left: 8.5rem !important; }
  .p-lg-18 {
    padding: 9rem !important; }
  .pt-lg-18,
  .py-lg-18 {
    padding-top: 9rem !important; }
  .pr-lg-18,
  .px-lg-18 {
    padding-right: 9rem !important; }
  .pb-lg-18,
  .py-lg-18 {
    padding-bottom: 9rem !important; }
  .pl-lg-18,
  .px-lg-18 {
    padding-left: 9rem !important; }
  .p-lg-19 {
    padding: 9.5rem !important; }
  .pt-lg-19,
  .py-lg-19 {
    padding-top: 9.5rem !important; }
  .pr-lg-19,
  .px-lg-19 {
    padding-right: 9.5rem !important; }
  .pb-lg-19,
  .py-lg-19 {
    padding-bottom: 9.5rem !important; }
  .pl-lg-19,
  .px-lg-19 {
    padding-left: 9.5rem !important; }
  .p-lg-20 {
    padding: 10rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 10rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 10rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 10rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 10rem !important; }
  .p-lg-21 {
    padding: 10.5rem !important; }
  .pt-lg-21,
  .py-lg-21 {
    padding-top: 10.5rem !important; }
  .pr-lg-21,
  .px-lg-21 {
    padding-right: 10.5rem !important; }
  .pb-lg-21,
  .py-lg-21 {
    padding-bottom: 10.5rem !important; }
  .pl-lg-21,
  .px-lg-21 {
    padding-left: 10.5rem !important; }
  .p-lg-26 {
    padding: 13rem !important; }
  .pt-lg-26,
  .py-lg-26 {
    padding-top: 13rem !important; }
  .pr-lg-26,
  .px-lg-26 {
    padding-right: 13rem !important; }
  .pb-lg-26,
  .py-lg-26 {
    padding-bottom: 13rem !important; }
  .pl-lg-26,
  .px-lg-26 {
    padding-left: 13rem !important; }
  .p-lg-28 {
    padding: 14rem !important; }
  .pt-lg-28,
  .py-lg-28 {
    padding-top: 14rem !important; }
  .pr-lg-28,
  .px-lg-28 {
    padding-right: 14rem !important; }
  .pb-lg-28,
  .py-lg-28 {
    padding-bottom: 14rem !important; }
  .pl-lg-28,
  .px-lg-28 {
    padding-left: 14rem !important; }
  .m-lg-n1 {
    margin: -0.5rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important; }
  .m-lg-n2 {
    margin: -1rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important; }
  .m-lg-n3 {
    margin: -1.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important; }
  .m-lg-n4 {
    margin: -2rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -2rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -2rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -2rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -2rem !important; }
  .m-lg-n5 {
    margin: -2.5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.5rem !important; }
  .m-lg-n6 {
    margin: -3rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -3rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -3rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -3rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -3rem !important; }
  .m-lg-n7 {
    margin: -3.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3.5rem !important; }
  .m-lg-n8 {
    margin: -4rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -4rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -4rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -4rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -4rem !important; }
  .m-lg-n9 {
    margin: -4.5rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -4.5rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -4.5rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -4.5rem !important; }
  .m-lg-n10 {
    margin: -5rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -5rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -5rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -5rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -5rem !important; }
  .m-lg-n11 {
    margin: -5.5rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5.5rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5.5rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5.5rem !important; }
  .m-lg-n12 {
    margin: -6rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -6rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -6rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -6rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -6rem !important; }
  .m-lg-n13 {
    margin: -6.5rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -6.5rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -6.5rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -6.5rem !important; }
  .m-lg-n14 {
    margin: -7rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -7rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -7rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -7rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -7rem !important; }
  .m-lg-n15 {
    margin: -7.5rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -7.5rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -7.5rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -7.5rem !important; }
  .m-lg-n16 {
    margin: -8rem !important; }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -8rem !important; }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -8rem !important; }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -8rem !important; }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -8rem !important; }
  .m-lg-n17 {
    margin: -8.5rem !important; }
  .mt-lg-n17,
  .my-lg-n17 {
    margin-top: -8.5rem !important; }
  .mr-lg-n17,
  .mx-lg-n17 {
    margin-right: -8.5rem !important; }
  .mb-lg-n17,
  .my-lg-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-lg-n17,
  .mx-lg-n17 {
    margin-left: -8.5rem !important; }
  .m-lg-n18 {
    margin: -9rem !important; }
  .mt-lg-n18,
  .my-lg-n18 {
    margin-top: -9rem !important; }
  .mr-lg-n18,
  .mx-lg-n18 {
    margin-right: -9rem !important; }
  .mb-lg-n18,
  .my-lg-n18 {
    margin-bottom: -9rem !important; }
  .ml-lg-n18,
  .mx-lg-n18 {
    margin-left: -9rem !important; }
  .m-lg-n19 {
    margin: -9.5rem !important; }
  .mt-lg-n19,
  .my-lg-n19 {
    margin-top: -9.5rem !important; }
  .mr-lg-n19,
  .mx-lg-n19 {
    margin-right: -9.5rem !important; }
  .mb-lg-n19,
  .my-lg-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-lg-n19,
  .mx-lg-n19 {
    margin-left: -9.5rem !important; }
  .m-lg-n20 {
    margin: -10rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -10rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -10rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -10rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -10rem !important; }
  .m-lg-n21 {
    margin: -10.5rem !important; }
  .mt-lg-n21,
  .my-lg-n21 {
    margin-top: -10.5rem !important; }
  .mr-lg-n21,
  .mx-lg-n21 {
    margin-right: -10.5rem !important; }
  .mb-lg-n21,
  .my-lg-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-lg-n21,
  .mx-lg-n21 {
    margin-left: -10.5rem !important; }
  .m-lg-n26 {
    margin: -13rem !important; }
  .mt-lg-n26,
  .my-lg-n26 {
    margin-top: -13rem !important; }
  .mr-lg-n26,
  .mx-lg-n26 {
    margin-right: -13rem !important; }
  .mb-lg-n26,
  .my-lg-n26 {
    margin-bottom: -13rem !important; }
  .ml-lg-n26,
  .mx-lg-n26 {
    margin-left: -13rem !important; }
  .m-lg-n28 {
    margin: -14rem !important; }
  .mt-lg-n28,
  .my-lg-n28 {
    margin-top: -14rem !important; }
  .mr-lg-n28,
  .mx-lg-n28 {
    margin-right: -14rem !important; }
  .mb-lg-n28,
  .my-lg-n28 {
    margin-bottom: -14rem !important; }
  .ml-lg-n28,
  .mx-lg-n28 {
    margin-left: -14rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 1.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important; }
  .m-xl-4 {
    margin: 2rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 2rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 2rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 2rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 2rem !important; }
  .m-xl-5 {
    margin: 2.5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.5rem !important; }
  .m-xl-6 {
    margin: 3rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 3rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 3rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 3rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 3rem !important; }
  .m-xl-7 {
    margin: 3.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3.5rem !important; }
  .m-xl-8 {
    margin: 4rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 4rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 4rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 4rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 4rem !important; }
  .m-xl-9 {
    margin: 4.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 4.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 4.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 4.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 4.5rem !important; }
  .m-xl-10 {
    margin: 5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 5rem !important; }
  .m-xl-11 {
    margin: 5.5rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem !important; }
  .m-xl-12 {
    margin: 6rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 6rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 6rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 6rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 6rem !important; }
  .m-xl-13 {
    margin: 6.5rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 6.5rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 6.5rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 6.5rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 6.5rem !important; }
  .m-xl-14 {
    margin: 7rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 7rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 7rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 7rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 7rem !important; }
  .m-xl-15 {
    margin: 7.5rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 7.5rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 7.5rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 7.5rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 7.5rem !important; }
  .m-xl-16 {
    margin: 8rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 8rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 8rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 8rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 8rem !important; }
  .m-xl-17 {
    margin: 8.5rem !important; }
  .mt-xl-17,
  .my-xl-17 {
    margin-top: 8.5rem !important; }
  .mr-xl-17,
  .mx-xl-17 {
    margin-right: 8.5rem !important; }
  .mb-xl-17,
  .my-xl-17 {
    margin-bottom: 8.5rem !important; }
  .ml-xl-17,
  .mx-xl-17 {
    margin-left: 8.5rem !important; }
  .m-xl-18 {
    margin: 9rem !important; }
  .mt-xl-18,
  .my-xl-18 {
    margin-top: 9rem !important; }
  .mr-xl-18,
  .mx-xl-18 {
    margin-right: 9rem !important; }
  .mb-xl-18,
  .my-xl-18 {
    margin-bottom: 9rem !important; }
  .ml-xl-18,
  .mx-xl-18 {
    margin-left: 9rem !important; }
  .m-xl-19 {
    margin: 9.5rem !important; }
  .mt-xl-19,
  .my-xl-19 {
    margin-top: 9.5rem !important; }
  .mr-xl-19,
  .mx-xl-19 {
    margin-right: 9.5rem !important; }
  .mb-xl-19,
  .my-xl-19 {
    margin-bottom: 9.5rem !important; }
  .ml-xl-19,
  .mx-xl-19 {
    margin-left: 9.5rem !important; }
  .m-xl-20 {
    margin: 10rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 10rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 10rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 10rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 10rem !important; }
  .m-xl-21 {
    margin: 10.5rem !important; }
  .mt-xl-21,
  .my-xl-21 {
    margin-top: 10.5rem !important; }
  .mr-xl-21,
  .mx-xl-21 {
    margin-right: 10.5rem !important; }
  .mb-xl-21,
  .my-xl-21 {
    margin-bottom: 10.5rem !important; }
  .ml-xl-21,
  .mx-xl-21 {
    margin-left: 10.5rem !important; }
  .m-xl-26 {
    margin: 13rem !important; }
  .mt-xl-26,
  .my-xl-26 {
    margin-top: 13rem !important; }
  .mr-xl-26,
  .mx-xl-26 {
    margin-right: 13rem !important; }
  .mb-xl-26,
  .my-xl-26 {
    margin-bottom: 13rem !important; }
  .ml-xl-26,
  .mx-xl-26 {
    margin-left: 13rem !important; }
  .m-xl-28 {
    margin: 14rem !important; }
  .mt-xl-28,
  .my-xl-28 {
    margin-top: 14rem !important; }
  .mr-xl-28,
  .mx-xl-28 {
    margin-right: 14rem !important; }
  .mb-xl-28,
  .my-xl-28 {
    margin-bottom: 14rem !important; }
  .ml-xl-28,
  .mx-xl-28 {
    margin-left: 14rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 1.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important; }
  .p-xl-4 {
    padding: 2rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 2rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 2rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 2rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 2rem !important; }
  .p-xl-5 {
    padding: 2.5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.5rem !important; }
  .p-xl-6 {
    padding: 3rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 3rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 3rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 3rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 3rem !important; }
  .p-xl-7 {
    padding: 3.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3.5rem !important; }
  .p-xl-8 {
    padding: 4rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 4rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 4rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 4rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 4rem !important; }
  .p-xl-9 {
    padding: 4.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 4.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 4.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 4.5rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 4.5rem !important; }
  .p-xl-10 {
    padding: 5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 5rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 5rem !important; }
  .p-xl-11 {
    padding: 5.5rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem !important; }
  .p-xl-12 {
    padding: 6rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 6rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 6rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 6rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 6rem !important; }
  .p-xl-13 {
    padding: 6.5rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 6.5rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 6.5rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 6.5rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6.5rem !important; }
  .p-xl-14 {
    padding: 7rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 7rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 7rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 7rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 7rem !important; }
  .p-xl-15 {
    padding: 7.5rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 7.5rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 7.5rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 7.5rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 7.5rem !important; }
  .p-xl-16 {
    padding: 8rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 8rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 8rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 8rem !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 8rem !important; }
  .p-xl-17 {
    padding: 8.5rem !important; }
  .pt-xl-17,
  .py-xl-17 {
    padding-top: 8.5rem !important; }
  .pr-xl-17,
  .px-xl-17 {
    padding-right: 8.5rem !important; }
  .pb-xl-17,
  .py-xl-17 {
    padding-bottom: 8.5rem !important; }
  .pl-xl-17,
  .px-xl-17 {
    padding-left: 8.5rem !important; }
  .p-xl-18 {
    padding: 9rem !important; }
  .pt-xl-18,
  .py-xl-18 {
    padding-top: 9rem !important; }
  .pr-xl-18,
  .px-xl-18 {
    padding-right: 9rem !important; }
  .pb-xl-18,
  .py-xl-18 {
    padding-bottom: 9rem !important; }
  .pl-xl-18,
  .px-xl-18 {
    padding-left: 9rem !important; }
  .p-xl-19 {
    padding: 9.5rem !important; }
  .pt-xl-19,
  .py-xl-19 {
    padding-top: 9.5rem !important; }
  .pr-xl-19,
  .px-xl-19 {
    padding-right: 9.5rem !important; }
  .pb-xl-19,
  .py-xl-19 {
    padding-bottom: 9.5rem !important; }
  .pl-xl-19,
  .px-xl-19 {
    padding-left: 9.5rem !important; }
  .p-xl-20 {
    padding: 10rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 10rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 10rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 10rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 10rem !important; }
  .p-xl-21 {
    padding: 10.5rem !important; }
  .pt-xl-21,
  .py-xl-21 {
    padding-top: 10.5rem !important; }
  .pr-xl-21,
  .px-xl-21 {
    padding-right: 10.5rem !important; }
  .pb-xl-21,
  .py-xl-21 {
    padding-bottom: 10.5rem !important; }
  .pl-xl-21,
  .px-xl-21 {
    padding-left: 10.5rem !important; }
  .p-xl-26 {
    padding: 13rem !important; }
  .pt-xl-26,
  .py-xl-26 {
    padding-top: 13rem !important; }
  .pr-xl-26,
  .px-xl-26 {
    padding-right: 13rem !important; }
  .pb-xl-26,
  .py-xl-26 {
    padding-bottom: 13rem !important; }
  .pl-xl-26,
  .px-xl-26 {
    padding-left: 13rem !important; }
  .p-xl-28 {
    padding: 14rem !important; }
  .pt-xl-28,
  .py-xl-28 {
    padding-top: 14rem !important; }
  .pr-xl-28,
  .px-xl-28 {
    padding-right: 14rem !important; }
  .pb-xl-28,
  .py-xl-28 {
    padding-bottom: 14rem !important; }
  .pl-xl-28,
  .px-xl-28 {
    padding-left: 14rem !important; }
  .m-xl-n1 {
    margin: -0.5rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important; }
  .m-xl-n2 {
    margin: -1rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important; }
  .m-xl-n3 {
    margin: -1.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important; }
  .m-xl-n4 {
    margin: -2rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -2rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -2rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -2rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -2rem !important; }
  .m-xl-n5 {
    margin: -2.5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.5rem !important; }
  .m-xl-n6 {
    margin: -3rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -3rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -3rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -3rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -3rem !important; }
  .m-xl-n7 {
    margin: -3.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3.5rem !important; }
  .m-xl-n8 {
    margin: -4rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -4rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -4rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -4rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -4rem !important; }
  .m-xl-n9 {
    margin: -4.5rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -4.5rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -4.5rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -4.5rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -4.5rem !important; }
  .m-xl-n10 {
    margin: -5rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -5rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -5rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -5rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -5rem !important; }
  .m-xl-n11 {
    margin: -5.5rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5.5rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5.5rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5.5rem !important; }
  .m-xl-n12 {
    margin: -6rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -6rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -6rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -6rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -6rem !important; }
  .m-xl-n13 {
    margin: -6.5rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -6.5rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -6.5rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -6.5rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -6.5rem !important; }
  .m-xl-n14 {
    margin: -7rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -7rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -7rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -7rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -7rem !important; }
  .m-xl-n15 {
    margin: -7.5rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -7.5rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -7.5rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -7.5rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -7.5rem !important; }
  .m-xl-n16 {
    margin: -8rem !important; }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -8rem !important; }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -8rem !important; }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -8rem !important; }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -8rem !important; }
  .m-xl-n17 {
    margin: -8.5rem !important; }
  .mt-xl-n17,
  .my-xl-n17 {
    margin-top: -8.5rem !important; }
  .mr-xl-n17,
  .mx-xl-n17 {
    margin-right: -8.5rem !important; }
  .mb-xl-n17,
  .my-xl-n17 {
    margin-bottom: -8.5rem !important; }
  .ml-xl-n17,
  .mx-xl-n17 {
    margin-left: -8.5rem !important; }
  .m-xl-n18 {
    margin: -9rem !important; }
  .mt-xl-n18,
  .my-xl-n18 {
    margin-top: -9rem !important; }
  .mr-xl-n18,
  .mx-xl-n18 {
    margin-right: -9rem !important; }
  .mb-xl-n18,
  .my-xl-n18 {
    margin-bottom: -9rem !important; }
  .ml-xl-n18,
  .mx-xl-n18 {
    margin-left: -9rem !important; }
  .m-xl-n19 {
    margin: -9.5rem !important; }
  .mt-xl-n19,
  .my-xl-n19 {
    margin-top: -9.5rem !important; }
  .mr-xl-n19,
  .mx-xl-n19 {
    margin-right: -9.5rem !important; }
  .mb-xl-n19,
  .my-xl-n19 {
    margin-bottom: -9.5rem !important; }
  .ml-xl-n19,
  .mx-xl-n19 {
    margin-left: -9.5rem !important; }
  .m-xl-n20 {
    margin: -10rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -10rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -10rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -10rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -10rem !important; }
  .m-xl-n21 {
    margin: -10.5rem !important; }
  .mt-xl-n21,
  .my-xl-n21 {
    margin-top: -10.5rem !important; }
  .mr-xl-n21,
  .mx-xl-n21 {
    margin-right: -10.5rem !important; }
  .mb-xl-n21,
  .my-xl-n21 {
    margin-bottom: -10.5rem !important; }
  .ml-xl-n21,
  .mx-xl-n21 {
    margin-left: -10.5rem !important; }
  .m-xl-n26 {
    margin: -13rem !important; }
  .mt-xl-n26,
  .my-xl-n26 {
    margin-top: -13rem !important; }
  .mr-xl-n26,
  .mx-xl-n26 {
    margin-right: -13rem !important; }
  .mb-xl-n26,
  .my-xl-n26 {
    margin-bottom: -13rem !important; }
  .ml-xl-n26,
  .mx-xl-n26 {
    margin-left: -13rem !important; }
  .m-xl-n28 {
    margin: -14rem !important; }
  .mt-xl-n28,
  .my-xl-n28 {
    margin-top: -14rem !important; }
  .mr-xl-n28,
  .mx-xl-n28 {
    margin-right: -14rem !important; }
  .mb-xl-n28,
  .my-xl-n28 {
    margin-bottom: -14rem !important; }
  .ml-xl-n28,
  .mx-xl-n28 {
    margin-left: -14rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-xxs-left {
    text-align: left !important; }
  .text-xxs-right {
    text-align: right !important; }
  .text-xxs-center {
    text-align: center !important; } }

@media (min-width: 767px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-ssm-left {
    text-align: left !important; }
  .text-ssm-right {
    text-align: right !important; }
  .text-ssm-center {
    text-align: center !important; } }

@media (min-width: 1281px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #444444 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1e1e1e !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: 100%; }

body.menu-open .app-wrapper > .app {
  overflow: hidden; }

.app-wrapper {
  height: 100vh;
  overflow-y: hidden; }
  .app-wrapper > .app {
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
    box-sizing: border-box; }

* {
  outline: 0px solid white; }

.svg-wrap > span {
  display: inline-block;
  width: inherit;
  height: inherit; }
  .svg-wrap > span > svg {
    width: 100%;
    height: 100%; }

.condensed-200 {
  font-family: 'Gotham Cond SSm A', 'Gotham Cond SSm B';
  font-style: normal;
  font-weight: 200; }

.extra-narrow-200 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 200; }

.extra-narrow-300 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 300; }

.extra-narrow-500 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 500; }

.narrow-200 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 200; }

.narrow-300 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 300; }

.narrow-400 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 400; }

.narrow-500 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 500; }

.narrow-700 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 700; }

.gotham-200 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 200; }

.gotham-300 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 300; }

.gotham-400, .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .lang-switch a {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 400; }

.gotham-700, .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .lang-switch a.active {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 700; }

/**
 * Basic typography style for copy text
 */
body {
  line-height: normal;
  color: #444444; }

.reset-list,
.reset-list li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none; }

.font-14 {
  font-size: 0.875rem; }

.font-36 {
  font-size: 2.25rem; }

.font-22 {
  font-size: 1.375rem; }

.text-primary-fade {
  color: rgba(68, 68, 68, 0.4); }

.text-primary-fade {
  color: rgba(68, 68, 68, 0.4); }

.text-blue {
  color: #4a90e2; }

.action-primary {
  color: rgba(68, 68, 68, 0.4);
  transition: 0.3s all; }
  .action-primary:hover {
    color: #444444; }

.cursor-pointer {
  cursor: pointer; }

.line-height-42 {
  line-height: 43px; }

.line-height-16 {
  line-height: 16px !important; }

/* Font Size */
.font-10 {
  font-size: 0.625rem !important; }

.font-12 {
  font-size: 0.75rem !important; }

.font-14 {
  font-size: 0.875rem !important; }

.font-16 {
  font-size: 1rem !important; }

.font-18 {
  font-size: 1.125rem !important; }

.font-20 {
  font-size: 1.25rem !important; }

.font-22 {
  font-size: 1.375rem !important; }

.font-24 {
  font-size: 1.75rem !important; }

.font-28 {
  font-size: 1.5rem !important; }

.font-32 {
  font-size: 2rem !important; }

@media (min-width: 576px) {
  .font-xxs-10 {
    font-size: 0.625rem !important; }
  .font-xxs-12 {
    font-size: 0.75rem !important; }
  .font-xxs-14 {
    font-size: 0.875rem !important; }
  .font-xxs-16 {
    font-size: 1rem !important; }
  .font-xxs-18 {
    font-size: 1.125rem !important; }
  .font-xxs-20 {
    font-size: 1.25rem !important; }
  .font-xxs-22 {
    font-size: 1.375rem !important; }
  .font-xxs-24 {
    font-size: 1.75rem !important; }
  .font-xxs-28 {
    font-size: 1.5rem !important; }
  .font-xxs-32 {
    font-size: 2rem !important; } }

@media (min-width: 767px) {
  .font-sm-10 {
    font-size: 0.625rem !important; }
  .font-sm-12 {
    font-size: 0.75rem !important; }
  .font-sm-14 {
    font-size: 0.875rem !important; }
  .font-sm-16 {
    font-size: 1rem !important; }
  .font-sm-18 {
    font-size: 1.125rem !important; }
  .font-sm-20 {
    font-size: 1.25rem !important; }
  .font-sm-22 {
    font-size: 1.375rem !important; }
  .font-sm-24 {
    font-size: 1.75rem !important; }
  .font-sm-28 {
    font-size: 1.5rem !important; }
  .font-sm-32 {
    font-size: 2rem !important; } }

@media (min-width: 992px) {
  .font-ssm-10 {
    font-size: 0.625rem !important; }
  .font-ssm-12 {
    font-size: 0.75rem !important; }
  .font-ssm-14 {
    font-size: 0.875rem !important; }
  .font-ssm-16 {
    font-size: 1rem !important; }
  .font-ssm-18 {
    font-size: 1.125rem !important; }
  .font-ssm-20 {
    font-size: 1.25rem !important; }
  .font-ssm-22 {
    font-size: 1.375rem !important; }
  .font-ssm-24 {
    font-size: 1.75rem !important; }
  .font-ssm-28 {
    font-size: 1.5rem !important; }
  .font-ssm-32 {
    font-size: 2rem !important; } }

@media (min-width: 1281px) {
  .font-md-10 {
    font-size: 0.625rem !important; }
  .font-md-12 {
    font-size: 0.75rem !important; }
  .font-md-14 {
    font-size: 0.875rem !important; }
  .font-md-16 {
    font-size: 1rem !important; }
  .font-md-18 {
    font-size: 1.125rem !important; }
  .font-md-20 {
    font-size: 1.25rem !important; }
  .font-md-22 {
    font-size: 1.375rem !important; }
  .font-md-24 {
    font-size: 1.75rem !important; }
  .font-md-28 {
    font-size: 1.5rem !important; }
  .font-md-32 {
    font-size: 2rem !important; } }

@media (min-width: 1400px) {
  .font-lg-10 {
    font-size: 0.625rem !important; }
  .font-lg-12 {
    font-size: 0.75rem !important; }
  .font-lg-14 {
    font-size: 0.875rem !important; }
  .font-lg-16 {
    font-size: 1rem !important; }
  .font-lg-18 {
    font-size: 1.125rem !important; }
  .font-lg-20 {
    font-size: 1.25rem !important; }
  .font-lg-22 {
    font-size: 1.375rem !important; }
  .font-lg-24 {
    font-size: 1.75rem !important; }
  .font-lg-28 {
    font-size: 1.5rem !important; }
  .font-lg-32 {
    font-size: 2rem !important; } }

@media (min-width: 1600px) {
  .font-xl-10 {
    font-size: 0.625rem !important; }
  .font-xl-12 {
    font-size: 0.75rem !important; }
  .font-xl-14 {
    font-size: 0.875rem !important; }
  .font-xl-16 {
    font-size: 1rem !important; }
  .font-xl-18 {
    font-size: 1.125rem !important; }
  .font-xl-20 {
    font-size: 1.25rem !important; }
  .font-xl-22 {
    font-size: 1.375rem !important; }
  .font-xl-24 {
    font-size: 1.75rem !important; }
  .font-xl-28 {
    font-size: 1.5rem !important; }
  .font-xl-32 {
    font-size: 2rem !important; } }

.max-width-480 {
  max-width: 480px; }

.max-width-512 {
  max-width: 32rem; }

.max-height-120 {
  max-height: 7.5rem; }

.max-height-688 {
  max-height: 688px; }

.max-width-1400 {
  max-width: 1400px;
  width: 100%;
  margin: auto; }

.tooltip-inner {
  font-size: 8px;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #444444; }
  .tooltip-inner + .arrow:before {
    border-top-color: white; }

.scrollbar, .form-wrapper .form-wrapper--inputs {
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }
  .scrollbar::-webkit-scrollbar, .form-wrapper .form-wrapper--inputs::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px; }
  .scrollbar::-webkit-scrollbar-thumb, .form-wrapper .form-wrapper--inputs::-webkit-scrollbar-thumb {
    background-color: rgba(68, 68, 68, 0.15);
    border-radius: 3px; }
  .scrollbar::-webkit-scrollbar-track, .form-wrapper .form-wrapper--inputs::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 3px; }
  .scrollbar::-webkit-scrollbar-track, .form-wrapper .form-wrapper--inputs::-webkit-scrollbar-track {
    background-color: transparent; }

.opacity-gradient {
  height: 98px;
  background: linear-gradient(rgba(255, 255, 255, 0.1), white);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0; }

.screen-divided-effect {
  position: relative; }
  .screen-divided-effect:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: #f5f5f5;
    z-index: -1; }

.page {
  overflow: hidden;
  padding-top: 3.75rem;
  max-height: 0;
  opacity: 0;
  transition: opacity 800ms ease-in-out 500ms; }
  .page.is-loaded {
    opacity: 1;
    max-height: initial; }
    .page.is-loaded .page-tabs.active span {
      transform: translate3d(0, 0, 0); }
    .page.is-loaded .cover img.loaded,
    .page.is-loaded .cover video.loaded {
      opacity: 1; }

@media (min-width: 767px) {
  .page {
    padding-top: 0; } }

@media (min-width: 1281px) {
  .page {
    padding-right: 5rem; }
  .screen-divided-effect:before {
    transform: translateX(calc(-50% - 2.5rem)); } }

@media only screen and (min-width: 767px) and (orientation: portrait) {
  .page {
    padding-right: 0;
    padding-top: 7rem; } }

.pointer {
  cursor: pointer; }

.form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .form-wrapper .form-wrapper--inputs {
    flex: 1;
    padding-right: 1rem !important; }
    .form-wrapper .form-wrapper--inputs-no-spacing {
      padding-right: 0 !important; }
  .form-wrapper .form-wrapper--actions {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    @media (min-width: 1281px) {
      .form-wrapper .form-wrapper--actions {
        margin-bottom: 4rem; } }

.modal-open:not(.page-transition) #root {
  filter: blur(4px); }

.modal-backdrop {
  background: none; }

.modal-content {
  border-radius: 0; }

.modal-header {
  border: none;
  padding: 1rem; }
  @media (max-width: 1599.98px) {
    .modal-header {
      position: absolute;
      width: 100%;
      z-index: 1; } }

.modal-dialog {
  max-width: 60%; }
  @media (max-width: 1599.98px) {
    .modal-dialog {
      max-width: 85%; } }

.font-weight-medium,
.font-weight-500 {
  font-weight: 500; }

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  width: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  z-index: 30;
  display: none; }
  .sidebar .logo {
    height: 5rem;
    width: 65px; }
  @media (min-width: 1281px) {
    .sidebar {
      display: flex;
      width: 7.5rem; }
      .sidebar .login {
        font-size: 1rem !important; } }
  @media (min-width: 1400px) {
    .sidebar {
      width: 10rem; }
      .sidebar .logo {
        height: 100px;
        width: 98px; } }

.sidebar-mobile {
  position: fixed;
  top: 0;
  background-color: #fff;
  width: 100%;
  z-index: 30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 2rem;
  align-items: center; }
  @media (min-width: 767px) {
    .sidebar-mobile {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .sidebar-mobile .logo > a {
    display: inline-flex; }
  .sidebar-mobile .burger {
    transform: rotate(0deg); }
  .sidebar-mobile .logo-mobile-icon {
    height: 2rem;
    width: 2rem; }
    @media (min-width: 767px) {
      .sidebar-mobile .logo-mobile-icon {
        height: 2.5rem;
        width: 2.5rem; } }
  .sidebar-mobile .logo-mobile-text {
    margin-top: 2px;
    display: none !important; }
    @media (min-width: 767px) {
      .sidebar-mobile .logo-mobile-text {
        display: inline-flex !important; } }
    .sidebar-mobile .logo-mobile-text > span {
      display: inline-flex;
      align-items: center; }
  @media (min-width: 767px) {
    .sidebar-mobile {
      padding-left: 2.5rem; } }
  @media (min-width: 1281px) {
    .sidebar-mobile {
      display: none; } }

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  transform: translate3d(100%, 0, 0);
  -webkit-transition: transform 0.6s ease;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: all 0.6s ease;
  will-change: transform;
  background: rgba(255, 255, 255, 0.95);
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 3vh;
  overflow: hidden;
  /**menu animation**/ }
  @media (min-width: 767px) {
    .menu {
      padding-right: 0.5rem;
      padding-bottom: 2rem;
      flex-direction: column;
      background: #ffffff;
      padding-top: 6rem;
      padding-bottom: 2rem;
      padding-left: 2.5rem; } }
  @media (min-width: 992px) {
    .menu {
      min-width: unset;
      max-width: unset;
      width: 60%;
      min-width: 512px; } }
  @media (min-width: 1281px) {
    .menu {
      min-width: 920px;
      width: 40%;
      padding-top: 2rem;
      padding-left: 4rem; } }
  .menu a {
    text-decoration: none !important; }
  .menu .menu-slide-container > div {
    flex: 0 0 100%; }
    .menu .menu-slide-container > div .overflow-hidden {
      height: 100%; }
  .menu .menu-slide-container .menu-content {
    height: 100%; }
    .menu .menu-slide-container .menu-content > div {
      flex: 0 0 100%; }
  .menu--mobile {
    padding: 2rem 1.625rem 0 !important; }
  .menu.open {
    transform: translate3d(0%, 0, 0); }
    .menu.open .first-level-title,
    .menu.open .action {
      opacity: 1;
      transform: translate3d(0%, 0, 0); }
  .menu .menu-slide-container .slide {
    transform: translate3d(0%, 0, 0);
    transition: transform 0.65s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: transform;
    overflow: hidden; }
  .menu .menu-content {
    height: 100%; }
    .menu .menu-content > div {
      flex: 0 0 100%;
      transition: transform 0.65s cubic-bezier(0.4, 0, 0.2, 1); }
    .menu .menu-content.tournament .fact-sheet ol > li {
      margin-bottom: 0.875rem;
      list-style-type: lower-greek;
      font-size: 0.75rem; }
      @media (min-width: 1281px) {
        .menu .menu-content.tournament .fact-sheet ol > li {
          font-size: 1rem; } }
    .menu .menu-content.tournament .fact-sheet dl,
    .menu .menu-content.tournament .fact-sheet dt {
      font-weight: 300; }
    .menu .menu-content.tournament .fact-sheet dt {
      font-size: 0.75rem;
      margin-bottom: 0.875rem; }
      @media (min-width: 1281px) {
        .menu .menu-content.tournament .fact-sheet dt {
          font-size: 1rem; } }
      .menu .menu-content.tournament .fact-sheet dt:first-child {
        margin-top: 0.875rem; }
    .menu .menu-content.tournament .participants ol > li {
      margin-top: 1rem;
      font-size: 0.75rem; }
      @media (min-width: 1281px) {
        .menu .menu-content.tournament .participants ol > li {
          font-size: 1rem; } }
    .menu .menu-content.tournament .draws {
      font-size: 0.75rem; }
      @media (min-width: 1281px) {
        .menu .menu-content.tournament .draws {
          font-size: 1rem; } }
      .menu .menu-content.tournament .draws .pdf-link-container {
        margin-bottom: 1rem; }
      .menu .menu-content.tournament .draws a {
        text-decoration: none !important; }
      .menu .menu-content.tournament .draws .pdf-icon {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        width: 1.625rem;
        height: 1.625rem;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center; }
        .menu .menu-content.tournament .draws .pdf-icon svg {
          margin-bottom: 0.5rem; }
  .menu.is-flipped .menu-slide-container .slide {
    will-change: transform;
    transform: translate3d(-100%, 0, 0); }
  .menu.is-flipped .menu-content.join_us > div {
    transform: translate3d(-100%, 0, 0); }
  .menu.is-flipped .menu-content.part_of_tact > div {
    transform: translate3d(-100%, 0, 0); }
  .menu.is-flipped .menu-content.career > div {
    transform: translate3d(-200%, 0, 0); }
  .menu.is-flipped .menu-content.legal > div, .menu.is-flipped .menu-content[class*='legal_'] > div {
    transform: translate3d(-300%, 0, 0); }
  .menu.is-flipped .menu-content.tournament > div {
    transform: translate3d(-400%, 0, 0); }
  .menu.is-flipped .first-level-title {
    transform: translate(200px, 0); }
    .menu.is-flipped .first-level-title:after {
      transform: translate(200px, 0); }
  .menu .react-card-front {
    opacity: 1; }
  .menu .react-card-back {
    opacity: 0; }
  .menu .react-card-front,
  .menu .react-card-back {
    transition: transform 0.6s ease 0s, opacity 0.3s ease !important; }
  .menu.is-flipped .react-card-front {
    opacity: 0; }
  .menu.is-flipped .react-card-back {
    opacity: 1; }
  .menu .legal,
  .menu [class*='legal_'] {
    font-size: 0.75rem; }

.menu-shadow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: red;
  background-color: rgba(255, 255, 255, 0);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: all 0.4s ease;
  will-change: transform;
  z-index: 10; }
  .menu-shadow.open {
    transform: translate3d(0%, 0, 0);
    background-color: rgba(255, 255, 255, 0.5); }

.menu-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%; }
  .menu-links .first-level {
    width: 220px;
    position: relative;
    background: transparent; }
    .menu-links .first-level:nth-child(1) .first-level-title {
      transition: all 0.4s; }
      .menu-links .first-level:nth-child(1) .first-level-title:after {
        transition: all 0.4s; }
    .menu-links .first-level:nth-child(2) .first-level-title {
      transition: all 0.6s; }
      .menu-links .first-level:nth-child(2) .first-level-title:after {
        transition: all 0.6s; }
    .menu-links .first-level:nth-child(3) .first-level-title {
      transition: all 0.8s; }
      .menu-links .first-level:nth-child(3) .first-level-title:after {
        transition: all 0.8s; }
    .menu-links .first-level:nth-child(4) .first-level-title {
      transition: all 1.1s; }
      .menu-links .first-level:nth-child(4) .first-level-title:after {
        transition: all 1.1s; }
    .menu-links .first-level:nth-child(5) .first-level-title {
      transition: all 1.3s; }
      .menu-links .first-level:nth-child(5) .first-level-title:after {
        transition: all 1.3s; }
    .menu-links .first-level:nth-child(6) .first-level-title {
      transition: all 1.5s; }
      .menu-links .first-level:nth-child(6) .first-level-title:after {
        transition: all 1.5s; }
    .menu-links .first-level:hover .second-level {
      transition-delay: 0.1s, 0.3s;
      transform: translate3d(100%, 0, 0);
      opacity: 1;
      z-index: 1; }
  .menu-links .first-level-title {
    font-size: 2.4vmax;
    font-weight: 700;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translate(200px, 0);
    cursor: pointer;
    padding-bottom: 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap; }
    @media (min-width: 767px) {
      .menu-links .first-level-title {
        padding-bottom: 2rem;
        font-size: 1rem; } }
    .menu-links .first-level-title .arrow {
      content: '';
      display: inline-block;
      background-image: url("../svg/common/right-arrow.svg");
      width: 1.125rem;
      height: 0.875rem;
      margin-left: 0.5rem;
      transition: all 0.5s;
      transform: translate3d(0, 0, 0);
      background-size: contain;
      background-repeat: no-repeat; }
  .menu-links .second-level {
    position: absolute;
    width: 240px;
    right: 0;
    top: 0px;
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.25s, opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    transition-property: transform opacity; }
  .menu-links .second-level-title {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    position: relative;
    cursor: pointer;
    padding-left: 0.5rem; }
    .menu-links .second-level-title:hover {
      font-weight: bold; }
    @media (min-width: 767px) {
      .menu-links .second-level-title {
        font-size: 1rem; } }
    @media (min-width: 1281px) {
      .menu-links .second-level-title {
        font-size: 1.125rem; } }
  @media (min-width: 767px) {
    .menu-links {
      padding-top: 2rem; } }
  @media (min-width: 1281px) {
    .menu-links .first-level-title {
      font-size: 1.5rem; }
      .menu-links .first-level-title .arrow {
        width: 1.625rem;
        height: 21px;
        margin-right: 1rem; }
    .menu-links .first-level {
      width: 340px; } }
  @media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
    .menu-links {
      justify-content: space-evenly;
      padding-bottom: 1rem;
      padding-top: 0; }
      .menu-links .first-level .first-level-title {
        padding-bottom: 0; } }

.menu-bottom {
  display: flex;
  align-items: flex-start;
  z-index: 10;
  position: relative; }

.lang {
  display: flex;
  align-items: center;
  margin-right: 3rem; }
  .lang div {
    cursor: pointer;
    transition: all 0.4s; }

.menu-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  @media (min-width: 767px) {
    .menu-actions {
      width: initial;
      justify-content: flex-start; } }
  .menu-actions .action {
    position: relative;
    opacity: 0;
    transform: translate(200px, 1.25rem);
    flex: 1;
    margin-bottom: 0;
    padding-bottom: 0; }
    @media (min-width: 767px) {
      .menu-actions .action {
        padding-right: 1.5rem;
        margin-right: 1.5rem;
        flex: auto; } }
    .menu-actions .action:last-child:after {
      display: none; }
    .menu-actions .action:nth-child(1) {
      transition: all 0.4s; }
    .menu-actions .action:nth-child(2) {
      transition: all 0.6s; }
    .menu-actions .action:nth-child(3) {
      transition: all 0.8s; }
    .menu-actions .action:nth-child(4) {
      transition: all 1.1s; }
    .menu-actions .action:nth-child(5) {
      transition: all 1.3s; }
    .menu-actions .action:nth-child(6) {
      transition: all 1.5s; }
    .menu-actions .action:after {
      content: '';
      position: absolute;
      top: 60%;
      right: 35%;
      transform: translateY(-50%);
      width: 0.25rem;
      height: 0.25rem;
      border-radius: 100%;
      background-color: #444444; }
      @media (min-width: 767px) {
        .menu-actions .action:after {
          right: 0; } }
  .menu-actions .svg-wrap {
    min-width: 1.125rem;
    max-width: 1.125rem;
    max-height: 1.125rem; }
    @media (min-width: 767px) {
      .menu-actions .svg-wrap {
        min-width: 1.5rem;
        max-width: 1.5rem;
        max-height: 1.5rem; } }

.menu-panel-title {
  font-size: 0.75rem;
  font-weight: 700;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem; }
  @media (min-width: 1281px) {
    .menu-panel-title {
      font-size: 0.875rem;
      padding-bottom: 2rem; } }
  @media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
    .menu-panel-title {
      margin-bottom: 0.75rem; } }
  .menu-panel-title:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1.5rem;
    height: 0.1875rem;
    background-color: #444444; }

.menu-panel {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .menu-panel .max-width-480 {
    max-width: 95%; }
    @media (min-width: 1281px) {
      .menu-panel .max-width-480 {
        max-width: 78%; } }
    @media (min-width: 1600px) {
      .menu-panel .max-width-480 {
        max-width: 480px; } }
  .menu-panel .input-placeholder,
  .menu-panel .radio-wrapper,
  .menu-panel .radio-label {
    font-size: 0.75rem; }
    @media (min-width: 1281px) {
      .menu-panel .input-placeholder,
      .menu-panel .radio-wrapper,
      .menu-panel .radio-label {
        font-size: 1rem; } }
  .menu-panel .radio-wrapper .checkmark {
    transform: scale(0.8);
    top: -1px; }
    @media (min-width: 1281px) {
      .menu-panel .radio-wrapper .checkmark {
        transform: none;
        top: 2px; } }
  .menu-panel p {
    line-height: 20px;
    font-size: 0.75rem;
    font-weight: 300; }
    @media (min-width: 1281px) {
      .menu-panel p {
        line-height: 32px;
        font-size: 1rem; } }
  .menu-panel ul {
    margin: 0 0 1rem 0 !important;
    font-size: 0.75rem;
    line-height: 20px;
    padding-left: 2.75rem; }
    @media (min-width: 1281px) {
      .menu-panel ul {
        font-size: 1rem;
        line-height: 29px; } }
  .menu-panel input {
    background: transparent; }
  .menu-panel .success-message {
    max-width: 15.5rem;
    margin-top: 13rem; }
  .menu-panel .scrollbar, .menu-panel .form-wrapper .form-wrapper--inputs, .form-wrapper .menu-panel .form-wrapper--inputs {
    padding-right: 0.5rem; }
  .menu-panel .about-us-wrapper {
    max-width: 95%;
    overflow: hidden; }
    @media (min-width: 1281px) {
      .menu-panel .about-us-wrapper {
        max-width: 78%; } }
    .menu-panel .about-us-wrapper .about-us-scrollable {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-right: 17px;
      /* Increase/decrease this value for cross-browser compatibility */
      box-sizing: content-box;
      /* So the width will be 100% + 17px */ }
  .menu-panel--join-us .form-wrapper {
    width: 100% !important; }
  .menu-panel--join-us .form-error {
    font-size: 0.625rem;
    position: relative; }
  .menu-panel--join-us .form-wrapper--actions {
    height: 1.25rem; }
    .menu-panel--join-us .form-wrapper--actions .btn {
      font-size: 0.75rem; }
      @media (min-width: 1281px) {
        .menu-panel--join-us .form-wrapper--actions .btn {
          font-size: 1rem; } }
  .menu-panel--join-us .terms-accepted-error {
    display: none;
    right: 3%;
    padding-top: 0.375rem; }
  .menu-panel--join-us .checkbox-container {
    padding-top: 0.375rem;
    align-items: center; }
    .menu-panel--join-us .checkbox-container .checkbox .uncheckedBox {
      min-width: 0.94rem;
      height: 0.94rem; }
    .menu-panel--join-us .checkbox-container .checkbox .checkedBox {
      line-height: 0;
      min-width: 0.94rem !important;
      height: 0.94rem !important; }
  .menu-panel.part-of-tact .menu-panel-link a {
    color: #4a90e2 !important;
    font-weight: 700; }
  .menu-panel.part-of-tact .menu-panel-subtitle {
    margin-top: 2.5rem; }
  .menu-panel.part-of-tact .menu-panel-measure-impact, .menu-panel.part-of-tact .menu-panel-philosophy {
    margin-top: 1.125rem; }
    .menu-panel.part-of-tact .menu-panel-measure-impact p, .menu-panel.part-of-tact .menu-panel-philosophy p {
      letter-spacing: 0;
      margin: 0 !important; }
  .menu-panel.about .about-us, .menu-panel.part-of-tact .about-us {
    width: auto;
    padding-top: 0.625rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    position: relative;
    white-space: nowrap; }
    @media (min-width: 1281px) {
      .menu-panel.about .about-us, .menu-panel.part-of-tact .about-us {
        padding-top: 1.5rem;
        padding-right: 3rem;
        padding-bottom: 4.5rem;
        padding-left: 4.5rem; } }
    .menu-panel.about .about-us::after, .menu-panel.about .about-us::before, .menu-panel.part-of-tact .about-us::after, .menu-panel.part-of-tact .about-us::before {
      content: '';
      display: inline-block;
      background-image: url("../svg/menu/about_corner.svg");
      width: 1.625rem;
      height: 1.625rem;
      position: absolute; }
    .menu-panel.about .about-us::after, .menu-panel.part-of-tact .about-us::after {
      left: 0px;
      transform: rotate(180deg);
      bottom: 0; }
    .menu-panel.about .about-us::before, .menu-panel.part-of-tact .about-us::before {
      right: 0;
      top: 0; }
    .menu-panel.about .about-us > p:last-child, .menu-panel.part-of-tact .about-us > p:last-child {
      margin-bottom: 0; }

.cookies-policy {
  left: 0;
  bottom: 0px;
  height: 4rem;
  font-size: 0.625rem;
  background: #fff;
  z-index: 31;
  opacity: 1;
  transition: opacity 1000ms ease 0s, visibility 1000ms ease 0s; }
  .cookies-policy .btn {
    box-shadow: none !important;
    font-size: 0.625rem; }
  .cookies-policy .btn-outline-primary {
    border-radius: 0px; }
    .cookies-policy .btn-outline-primary:hover {
      background: #fff;
      color: #444444; }
  .cookies-policy.accepted {
    opacity: 0;
    visibility: hidden; }

@media (min-width: 767px) {
  .menu .legal,
  .menu [class*='legal_'] {
    font-size: 1rem; }
  .btn-login {
    display: none; }
  .first-level-title {
    background: #ffffff; }
  .menu-bottom {
    height: auto;
    align-items: center; }
  .cookies-policy {
    font-size: 0.75rem; }
    .cookies-policy .btn {
      font-size: 0.75rem;
      line-height: 0.75rem; } }

.btn-login {
  right: 1rem;
  bottom: 1.5rem;
  cursor: pointer;
  z-index: 1; }
  .btn-login .svg-wrap {
    height: 2rem;
    width: 2.75rem; }

@media (min-width: 1281px) {
  .cookies-policy {
    font-size: 0.875rem !important; }
    .cookies-policy .btn {
      font-size: 0.875rem !important; }
      .cookies-policy .btn.btn-accept {
        line-height: 0.875rem; } }

.app {
  opacity: 0.01; }
  .app.navigation-enter-active, .app.navigation-enter-done {
    transition: opacity 300ms;
    opacity: 1; }

.page-bottom-nav {
  position: absolute;
  z-index: 1;
  bottom: 8rem; }
  @media only screen and (max-width: 768px) and (orientation: landscape) {
    .page-bottom-nav {
      bottom: 6rem; } }
  @media (min-width: 1281px) {
    .page-bottom-nav {
      bottom: 3.5%; } }
  .page-bottom-nav a {
    text-decoration: none; }
  .page-bottom-nav:not(.page-bottom-nav--previous) {
    right: 2rem; }
    @media (min-width: 767px) {
      .page-bottom-nav:not(.page-bottom-nav--previous) {
        right: 5rem; } }
    @media (min-width: 1281px) {
      .page-bottom-nav:not(.page-bottom-nav--previous) {
        right: 19%; } }
  .page-bottom-nav.page-bottom-nav--previous {
    left: 2rem; }
    @media (min-width: 767px) {
      .page-bottom-nav.page-bottom-nav--previous {
        left: 5rem; } }
    @media (min-width: 1281px) {
      .page-bottom-nav.page-bottom-nav--previous {
        left: 19%; } }
  .page-bottom-nav .page-bottom-nav--label {
    font-size: 0.875rem;
    margin-bottom: 0.125rem; }
    @media (min-width: 767px) {
      .page-bottom-nav .page-bottom-nav--label {
        font-size: 1.125rem;
        margin-bottom: 0.5rem; } }
  .page-bottom-nav .page-bottom-nav--direction {
    font-size: 0.75rem; }
    @media (min-width: 767px) {
      .page-bottom-nav .page-bottom-nav--direction {
        font-size: 1rem; } }

.btn {
  line-height: initial; }

.btn-link {
  text-decoration: solid !important; }

.btn-scroll-top {
  bottom: 3.5rem;
  height: 2.75rem;
  width: 2.75rem;
  right: 3rem;
  z-index: 1;
  cursor: pointer; }

.btn-with-arrow .svg-wrap {
  transition: transform 0.3s; }

.btn-with-arrow:hover:not(.btn-with-arrow--prev) .svg-wrap {
  transform: translate(3px, -3px); }

.btn-with-arrow:hover.btn-with-arrow--prev .svg-wrap {
  transform: translate(-3px, -3px); }

.burger {
  transform: rotate(90deg) translate(-50%, 0);
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  width: 35px;
  height: 1.25rem; }
  @media (min-width: 1281px) {
    .burger {
      width: 53px !important; }
      .burger span {
        width: 47px !important; } }
  .burger span {
    display: block;
    background-color: #000;
    width: 40px;
    height: 0.15em;
    border-radius: 1px;
    position: absolute;
    -webkit-transition: transform 0.3s ease-in-out;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .burger span:first-child {
      top: 0;
      left: 0; }
    .burger span:nth-child(2) {
      top: 8px;
      left: 0; }
    .burger span:last-child {
      top: 16px;
      left: 0; }
  .burger.open span:first-child {
    transform: rotate(-45deg);
    top: 8px; }
  .burger.open span:nth-child(2) {
    background-color: #fff !important; }
  .burger.open span:last-child {
    transform: rotate(45deg);
    top: 8px; }

.react-card-flip {
  flex: 1;
  overflow: hidden; }

.react-card-front,
.react-card-back {
  display: flex;
  flex-direction: column;
  padding-left: 5rem; }
  .react-card-front > div,
  .react-card-back > div {
    overflow: hidden;
    display: flex;
    flex-direction: column; }

.steps {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1; }
  .steps .steps-indicators {
    display: flex;
    min-height: 6px; }
    .steps .steps-indicators > div {
      position: absolute;
      width: 2.5rem;
      position: relative; }
      .steps .steps-indicators > div:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 1px;
        background-color: #444444;
        transition: 0.3s all; }
      .steps .steps-indicators > div.active:after {
        height: 6px; }
  .steps .step-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden; }

.tabs {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 0.75rem; }
  .tabs .tabs-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 5rem; }
    .tabs .tabs-buttons .disabled {
      opacity: 0.5;
      pointer-events: none; }
    .tabs .tabs-buttons > div {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      transition: 0.3s all;
      font-size: 0.875rem;
      position: relative;
      cursor: pointer;
      transform: translateY(0); }
      .tabs .tabs-buttons > div span {
        transform: translateX(0);
        display: inline-block;
        transition: 0.8s all; }
      .tabs .tabs-buttons > div:hover span {
        transform: translateX(10px); }
      .tabs .tabs-buttons > div:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #444444;
        transition: 0.3s all; }
      .tabs .tabs-buttons > div.active {
        font-weight: bold; }
        .tabs .tabs-buttons > div.active:before {
          width: 2px; }
  .tabs .tab-content {
    overflow: auto;
    position: relative; }
    .tabs .tab-content a {
      text-decoration: underline !important; }
  .tabs.has-dropdown .tabs-buttons {
    display: none; }
  .tabs.has-dropdown .tabs-drodown {
    display: block;
    width: 14rem; }

@media (min-width: 1281px) {
  .tabs.has-dropdown .tabs-buttons {
    display: flex; }
  .tabs.has-dropdown .tabs-drodown {
    display: none; } }

.main-slider {
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 300ms ease-out; }
  .main-slider .slick-list,
  .main-slider .slick-track,
  .main-slider .slick-slide,
  .main-slider .slide-inner {
    height: 100%;
    transition: all 300ms ease-out; }
  .main-slider .slick-arrow {
    display: none !important; }
  .main-slider .slide-inner {
    position: relative; }
  .main-slider .slide-inner--image {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: center top; }
    .main-slider .slide-inner--image.object-bottom {
      object-position: center bottom !important; }
  .main-slider .slide-inner--label {
    position: absolute;
    bottom: 20%;
    height: 2.5rem;
    left: 0px;
    color: white;
    font-size: 1.25rem;
    padding-left: 2rem;
    padding-right: 1.5rem;
    line-height: 39px;
    transition: 0.8s all;
    background-image: linear-gradient(135deg, #444444 12.5%, transparent 12.5%, transparent 50%, #444444 50%, #444444 62.5%, transparent 62.5%, transparent 100%);
    background-size: 5.66px 5.66px;
    text-align: right;
    min-width: 230px;
    max-width: 230px; }
    @media (min-width: 767px) {
      .main-slider .slide-inner--label {
        bottom: 2rem; } }
    @media (min-width: 1281px) {
      .main-slider .slide-inner--label {
        bottom: 100px;
        font-size: 2.5rem;
        padding-left: 5rem;
        height: 5rem;
        line-height: 5rem;
        min-width: 420px;
        max-width: 420px;
        background-image: linear-gradient(135deg, #444444 8.33%, transparent 8.33%, transparent 50%, #444444 50%, #444444 58.33%, transparent 58.33%, transparent 100%);
        background-size: 8.49px 8.49px; } }
    @media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
      .main-slider .slide-inner--label {
        bottom: 10%; } }
  .main-slider .slide-inner--info {
    position: absolute;
    left: 2rem;
    top: 13%;
    color: white;
    transform: translate3d(-200%, 0, 0); }
    @media (min-width: 1281px) {
      .main-slider .slide-inner--info {
        left: 5rem;
        top: 2rem; } }
    @media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {
      .main-slider .slide-inner--info {
        top: 30%; } }
    .main-slider .slide-inner--info--divider {
      height: 0.25rem;
      width: 1.5rem;
      background-color: white;
      margin-top: 1.375rem;
      margin-bottom: 0.5rem; }
  .main-slider .slick-dots {
    position: absolute;
    left: 2rem;
    top: 42vh;
    padding: 0;
    display: none !important; }
    @media (min-width: 767px) {
      .main-slider .slick-dots {
        display: block !important; } }
    @media (min-width: 1281px) {
      .main-slider .slick-dots {
        left: 5rem; } }
    .main-slider .slick-dots button {
      padding: 0px;
      color: transparent;
      border-radius: 0px;
      border: none;
      width: 1px;
      overflow: hidden;
      height: 1.75rem;
      margin-bottom: 1rem;
      outline: none;
      box-shadow: none;
      transition: 0.3s width;
      background: white; }
    .main-slider .slick-dots li {
      list-style: none;
      width: 0.5rem; }
      .main-slider .slick-dots li.slick-active button, .main-slider .slick-dots li:hover button {
        width: 0.375rem; }
  .main-slider .slick-current .slide-inner--info {
    transition: transform 0.55s cubic-bezier(0.6, 0.3, 0.11, 0.86);
    transform: translate3d(0, 0, 0); }

.section-left .secondary-slider .slick-dots {
  display: none !important; }

.secondary-slider {
  transition: all 300ms ease-out;
  width: 100%; }
  .secondary-slider .slick-list,
  .secondary-slider .slick-track,
  .secondary-slider .slick-slide,
  .secondary-slider .slide-inner {
    transition: all 300ms ease-out; }
  .secondary-slider .slick-arrow {
    display: none !important; }
  .secondary-slider img {
    width: 552px !important; }
  .secondary-slider .slick-dots {
    position: absolute;
    left: 40%;
    bottom: -3.375rem;
    transform: rotate(180deg);
    padding: 0; }
    .secondary-slider .slick-dots button {
      color: transparent;
      border-radius: 0;
      border: none;
      margin: 0 0.5rem;
      padding: 0;
      height: 1px;
      overflow: hidden;
      width: 2.5rem;
      outline: none;
      box-shadow: none;
      transition: 0.3s height;
      background: #444444; }
    .secondary-slider .slick-dots li {
      display: inline;
      list-style: none;
      width: 0.5rem; }
      .secondary-slider .slick-dots li.slick-active button, .secondary-slider .slick-dots li:hover button {
        height: 0.625rem; }

@media (max-width: 1400px) {
  .secondary-slider img {
    width: 480px !important; } }

@media (max-width: 1280px) {
  .section-left .slick-slider {
    float: right; }
  .secondary-slider {
    width: 300px !important; }
    .secondary-slider .slick-dots {
      left: 30%; }
    .secondary-slider img {
      width: 300px !important; } }

@media (max-width: 767px) {
  .section-left .secondary-slider .slick-dots {
    display: block !important; }
  .section-right .secondary-slider .slick-dots {
    display: none !important; }
  .section-right .secondary-slider {
    margin-left: calc((100vw - 345px) / 2); }
  .secondary-slider .slick-dots {
    bottom: -2.5rem; } }

.cover {
  position: relative;
  z-index: 1;
  height: 315px; }
  .cover img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 0;
    transition: opacity 1000ms ease 0s; }
  .cover video {
    transition: opacity 1000ms ease 0s, visibility 1000ms ease 0s;
    opacity: 0;
    object-fit: cover;
    width: 100%; }

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .cover {
    height: 600px; } }

@media (min-width: 767px) {
  .cover {
    height: 462px; } }

@media (min-width: 1281px) {
  .cover {
    height: auto; }
    .cover video {
      width: calc(100% - 2.5rem); } }

@media (min-width: 1400px) {
  .cover video {
    width: calc(100% - 5rem); } }

.fancy-box {
  padding: 24px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  position: relative;
  transform: translateY(84px);
  transition: 3s all;
  transition-timing-function: ease-out; }
  .fancy-box.animate {
    transform: translateY(0); }
    .fancy-box.animate .fancy-box--inner {
      animation: fancyBoxInnerFrames 0.4s ease-out forwards; }
    .fancy-box.animate:before, .fancy-box.animate:after {
      animation: fancyBoxSudoFrames 0.8s 1s ease-out forwards; }
    .fancy-box.animate .fancy-box--abbrev {
      animation: fancyBoxAbbrevFrames 0.4s 0.8s ease-out forwards; }
  .fancy-box--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 60px;
    background-color: white;
    position: relative;
    z-index: 1;
    border: 0 solid white; }
  .fancy-box:before, .fancy-box:after {
    content: '';
    width: 120px;
    height: 120px;
    background-image: linear-gradient(135deg, #dadada 8.33%, transparent 8.33%, transparent 50%, #dadada 50%, #dadada 58.33%, transparent 58.33%, transparent 100%);
    background-size: 8.49px 8.49px;
    position: absolute;
    opacity: 0; }
  .fancy-box:before {
    top: 0;
    left: 0;
    transform: translate(48px, 48px); }
  .fancy-box:after {
    right: 0;
    bottom: 0;
    transform: translate(-48px, -48px); }
  .fancy-box .fancy-box--abbrev {
    opacity: 0; }

@keyframes fancyBoxSudoFrames {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    transform: translate(0, 0);
    background-image: linear-gradient(135deg, #dadada 8.33%, transparent 8.33%, transparent 50%, #dadada 50%, #dadada 58.33%, transparent 58.33%, transparent 100%);
    background-size: 8.49px 8.49px; } }

@keyframes fancyBoxInnerFrames {
  0% {
    border: 0 solid white; }
  100% {
    border: 12px solid #444444; } }

@keyframes fancyBoxAbbrevFrames {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes opacityAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media (min-width: 1281px) {
  .fancy-box {
    padding: 2rem;
    min-width: 264px;
    max-width: 264px;
    height: 264px;
    margin: 0px 5rem; }
    .fancy-box--inner {
      font-size: 76px; }
    .fancy-box:after, .fancy-box:before {
      width: 172px;
      height: 172px; }
    .fancy-box.less-margin {
      padding: 2rem;
      margin-right: 50px;
      margin-left: 70px; } }

@media (min-width: 1400px) {
  .fancy-box {
    min-width: 304px;
    max-width: 304px;
    height: 304px; } }

@media (min-width: 1600px) {
  .fancy-box {
    padding: 48px;
    min-width: 348px;
    max-width: 348px;
    height: 348px;
    margin-right: 137px;
    margin-left: 180px; } }

.page-navigation {
  display: flex;
  position: relative;
  top: -132px;
  z-index: 2;
  padding-left: 2rem;
  flex-direction: column;
  margin-bottom: -6rem; }
  .page-navigation--title {
    font-size: 2.5rem;
    margin-bottom: 40px;
    overflow: hidden; }
    .page-navigation--title > h1 {
      transition: transform 0.8s cubic-bezier(0.6, 0.3, 0.11, 0.86);
      transform: translate3d(-100%, 0, 0);
      font-weight: 700;
      line-height: normal;
      margin: 0; }
      @media (min-width: 1281px) {
        .page-navigation--title > h1 {
          font-size: 3rem; } }
      @media (min-width: 1600px) {
        .page-navigation--title > h1 {
          font-size: 3.5rem; } }
    .page-navigation--title.active > h1 {
      transform: translate3d(0, 0, 0); }
    @media (min-width: 1281px) {
      .page-navigation--title {
        font-size: 3rem; } }
    @media (min-width: 1600px) {
      .page-navigation--title {
        font-size: 3.5rem; } }
  .page-navigation .page-navigation--main {
    margin: 5rem 0 0 1.25rem; }

.page-tabs {
  display: flex;
  flex-direction: column; }
  .page-tabs--button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1.5rem;
    padding-right: 3rem;
    font-size: 0.875rem;
    transition: 0.3s all;
    cursor: pointer;
    overflow: hidden;
    position: relative; }
    .page-tabs--button.active {
      font-weight: 500; }
      .page-tabs--button.active:after {
        width: 2px; }
    .page-tabs--button:after {
      content: '';
      position: absolute;
      transition: transform 0.15s cubic-bezier(0, 0, 0.08, 0.96);
      transform: rotate(0deg);
      background: #444444;
      height: 100%;
      width: 1px;
      top: 0;
      left: 0; }
    .page-tabs--button span {
      display: inline-block;
      transition: transform 0.4s cubic-bezier(0.6, 0.3, 0.11, 0.86);
      transform: translate3d(-210%, 0, 0); }
    .page-tabs--button:hover {
      overflow: visible; }
      .page-tabs--button:hover span {
        transform: translate3d(10px, 0, 0) !important; }
      .page-tabs--button:hover:after {
        transform: rotate(45deg); }

@media (min-width: 767px) {
  .page-tabs {
    flex-direction: row; }
  .page-navigation {
    padding-left: 5rem;
    margin-bottom: 0px; } }

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .page-navigation {
    margin-bottom: -6rem !important; } }

@media (min-width: 1281px) {
  .page-navigation {
    margin-right: 132px;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 0;
    top: -100px; }
    .page-navigation .page-navigation--main {
      margin: 0;
      margin-bottom: -1.5rem; }
  .page-tabs--button {
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-right: 2rem; } }

@media (min-width: 1400px) {
  .page-navigation {
    top: -132px; }
    .page-navigation .page-navigation--main {
      margin-bottom: 0; } }

@media (min-width: 1600px) {
  .page-tabs--button {
    font-size: 1.125rem;
    padding-left: 2rem;
    padding-right: 4rem; } }

/* 
 * base styling for primary-section
 * and secondary-section
*/
.section {
  display: flex; }
  .section .section-left,
  .section .section-right {
    width: 50%;
    padding: 0 1.5rem;
    transition: 0.7s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.5s; }
    .section .section-left img,
    .section .section-right img {
      transition: transform 0.5s;
      transition-timing-function: ease-out;
      object-fit: cover; }
  .section .section-title h3 {
    font-size: 1.125rem;
    font-weight: bold; }
  .section .section-text {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 2rem; }
  .section img {
    max-width: 100%;
    max-height: 100%; }

.animate-sections .section-title h3 {
  display: inline;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  margin: 0; }
  @media (max-width: 1024px) {
    .animate-sections .section-title h3 {
      font-size: 1.125rem; } }

.animate-sections .section-text p {
  transition: transform 0.55s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.25s;
  transform: translate3d(-110%, 0, 0); }

.animate-sections .section-left {
  clip-path: inset(0 0 0 100%); }
  .animate-sections .section-left .section-title h3,
  .animate-sections .section-left .section-text p {
    transform: translate3d(110%, 0, 0); }

.animate-sections .section-right {
  clip-path: inset(0 100% 0 0); }

.animate-sections.animate .section-left,
.animate-sections.animate .section-right {
  clip-path: inset(0 0 0 0); }

.animate-sections.animate .section-title h3,
.animate-sections.animate .section-text p {
  transition-delay: 0.7s;
  transform: translate3d(0, 0, 0); }

.animate-sections.animated .section-left,
.animate-sections.animated .section-right {
  clip-path: none; }

.primary-section {
  align-items: center;
  position: relative;
  margin-bottom: 4rem;
  clear: both; }
  .primary-section .section {
    flex-direction: column;
    padding: 0 6.5rem; }
  .primary-section:before {
    content: '';
    position: absolute;
    top: 1.5rem;
    bottom: 1.5rem;
    left: 0;
    right: 0;
    background-image: linear-gradient(135deg, #dadada 8.33%, transparent 8.33%, transparent 50%, #dadada 50%, #dadada 58.33%, transparent 58.33%, transparent 100%);
    background-size: 8.49px 8.49px; }
  .primary-section .left-text {
    margin-top: 2.5rem;
    font-size: 2.5rem;
    font-weight: bold;
    opacity: 0.05;
    left: 3rem;
    top: 200vh;
    position: fixed;
    display: none;
    transform: rotate(-90deg);
    transform-origin: left top 0;
    float: left;
    text-align: left;
    white-space: nowrap; }
    @media (min-width: 1281px) {
      .primary-section .left-text {
        margin-top: 1.5rem;
        font-size: 3.5rem; } }
    @media (min-width: 1600px) {
      .primary-section .left-text {
        margin-top: 0;
        font-size: 5.5rem; } }
  .primary-section .section {
    align-items: center;
    z-index: 1;
    position: relative; }
  .primary-section .section-left,
  .primary-section .section-right {
    width: 100%; }
  .primary-section .section-left {
    padding-left: 120px;
    max-width: 30rem; }
    .primary-section .section-left .section-text {
      margin-bottom: 3.5rem; }
  .primary-section .section-right {
    text-align: center; }
  .primary-section .section-title {
    padding-bottom: 2.5rem;
    margin-bottom: 2.5rem;
    position: relative; }
    .primary-section .section-title:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 0.25rem;
      width: 1.5rem;
      background-color: #444444; }
  .primary-section .section-title {
    margin-bottom: 2.5rem; }
  .primary-section img {
    width: 458px;
    height: 327px; }

.mobile-section {
  padding: 0 3rem;
  justify-content: center;
  align-items: center;
  display: flex; }
  .mobile-section::after {
    display: none; }

.tablet-section {
  display: none; }

.secondary-section .section {
  position: relative; }
  .secondary-section .section:after {
    content: '';
    position: absolute;
    bottom: 65px;
    height: 400px;
    left: 0px;
    width: 2000px;
    background-image: linear-gradient(135deg, #dadada 8.33%, transparent 8.33%, transparent 50%, #dadada 50%, #dadada 58.33%, transparent 58.33%, transparent 100%);
    background-size: 8.49px 8.49px;
    z-index: -1; }

.secondary-section .section-left {
  text-align: right; }
  .secondary-section .section-left .section-title span,
  .secondary-section .section-left .section-title h3 {
    margin-left: initial;
    margin-right: 2rem;
    text-align: right; }
  .secondary-section .section-left .section-title .title-container:before {
    left: initial;
    right: 0; }

.secondary-section .section-title {
  padding: 0.75rem 0px;
  margin-bottom: 3.5rem;
  margin-top: 1.5rem; }
  .secondary-section .section-title .title-container {
    margin-left: 2rem;
    display: inline-flex;
    align-items: center;
    position: relative;
    min-height: 74px;
    line-height: 27px; }
    .secondary-section .section-title .title-container:before {
      content: '';
      display: inline-block;
      width: 74px;
      height: 100%;
      border: 6px solid rgba(68, 68, 68, 0.2);
      background-color: white;
      position: absolute;
      top: 0;
      left: -32px;
      z-index: -1; }
  .secondary-section .section-title span,
  .secondary-section .section-title h3 {
    background-color: white;
    padding: 6px 0;
    display: inline-block;
    margin-block-end: 0; }

.secondary-section .section-text {
  margin-bottom: 2.5rem; }
  .secondary-section .section-text p {
    max-width: 21.25rem; }

.secondary-section .align-right {
  text-align: right; }

.secondary-section .mobile-section .section-text p {
  max-width: 25rem; }

.secondary-section .bottom-line {
  height: 175px;
  position: relative;
  transition: 0.7s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.5s; }
  .secondary-section .bottom-line:after {
    content: '';
    position: absolute;
    left: 50%;
    top: calc(50% - 1.75rem);
    height: 3.5rem;
    width: 1px;
    background-color: #444444; }

.secondary-section .small-image {
  display: flex;
  align-items: flex-end; }

.secondary-section.primary-right .small-image {
  justify-content: flex-end; }
  .secondary-section.primary-right .small-image .section-text {
    justify-content: flex-end;
    display: flex; }

.secondary-section img {
  width: 300px;
  height: 280px; }
  .secondary-section img.primary-img {
    height: 449px; }

.sections-header {
  font-size: 3.5rem;
  text-align: center;
  padding: 88px 0;
  position: relative;
  z-index: 1;
  font-weight: bold;
  margin-bottom: 3rem; }
  .sections-header:before, .sections-header:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 3.5rem;
    width: 1px;
    background-color: #444444; }
  .sections-header:before {
    top: 0; }
  .sections-header:after {
    bottom: 0; }
  .sections-header .sections-header--animated {
    display: inline-flex;
    justify-content: center;
    align-items: center; }
    .sections-header .sections-header--animated > div {
      display: inline-block;
      overflow: hidden;
      position: relative;
      transition: opacity 2.9s cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0;
      transform: translateY(100%);
      animation: slide-up 2.9s cubic-bezier(0.23, 1, 0.32, 1) forwards; }
      .sections-header .sections-header--animated > div.sections-header--animated-first {
        text-align: right; }
        .sections-header .sections-header--animated > div.sections-header--animated-first > h2 {
          display: inline;
          font-size: 3.5rem;
          font-weight: 700;
          line-height: normal;
          margin: 0; }
          @media (max-width: 800px) {
            .sections-header .sections-header--animated > div.sections-header--animated-first > h2 {
              font-size: 2rem; } }
      .sections-header .sections-header--animated > div.sections-header--animated-rest {
        padding-left: 0.5rem;
        text-align: left; }
        .sections-header .sections-header--animated > div.sections-header--animated-rest > h2 {
          display: inline;
          font-size: 3.5rem;
          font-weight: 700;
          line-height: normal;
          margin: 0; }
          @media (max-width: 800px) {
            .sections-header .sections-header--animated > div.sections-header--animated-rest > h2 {
              font-size: 2rem; } }

@keyframes slide-up {
  0% {
    transform: translateY(-50%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@media (max-width: 1280px) {
  .primary-section {
    padding-top: 3rem; }
    .primary-section:before {
      top: 0; }
    .primary-section .section-left {
      padding: 0 1.5rem; }
  .secondary-section .section-left,
  .secondary-section .section-right {
    margin-bottom: 2.5rem; } }

@media (max-width: 800px) {
  .section .section-title {
    font-size: 1.125rem; }
    .section .section-title .title-container {
      min-height: 88px; }
      .section .section-title .title-container:before {
        width: 54px; }
      .section .section-title .title-container h3 {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1.6875rem;
        margin-block-end: 0; }
  .section .section-text {
    font-size: 0.875rem;
    margin-bottom: 3rem; }
  .sections-header h2 {
    font-size: 2rem; }
  .page-navigation {
    display: block; }
    .page-navigation .page-navigation--main {
      margin-left: 2.5rem;
      margin-top: 60px; }
    .page-navigation .page-tabs--button {
      margin-bottom: 1rem; }
  .page-tabs {
    direction: block; } }

/**Mobile Portait**/
@media only screen and (max-width: 767px) and (orientation: portrait) {
  .primary-section .section {
    padding: 0px; }
  .primary-section img {
    width: 300px;
    height: 215px; }
  .primary-section .section-left {
    padding: 0;
    width: 300px; }
  .section {
    flex-direction: column; }
    .section .small-image {
      justify-content: center !important; }
  .secondary-section .section-text {
    padding-left: 0px; }
  .secondary-section .section-left,
  .secondary-section .section-right {
    width: 100%;
    text-align: center; } }

@media (min-width: 767px) and (orientation: portrait) {
  .cover {
    height: 800px; }
  .mobile-section {
    display: flex !important; }
  .tablet-section {
    display: none !important; }
  .secondary-section .small-image {
    display: flex !important; }
  .btn-scroll-top {
    bottom: 3.375rem !important;
    right: 7.25rem !important; }
  .section-text {
    padding-left: 0 !important; } }

@media (min-width: 767px) {
  .btn-scroll-top {
    right: 15rem;
    bottom: 7rem; }
  .primary-section {
    margin-bottom: 8rem; }
    .primary-section .section {
      padding: 0; }
    .primary-section .left-text {
      display: block; }
  .mobile-section {
    display: none; }
  .tablet-section {
    display: block;
    overflow: hidden; }
  .secondary-section .small-image {
    display: block; } }

@media (min-width: 1281px) {
  .section .section-title {
    font-size: 1.5rem;
    padding: 1.125rem 0; }
    .section .section-title:before {
      width: 74px; }
  .section .section-left .section-text p {
    padding-right: 2rem; }
  .section .section-text {
    font-size: 1.125rem;
    font-weight: 300;
    line-height: 3rem; }
  .primary-section .section {
    flex-direction: row; }
  .primary-section .section-left,
  .primary-section .section-right {
    width: 100%; }
  .primary-section .section-left {
    max-width: 100%; }
    .primary-section .section-left .section-text {
      margin-bottom: 0; }
  .primary-section .section-right {
    text-align: left; }
    .primary-section .section-right img {
      width: 540px;
      height: 360px; }
  .secondary-section img {
    width: 480px;
    height: 449px; }
    .secondary-section img.primary-img {
      height: 717px; }
  .secondary-section .bottom-line {
    height: 336px; }
  .secondary-section .section-text {
    padding-left: 2rem; }
    .secondary-section .section-text p {
      max-width: 32.25rem; } }

@media (min-width: 1400px) {
  .primary-section .section-left {
    padding-left: 172px; }
  .primary-section .section-right img {
    width: unset;
    height: unset; }
  .secondary-section img {
    width: unset;
    height: unset; }
    .secondary-section img.primary-img {
      height: unset; } }

.input-wrapper {
  position: relative;
  padding-top: 20px; }
  .input-wrapper input {
    display: block;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid;
    border-color: rgba(68, 68, 68, 0.2);
    transition: 0.4s all;
    z-index: 2;
    padding: 6px 0;
    border-radius: 0;
    font-size: 0.75rem; }
    @media (min-width: 1281px) {
      .input-wrapper input {
        font-size: 1rem; } }
  .input-wrapper .input-placeholder {
    position: absolute;
    font-size: 0.75rem;
    top: 26px;
    transition: 0.1s all;
    z-index: -1;
    color: rgba(68, 68, 68, 0.2); }
    @media (min-width: 1281px) {
      .input-wrapper .input-placeholder {
        font-size: 1rem; } }
    .input-wrapper .input-placeholder.placeholder-sm {
      font-size: 0.875rem; }
  .input-wrapper.focused input {
    border-color: #444444; }
  .input-wrapper.focused .input-placeholder {
    top: 0;
    color: #444444; }
    .input-wrapper.focused .input-placeholder.with-value {
      color: rgba(68, 68, 68, 0.2); }
  .input-wrapper:hover input {
    border-color: #444444; }
  .input-wrapper:hover .input-placeholder {
    color: #444444; }
  .input-wrapper.error input,
  .input-wrapper.error textarea {
    border-color: #fa7268; }
  .input-wrapper.error.input-file-wrapper:not(:hover) label {
    border-color: #fa7268; }

.checkbox {
  cursor: pointer; }
  .checkbox .uncheckedBox {
    display: inline-block;
    min-width: 1.125rem;
    max-width: 1.125rem;
    height: 1.125rem;
    border: 1px solid #ced4da; }
  .checkbox .checkedBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.125rem;
    max-width: 1.125rem;
    height: 1.125rem;
    line-height: 1.125rem;
    text-align: center;
    background-color: black;
    border: 1px solid black;
    color: white;
    font-size: 0.5rem; }

.input-file-wrapper {
  cursor: pointer; }
  .input-file-wrapper label {
    height: 40px;
    border: 1px dashed rgba(68, 68, 68, 0.2);
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem 0 1.5rem;
    font-size: 0.75rem; }
    .input-file-wrapper label.with-file, .input-file-wrapper label:hover {
      border: 1px solid #444444; }
    .input-file-wrapper label:hover .placeholder {
      color: #444444; }
    .input-file-wrapper label:hover .upload-icon {
      opacity: 1; }
    @media (min-width: 1281px) {
      .input-file-wrapper label {
        height: 56px;
        font-size: 1rem; } }
    @media (min-width: 1600px) {
      .input-file-wrapper label {
        height: 74px; } }
  .input-file-wrapper input {
    display: none; }
  .input-file-wrapper .placeholder {
    color: rgba(68, 68, 68, 0.2);
    z-index: -1; }
    .input-file-wrapper .placeholder.with-file {
      color: #444444; }
  .input-file-wrapper .upload-icon {
    opacity: 0.4;
    height: 1.125rem;
    width: 1.25rem; }

.textarea-wrapper label {
  margin-bottom: 0.5rem; }

.textarea-wrapper textarea {
  border: 1px solid;
  border-color: rgba(68, 68, 68, 0.2);
  border-radius: 5px;
  display: block;
  width: 100%;
  resize: none;
  transition: 0.4s border-color; }
  .textarea-wrapper textarea::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 1rem;
    color: rgba(68, 68, 68, 0.2); }
  .textarea-wrapper textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 1rem;
    color: rgba(68, 68, 68, 0.2); }
  .textarea-wrapper textarea::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 1rem;
    color: rgba(68, 68, 68, 0.2); }
  .textarea-wrapper textarea:focus {
    border-color: #444444; }

.form-error {
  position: absolute;
  right: 0;
  color: #fa7268;
  font-size: 0.75rem;
  text-align: right; }

.radio-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 1.5rem;
  min-height: 1.125rem;
  min-width: 1.125rem;
  padding-bottom: 0; }

.radio-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

.radio-wrapper.disabled {
  pointer-events: none;
  opacity: 0.7; }

.radio-wrapper .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 1.125rem;
  width: 1.125rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid rgba(68, 68, 68, 0.2); }

.radio-wrapper:hover input ~ .checkmark {
  background-color: rgba(68, 68, 68, 0.2); }

.radio-wrapper input:checked ~ .checkmark {
  background-color: rgba(68, 68, 68, 0.2); }

.radio-wrapper .checkmark:after {
  content: '';
  position: absolute;
  display: none; }

.radio-wrapper input:checked ~ .checkmark:after {
  display: block;
  background-color: rgba(68, 68, 68, 0.2);
  border: 0.125rem solid white; }

.radio-wrapper .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: white; }

.radio-wrapper.label-above {
  font-size: 10px;
  padding-left: 0;
  padding-bottom: 17px; }
  .radio-wrapper.label-above .checkmark {
    left: 0;
    right: 0;
    margin: auto;
    top: initial;
    bottom: 0px;
    transform: unset;
    height: 18px;
    width: 18px; }
  .radio-wrapper.label-above .radio-wrapper input:checked ~ .checkmark:after {
    width: 14px;
    height: 14px;
    border-width: 4px; }

.react-select .react-select__indicator-separator {
  display: none; }

.react-select .react-select__control {
  border-radius: 3px;
  height: 42px;
  box-shadow: initial !important;
  border-color: rgba(68, 68, 68, 0.2) !important; }

.react-select .react-select__control--is-focused {
  border-color: #444444 !important; }

.react-select .react-select__menu {
  z-index: 9999;
  box-shadow: none;
  border: 1px solid #444444;
  border-radius: 3px;
  margin-top: 2px; }
  .react-select .react-select__menu .react-select__option.react-select__option--is-focused, .react-select .react-select__menu .react-select__option.react-select__option--is-selected {
    background-color: #444444;
    color: #fff; }

.tabs-drodown .react-select {
  font-size: 0.75rem; }
  .tabs-drodown .react-select .react-select__control {
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #444444 !important;
    background: transparent !important; }

.daviscup-modal {
  display: flex;
  align-items: center;
  justify-content: center; }
  .daviscup-modal .daviscup-modal-dialog {
    max-width: 85%; }
    @media (min-width: 1400px) {
      .daviscup-modal .daviscup-modal-dialog {
        max-width: 668px; } }
    .daviscup-modal .daviscup-modal-dialog .modal-content {
      border: none;
      box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.6); }
    .daviscup-modal .daviscup-modal-dialog .modal-header {
      padding: 2rem 1.5rem 0 1.5rem;
      position: initial; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .modal-header {
          padding: 2.5rem 2rem 0 2rem; } }
      .daviscup-modal .daviscup-modal-dialog .modal-header .close {
        background: url("../svg/common/x.svg") no-repeat center;
        background-size: cover;
        outline: none;
        width: 18px;
        height: 18px;
        border: none;
        padding: 0;
        cursor: pointer;
        opacity: 1; }
        .daviscup-modal .daviscup-modal-dialog .modal-header .close span {
          display: none; }
    .daviscup-modal .daviscup-modal-dialog .modal-body {
      padding: 0.5rem 1rem 1.5rem 1rem; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .modal-body {
          padding: 0.5rem 2rem 2rem 2rem; } }
    .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--header {
      font-size: 0.75rem;
      margin-bottom: 0.5rem; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--header {
          font-size: 1.5rem; } }
    .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--brand {
      background: url("../svg/common/daviscup.svg") #fe4e02 no-repeat center;
      width: 155px;
      height: 32px;
      margin-left: -24px;
      padding: 5px;
      background-origin: content-box;
      background-size: contain; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--brand {
          width: 185px;
          height: 40px;
          padding: 3px;
          margin-top: -2px;
          margin-left: -40px; } }
    .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content {
      margin-top: 1rem; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content {
          margin-top: 2.5rem; } }
      .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-title {
        font-size: 0.75rem;
        padding-bottom: 0.5rem;
        margin-bottom: 5px;
        border-bottom: 1px solid #fe4e02; }
        @media (min-width: 767px) {
          .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-title {
            font-size: 1.5rem;
            padding-bottom: 1rem; } }
      .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-text {
        position: relative;
        margin-bottom: 5px;
        display: none; }
        .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-text > img {
          width: 100%;
          object-fit: cover;
          height: 240px; }
        @media (min-width: 767px) {
          .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-text {
            display: block; } }
        @media (min-width: 1600px) {
          .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--content .daviscup-modal-inner--content-text > img {
            height: 256px; } }
    .daviscup-modal .daviscup-modal-dialog .ticket-info {
      color: #fe4e02;
      font-size: 1rem;
      line-height: 14px;
      bottom: 10px;
      left: 8px; }
      @media (min-width: 1281px) {
        .daviscup-modal .daviscup-modal-dialog .ticket-info {
          position: absolute;
          font-size: 1.5rem;
          line-height: 29px; } }
    .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .lang-switch a {
      color: #444444; }
    .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .btn-outline-orange {
      color: #fe4e02;
      border-radius: 0;
      border: 1px solid #fe4e02;
      height: 2rem;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      outline: none !important; }
      .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .btn-outline-orange:hover {
        color: #fff;
        background: #fe4e02; }
      @media (min-width: 767px) {
        .daviscup-modal .daviscup-modal-dialog .daviscup-modal-inner--footer .btn-outline-orange {
          height: 3rem;
          padding: 0.5rem 2.5rem;
          font-size: 1rem; } }

.landing-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%; }
  .landing-wrapper > div {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0; }

.not-found-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .not-found-wrapper {
      padding: 2rem !important; }
      .not-found-wrapper .not-found--info {
        background-image: url("../svg/errors/oops_mobile_landscape.svg"); } }
  .not-found-wrapper .not-found--info {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../svg/errors/oops _mobile_portrait.svg");
    width: 300px;
    height: 160px; }
    .not-found-wrapper .not-found--info .not-found--info-text {
      text-align: center;
      position: absolute;
      left: 0;
      width: 100%;
      padding-right: 16px;
      font-size: 1rem;
      bottom: 40px; }
    @media (min-width: 767px) {
      .not-found-wrapper .not-found--info {
        background-image: url("../svg/errors/oops_full.svg");
        width: 650px;
        height: 347px; }
        .not-found-wrapper .not-found--info .not-found--info-text {
          padding-right: 8px;
          font-size: 2rem;
          bottom: 100px; } }
    @media (min-width: 1281px) {
      .not-found-wrapper .not-found--info {
        width: 854px;
        height: 347px; }
        .not-found-wrapper .not-found--info .not-found--info-text {
          padding-right: 12px;
          font-size: 2.75rem;
          bottom: 80px; } }
  .not-found-wrapper .back-home > a {
    font-weight: 500;
    display: inline-flex;
    min-width: 10rem;
    height: 2.5rem;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
    color: #444444;
    border: 1px solid #444444;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .not-found-wrapper .back-home > a:hover {
      background-color: #444444;
      color: #fff; }
    @media (min-width: 767px) {
      .not-found-wrapper .back-home > a {
        min-width: 16rem;
        font-size: 0.875rem;
        height: 3rem; } }
