.not-found-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media only screen and (max-width: 767px) and (orientation: landscape) {
    padding: 2rem !important;

    .not-found--info {
      background-image: url('../svg/errors/oops_mobile_landscape.svg');
    }
  }

  .not-found--info {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;

    background-image: url('../svg/errors/oops _mobile_portrait.svg');
    width: 300px;
    height: 160px;

    .not-found--info-text {
      text-align: center;
      position: absolute;
      left: 0;
      width: 100%;
      padding-right: 16px;
      font-size: $size16;
      bottom: 40px;
    }

    @include media-breakpoint-up(sm) {
      background-image: url('../svg/errors/oops_full.svg');
      width: 650px;
      height: 347px;

      .not-found--info-text {
        padding-right: 8px;
        font-size: $size32;
        bottom: 100px;
      }
    }

    @include media-breakpoint-up(md) {
      width: 854px;
      height: 347px;

      .not-found--info-text {
        padding-right: 12px;
        font-size: $size44;
        bottom: 80px;
      }
    }
  }

  .back-home {
    > a {
      font-weight: 500;
      display: inline-flex;
      min-width: 10rem;
      height: $size40;
      font-size: $size12;
      align-items: center;
      justify-content: center;
      color: $primary;
      border: 1px solid $primary;
      text-decoration: none;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &:hover {
        background-color: $primary;
        color: #fff;
      }

      @include media-breakpoint-up(sm) {
        min-width: 16rem;
        font-size: $size14;
        height: $size48;
      }
    }
  }
}
