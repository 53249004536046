.app {
  opacity: 0.01;

  &.navigation-enter-active,
  &.navigation-enter-done {
    transition: opacity 300ms;
    opacity: 1;
  }
}

.page-bottom-nav {
  position: absolute;
  z-index: 1;
  bottom: 8rem;

  @media only screen and (max-width: 768px) and (orientation: landscape) {
    bottom: 6rem;
  }

  @include media-breakpoint-up(md) {
    bottom: 3.5%;
  }

  a {
    text-decoration: none;
  }

  &:not(.page-bottom-nav--previous) {
    right: 2rem;

    @include media-breakpoint-up(sm) {
      right: 5rem;
    }

    @include media-breakpoint-up(md) {
      right: 19%;
    }
  }

  &.page-bottom-nav--previous {
    left: 2rem;

    @include media-breakpoint-up(sm) {
      left: 5rem;
    }

    @include media-breakpoint-up(md) {
      left: 19%;
    }
  }

  .page-bottom-nav--label {
    font-size: $size14;
    margin-bottom: $size2;

    @include media-breakpoint-up(sm) {
      font-size: $size18;
      margin-bottom: $size8;
    }
  }

  .page-bottom-nav--direction {
    font-size: $size12;

    @include media-breakpoint-up(sm) {
      font-size: $size16;
    }
  }
}
