.steps {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;

  .steps-indicators {
    display: flex;
    min-height: 6px;

    > div {
      position: absolute;
      width: $size40;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 1px;
        background-color: $primary;
        transition: 0.3s all;
      }

      &.active {
        &:after {
          height: 6px;
        }
      }
    }
  }

  .step-main {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}
