// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  height: 100%;
}

body {
  &.menu-open {
    .app-wrapper > .app {
      overflow: hidden;
    }
  }
}

.app-wrapper {
  height: 100vh;
  overflow-y: hidden;

  > .app {
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    position: relative;
    box-sizing: border-box;
  }
}

* {
  outline: 0px solid white;
}

.svg-wrap {
  > span {
    display: inline-block;
    width: inherit;
    height: inherit;

    > svg {
      width: 100%;
      height: 100%;
    }
  }
}
