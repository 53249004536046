// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/* Font Size */
// Creates Classes like font-12, font-md-14, font-lg-16 etc
// Add more sizes in $font-sizes variable
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (font: font) {
      @each $size, $length in $font-sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}-size: $length !important;
        }
      }
    }
  }
}

.max-width-480 {
  max-width: 480px;
}

.max-width-512 {
  max-width: 32rem;
}

.max-height-120 {
  max-height: 7.5rem;
}

.max-height-688 {
  max-height: 688px;
}

.max-width-1400 {
  max-width: 1400px;
  width: 100%;
  margin: auto;
}

.tooltip-inner {
  font-size: 8px;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: $primary;

  & + .arrow:before {
    border-top-color: white;
  }
}

.scrollbar {
  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($primary, 0.15);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.opacity-gradient {
  height: 98px;
  background: linear-gradient(rgba(255, 255, 255, 0.1), white);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.screen-divided-effect {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    background-color: #f5f5f5;
    z-index: -1;
  }
}

.page {
  overflow: hidden;
  padding-top: $size60;
  max-height: 0;
  opacity: 0;
  transition: opacity 800ms ease-in-out 500ms;

  &.is-loaded {
    opacity: 1;
    max-height: initial;

    .page-tabs.active {
      span {
        transform: translate3d(0, 0, 0);
      }
    }

    .cover {
      img.loaded,
      video.loaded {
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .page {
    padding-top: 0;
  }
}

@include media-breakpoint-up(md) {
  .page {
    padding-right: $size80;
  }
  .screen-divided-effect {
    &:before {
      transform: translateX(calc(-50% - 2.5rem));
    }
  }
}

@media only screen and (min-width: 767px) and (orientation: portrait) {
  .page {
    padding-right: 0;
    padding-top: $size112;
  }
}

.pointer {
  cursor: pointer;
}

.form-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-wrapper--inputs {
    @extend .scrollbar;
    flex: 1;
    padding-right: $size16 !important;

    &-no-spacing {
      padding-right: 0 !important;
    }
  }

  .form-wrapper--actions {
    margin-top: $size24;
    margin-bottom: $size24;

    @include media-breakpoint-up(md) {
      margin-bottom: $size64;
    }
  }
}

//modal styling
.modal-open:not(.page-transition) {
  #root {
    filter: blur(4px);
  }
}
.modal-backdrop {
  background: none;
}
.modal-open {
  .modal {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
  }
}
.modal-content {
  border-radius: 0;
}
.modal-header {
  border: none;
  padding: 1rem;
  @extend .justify-content-end;

  @include media-breakpoint-down(lg) {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}

.modal-dialog {
  max-width: 60%;
  @include media-breakpoint-down(lg) {
    max-width: 85%;
  }
}

.font-weight-medium,
.font-weight-500 {
  font-weight: 500;
}
