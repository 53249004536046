.page-navigation {
  display: flex;
  position: relative;
  top: -132px;
  z-index: 2;
  padding-left: $size32;
  flex-direction: column;
  margin-bottom: -$size96;

  &--title {
    font-size: $size40;
    margin-bottom: 40px;
    overflow: hidden;

    > h1 {
      transition: transform 0.8s cubic-bezier(0.6, 0.3, 0.11, 0.86);
      transform: translate3d(-100%, 0, 0);
      font-weight: 700;
      line-height: normal;
      margin: 0;

      @include media-breakpoint-up(md) {
        font-size: $size48;
      }

      @include media-breakpoint-up(xl) {
        font-size: $size56;
      }
    }

    &.active {
      > h1 {
        transform: translate3d(0, 0, 0);
      }
    }

    @include media-breakpoint-up(md) {
      font-size: $size48;
    }

    @include media-breakpoint-up(xl) {
      font-size: $size56;
    }
  }

  .page-navigation--main {
    margin: $size80 0 0 $size20;
  }
}

.page-tabs {
  display: flex;
  flex-direction: column;

  &--button {
    padding-top: $size4;
    padding-bottom: $size4;
    padding-left: $size24;
    padding-right: $size48;
    font-size: $size14;
    transition: 0.3s all;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &.active {
      font-weight: 500;
      &:after {
        width: 2px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      transition: transform 0.15s cubic-bezier(0, 0, 0.08, 0.96);
      transform: rotate(0deg);
      background: $primary;
      height: 100%;
      width: 1px;
      top: 0;
      left: 0;
    }

    span {
      display: inline-block;
      transition: transform 0.4s cubic-bezier(0.6, 0.3, 0.11, 0.86);
      transform: translate3d(-210%, 0, 0);
    }

    &:hover {
      overflow: visible;
      span {
        transform: translate3d(10px, 0, 0) !important;
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .page-tabs {
    flex-direction: row;
  }
  .page-navigation {
    padding-left: $size80;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 768px) {
  @include orientation(portrait) {
    .page-navigation {
      margin-bottom: -$size96 !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-navigation {
    margin-right: 132px;
    flex-direction: row;
    align-items: flex-end;
    padding-left: 0;
    top: -100px;
    .page-navigation--main {
      margin: 0;
      margin-bottom: -$size24;
    }
  }

  .page-tabs {
    &--button {
      font-size: $size16;
      padding-left: $size24;
      padding-right: $size32;
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-navigation {
    top: -132px;

    .page-navigation--main {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .page-tabs {
    &--button {
      font-size: $size18;
      padding-left: $size32;
      padding-right: $size64;
    }
  }
}
