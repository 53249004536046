.radio-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: $size24;
  min-height: $size18;
  min-width: $size18;
  padding-bottom: 0;
}

.radio-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-wrapper.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.radio-wrapper .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: $size18;
  width: $size18;
  background-color: white;
  border-radius: 50%;
  border: 1px solid rgba($primary, 0.2);
}

.radio-wrapper:hover input ~ .checkmark {
  background-color: rgba($primary, 0.2);
}

.radio-wrapper input:checked ~ .checkmark {
  // background-color: $primary;
  background-color: rgba($primary, 0.2);
}

.radio-wrapper .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-wrapper input:checked ~ .checkmark:after {
  display: block;
  // background-color: $primary;
  background-color: rgba($primary, 0.2);
  border: $size2 solid white;
}

.radio-wrapper .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $size16;
  height: $size16;
  border-radius: 50%;
  background: white;
}

.radio-wrapper.label-above {
  font-size: 10px;
  padding-left: 0;
  padding-bottom: 17px;

  .checkmark {
    left: 0;
    right: 0;
    margin: auto;
    top: initial;
    bottom: 0px;
    transform: unset;
    height: 18px;
    width: 18px;
  }

  .radio-wrapper input:checked ~ .checkmark:after {
    width: 14px;
    height: 14px;
    border-width: 4px;
  }
}
