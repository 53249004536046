.input-wrapper {
  position: relative;
  padding-top: 20px;

  input {
    display: block;
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid;
    border-color: rgba($primary, 0.2);
    transition: 0.4s all;
    z-index: 2;
    padding: 6px 0;
    border-radius: 0;
    font-size: $size12;

    @include media-breakpoint-up(md) {
      font-size: $size16;
    }
  }

  .input-placeholder {
    position: absolute;
    font-size: $size12;
    top: 26px;
    transition: 0.1s all;
    z-index: -1;
    color: rgba($primary, 0.2);

    @include media-breakpoint-up(md) {
      font-size: $size16;
    }

    &.placeholder-sm {
      font-size: $size14;
    }
  }

  &.focused {
    input {
      border-color: $primary;
    }

    .input-placeholder {
      top: 0;
      color: $primary;

      &.with-value {
        color: rgba($primary, 0.2);
      }
    }
  }

  &:hover {
    input {
      border-color: $primary;
    }

    .input-placeholder {
      color: $primary;
    }
  }

  &.error {
    input,
    textarea {
      border-color: $error;
    }

    &.input-file-wrapper:not(:hover) label {
      border-color: $error;
    }
  }
}

.checkbox {
  cursor: pointer;

  .uncheckedBox {
    display: inline-block;
    min-width: $size18;
    max-width: $size18;
    height: $size18;
    border: 1px solid $input-border-color;
  }

  .checkedBox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: $size18;
    max-width: $size18;
    height: $size18;
    line-height: $size18;
    text-align: center;
    background-color: black;
    border: 1px solid black;
    color: white;
    font-size: $size8;
  }
}
