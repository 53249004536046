// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.btn {
  line-height: initial;
}

.btn-link {
  text-decoration: solid !important;
}

.btn-scroll-top {
  bottom: $size56;
  height: $size44;
  width: $size44;
  right: $size48;
  z-index: 1;
  cursor: pointer;
}

.btn-with-arrow {
  .svg-wrap {
    transition: transform 0.3s;
  }

  &:hover {
    &:not(.btn-with-arrow--prev) {
      .svg-wrap {
        transform: translate(3px, -3px);
      }
    }

    &.btn-with-arrow--prev {
      .svg-wrap {
        transform: translate(-3px, -3px);
      }
    }
  }
}
