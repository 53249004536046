// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables and Bootstrap overrides.
// -----------------------------------------------------------------------------

/* Colors */
$primary: #444444;
$error: #fa7268;

$daviscup: #fe4e02;

$text-color: $primary;
$blue: #4a90e2;
$theme-colors: ();
$theme-colors: map-merge(
  (
    'primary': $primary
  ),
  $theme-colors
);

/* Spacers */
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.5
    ),
    2: $spacer,
    3: (
      $spacer * 1.5
    ),
    4: (
      $spacer * 2
    ),
    5: (
      $spacer * 2.5
    ),
    6: (
      $spacer * 3
    ),
    7: (
      $spacer * 3.5
    ),
    8: (
      $spacer * 4
    ),
    9: (
      $spacer * 4.5
    ),
    10: (
      $spacer * 5
    ),
    11: (
      $spacer * 5.5
    ),
    12: (
      $spacer * 6
    ),
    13: (
      $spacer * 6.5
    ),
    14: (
      $spacer * 7
    ),
    15: (
      $spacer * 7.5
    ),
    16: (
      $spacer * 8
    ),
    17: (
      $spacer * 8.5
    ),
    18: (
      $spacer * 9
    ),
    19: (
      $spacer * 9.5
    ),
    20: (
      $spacer * 10
    ),
    21: (
      $spacer * 10.5
    ),
    26: (
      $spacer * 13
    ),
    28: (
      $spacer * 14
    )
  ),
  $spacers
);

$grid-gutter-width: 1rem;

/* Sizes */
// Set helper sizes
$size2: 0.125rem; // 4px
$size4: 0.25rem; // 4px
$size6: 0.375rem;
$size8: map-get($spacers, 1); // 8px
$size10: 0.625rem; // 10px
$size12: 0.75rem; // 12px
$size14: 0.875rem; // 14px
$size16: map-get($spacers, 2); // 16px
$size18: 1.125rem; // 18px
$size20: 1.25rem; // 20px
$size22: 1.375rem; // 22px
$size24: map-get($spacers, 3); // 24px
$size26: 1.625rem;
$size27: 1.6875rem; // 27px
$size28: 1.75rem; // 28px2
$size32: map-get($spacers, 4); // 32px
$size34: 2.125rem;
$size36: 2.25rem;
$size40: map-get($spacers, 5); // 40px
$size44: 2.75rem; // 404px
$size48: map-get($spacers, 6); // 48px
$size52: 3.25rem;
$size54: 3.375rem; // 54px
$size56: map-get($spacers, 7); // 56px
$size60: 3.75rem;
$size64: map-get($spacers, 8); // 64px
$size72: map-get($spacers, 9); // 72px
$size77: 4.8125rem;
$size80: map-get($spacers, 10); // 80px
$size88: map-get($spacers, 11); // 88px
$size96: map-get($spacers, 12); // 96px
$size104: map-get($spacers, 13); // 104px
$size112: map-get($spacers, 14); // 112px
$size116: 7.25rem; //116px
$size120: map-get($spacers, 15); // 120px
$size128: map-get($spacers, 16); // 128px
$size136: map-get($spacers, 17); // 136px
$size144: map-get($spacers, 18); // 144px
$size152: map-get($spacers, 19); // 152px
$size160: map-get($spacers, 20); // 160px
$size168: map-get($spacers, 21); // 168px
$size208: map-get($spacers, 26); // 208px
$size224: map-get($spacers, 28); // 224px

$font-sizes: (
  10: $size10,
  12: $size12,
  14: $size14,
  16: $size16,
  18: $size18,
  20: $size20,
  22: $size22,
  24: $size28,
  28: $size24,
  32: $size32
);

$font-family-base: 'Gotham SSm A', 'Gotham SSm B';
$font-weight-base: 300;
$font-size-base: $size16;

$grid-breakpoints: (
  xs: 0,
  xxs: 576px,
  sm: 767px,
  ssm: 992px,
  md: 1281px,
  lg: 1400px,
  xl: 1600px
);

$delays: (
  1: 0.4s,
  2: 0.6s,
  3: 0.8s,
  4: 1.1s,
  5: 1.3s,
  6: 1.5s
);

$gradient-pattern-image: linear-gradient(
  135deg,
  #dadada 8.33%,
  transparent 8.33%,
  transparent 50%,
  #dadada 50%,
  #dadada 58.33%,
  transparent 58.33%,
  transparent 100%
);
$gradient-pattern-size: 8.49px 8.49px;

$gradient-pattern-image-dark: linear-gradient(
  135deg,
  #444444 8.33%,
  transparent 8.33%,
  transparent 50%,
  #444444 50%,
  #444444 58.33%,
  transparent 58.33%,
  transparent 100%
);

$gradient-pattern-landing-mobile: linear-gradient(
  135deg,
  #444444 12.5%,
  transparent 12.5%,
  transparent 50%,
  #444444 50%,
  #444444 62.5%,
  transparent 62.5%,
  transparent 100%
);
$gradient-pattern-image-light: linear-gradient(
  128deg,
  rgba($primary, 0.3%) 10%,
  transparent 10%,
  transparent 50%,
  rgba($primary, 0.3%) 50%,
  rgba($primary, 0.3%) 60%,
  transparent 60%,
  transparent 100%
);
