.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  width: $size80;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: $size32 0;
  z-index: 30;
  display: none;

  .logo {
    height: $size80;
    width: 65px;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    width: $size120;

    .login {
      font-size: $size16 !important;
    }
  }

  @include media-breakpoint-up(lg) {
    width: $size160;

    .logo {
      height: 100px;
      width: 98px;
    }
  }
}

.sidebar-mobile {
  position: fixed;
  top: 0;
  background-color: $white;
  width: 100%;
  z-index: 30;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $size12;
  padding-bottom: $size12;
  padding-left: $size16;
  padding-right: $size32;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding-top: $size16;
    padding-bottom: $size16;
  }

  .logo > a {
    display: inline-flex;
  }

  .burger {
    transform: rotate(0deg);
  }

  .logo-mobile-icon {
    height: $size32;
    width: $size32;

    @include media-breakpoint-up(sm) {
      height: $size40;
      width: $size40;
    }
  }

  .logo-mobile-text {
    margin-top: 2px;
    display: none !important;

    @include media-breakpoint-up(sm) {
      display: inline-flex !important;
    }

    > span {
      display: inline-flex;
      align-items: center;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-left: $size40;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;

  transform: translate3d(100%, 0, 0);
  -webkit-transition: transform 0.6s ease;
  -webkit-transition: -webkit-transform 0.6s ease;
  transition: all 0.6s ease;
  will-change: transform;

  background: rgba(255, 255, 255, 0.95);
  z-index: 20;
  display: flex;
  flex-direction: column;
  padding-top: $size64;
  padding-right: $size16;
  padding-left: $size16;
  padding-bottom: 3vh;

  overflow: hidden;

  @include media-breakpoint-up(sm) {
    padding-right: $size8;
    padding-bottom: $size32;
    flex-direction: column;
    background: #ffffff;
    padding-top: 6rem;
    padding-bottom: $size32;
    padding-left: $size40;
  }

  @include media-breakpoint-up(ssm) {
    min-width: unset;
    max-width: unset;
    width: 60%;
    min-width: 512px;
  }

  @include media-breakpoint-up(md) {
    min-width: 920px;
    width: 40%;
    padding-top: 2rem;
    padding-left: $size64;
  }

  a {
    text-decoration: none !important;
  }

  /**menu animation**/
  .menu-slide-container {
    > div {
      flex: 0 0 100%;

      .overflow-hidden {
        height: 100%;
      }
    }

    .menu-content {
      height: 100%;

      > div {
        flex: 0 0 100%;
      }
    }
  }

  &--mobile {
    padding: $size32 $size26 0 !important;
  }

  &.open {
    transform: translate3d(0%, 0, 0);

    .first-level-title,
    .action {
      opacity: 1;
      transform: translate3d(0%, 0, 0);
    }
  }

  .menu-slide-container {
    .slide {
      transform: translate3d(0%, 0, 0);
      transition: transform 0.65s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: transform;

      overflow: hidden;
    }
  }

  .menu-content {
    height: 100%;

    > div {
      flex: 0 0 100%;
      transition: transform 0.65s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &.tournament {
      .fact-sheet {
        ol > li {
          margin-bottom: $size14;
          list-style-type: lower-greek;

          font-size: $size12;

          @include media-breakpoint-up(md) {
            font-size: $size16;
          }
        }

        dl,
        dt {
          font-weight: 300;
        }
        dt {
          font-size: $size12;

          @include media-breakpoint-up(md) {
            font-size: $size16;
          }

          &:first-child {
            margin-top: $size14;
          }

          margin-bottom: $size14;
        }
      }

      .participants {
        ol > li {
          margin-top: $size16;
          font-size: $size12;

          @include media-breakpoint-up(md) {
            font-size: $size16;
          }
        }
      }

      .draws {
        font-size: $size12;

        @include media-breakpoint-up(md) {
          font-size: $size16;
        }

        .pdf-link-container {
          margin-bottom: $size16;
        }

        a {
          text-decoration: none !important;
        }

        .pdf-icon {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          width: $size26;
          height: $size26;
          border-radius: $size4;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin-bottom: $size8;
          }
        }
      }
    }
  }

  &.is-flipped {
    .menu-slide-container {
      .slide {
        will-change: transform;
        transform: translate3d(-100%, 0, 0);
      }
    }
    .menu-content {
      &.join_us {
        > div {
          transform: translate3d(-100%, 0, 0);
        }
      }

      &.part_of_tact {
        > div {
          transform: translate3d(-100%, 0, 0);
        }
      }

      &.career {
        > div {
          transform: translate3d(-200%, 0, 0);
        }
      }

      &.legal,
      &[class*='legal_'] {
        > div {
          transform: translate3d(-300%, 0, 0);
        }
      }

      &.tournament {
        > div {
          transform: translate3d(-400%, 0, 0);
        }
      }
    }

    .first-level-title {
      transform: translate(200px, 0);

      &:after {
        transform: translate(200px, 0);
      }
    }
  }

  .react-card-front {
    opacity: 1;
  }

  .react-card-back {
    opacity: 0;
  }

  .react-card-front,
  .react-card-back {
    transition: transform 0.6s ease 0s, opacity 0.3s ease !important;
  }

  &.is-flipped {
    .react-card-front {
      opacity: 0;
    }

    .react-card-back {
      opacity: 1;
    }
  }

  .legal,
  [class*='legal_'] {
    font-size: $size12;
  }
}

.menu-shadow {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: red;
  background-color: rgba(255, 255, 255, 0);

  transform: translate3d(100%, 0, 0);

  -webkit-transition: transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: all 0.4s ease;

  will-change: transform;

  z-index: 10;

  &.open {
    transform: translate3d(0%, 0, 0);
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.menu-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  .first-level {
    width: 220px;
    position: relative;
    background: transparent;

    @each $element, $size in $delays {
      &:nth-child(#{$element}) {
        .first-level-title {
          transition: all $size;

          &:after {
            transition: all $size;
          }
        }
      }
    }

    &:hover {
      .second-level {
        transition-delay: 0.1s, 0.3s;
        transform: translate3d(100%, 0, 0);
        opacity: 1;
        z-index: 1;
      }
    }
  }

  .first-level-title {
    font-size: 2.4vmax;
    font-weight: 700;
    position: relative;
    z-index: 2;
    opacity: 0;
    transform: translate(200px, 0);
    cursor: pointer;
    padding-bottom: $size24;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include media-breakpoint-up(sm) {
      padding-bottom: $size32;
      font-size: $size16;
    }

    .arrow {
      content: '';
      display: inline-block;
      background-image: url('../svg/common/right-arrow.svg');
      width: $size18;
      height: $size14;
      margin-left: $size8;
      transition: all 0.5s;
      transform: translate3d(0, 0, 0);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .second-level {
    position: absolute;
    width: 240px;
    right: 0;
    top: 0px;
    opacity: 0;
    transition: transform 0.5s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.25s,
      opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
    transition-property: transform opacity;
  }

  .second-level-title {
    font-size: $size12;
    margin-bottom: $size12;
    position: relative;
    cursor: pointer;
    padding-left: $size8;

    &:hover {
      font-weight: bold;
    }

    @include media-breakpoint-up(sm) {
      font-size: $size16;
    }

    @include media-breakpoint-up(md) {
      font-size: $size18;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: $size32;
  }

  @include media-breakpoint-up(md) {
    .first-level-title {
      font-size: $size24;

      .arrow {
        width: $size26;
        height: 21px;
        margin-right: $size16;
      }
    }

    .first-level {
      width: 340px;
    }
  }

  @include mobile-landscape() {
    justify-content: space-evenly;
    padding-bottom: 1rem;
    padding-top: 0;

    .first-level {
      .first-level-title {
        padding-bottom: 0;
      }
    }
  }
}

.menu-bottom {
  display: flex;
  align-items: flex-start;
  z-index: 10;
  position: relative;
}

.lang {
  display: flex;
  align-items: center;
  margin-right: $size48;

  div {
    cursor: pointer;
    transition: all 0.4s;
  }
}

.menu-actions {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: initial;
    justify-content: flex-start;
  }

  .action {
    position: relative;
    opacity: 0;
    transform: translate(200px, $size20);
    flex: 1;
    margin-bottom: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(sm) {
      padding-right: $size24;
      margin-right: $size24;
      flex: auto;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    @each $element, $size in $delays {
      &:nth-child(#{$element}) {
        transition: all $size;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 60%;
      right: 35%;
      transform: translateY(-50%);
      width: $size4;
      height: $size4;
      border-radius: 100%;
      background-color: $primary;

      @include media-breakpoint-up(sm) {
        right: 0;
      }
    }
  }

  .svg-wrap {
    min-width: $size18;
    max-width: $size18;
    max-height: $size18;

    @include media-breakpoint-up(sm) {
      min-width: $size24;
      max-width: $size24;
      max-height: $size24;
    }
  }
}

.menu-panel-title {
  font-size: $size12;
  font-weight: 700;
  position: relative;
  padding-bottom: $size16;
  margin-bottom: $size24;

  @include media-breakpoint-up(md) {
    font-size: $size14;
    padding-bottom: $size32;
  }

  @include mobile-landscape() {
    margin-bottom: $size12;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: $size24;
    height: 0.1875rem;
    background-color: $primary;
  }
}

.menu-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .max-width-480 {
    max-width: 95%;

    @include media-breakpoint-up(md) {
      max-width: 78%;
    }

    @include media-breakpoint-up(xl) {
      max-width: 480px;
    }
  }

  .input-placeholder,
  .radio-wrapper,
  .radio-label {
    font-size: $size12;

    @include media-breakpoint-up(md) {
      font-size: $size16;
    }
  }

  .radio-wrapper .checkmark {
    transform: scale(0.8);
    top: -1px;

    @include media-breakpoint-up(md) {
      transform: none;
      top: 2px;
    }
  }

  p {
    line-height: 20px;
    font-size: $size12;
    font-weight: 300;

    @include media-breakpoint-up(md) {
      line-height: 32px;
      font-size: $size16;
    }
  }

  ul {
    margin: 0 0 $size16 0 !important;
    font-size: $size12;
    line-height: 20px;
    padding-left: $size44;

    @include media-breakpoint-up(md) {
      font-size: $size16;
      line-height: 29px;
    }
  }

  input {
    background: transparent;
  }

  .success-message {
    max-width: 15.5rem;
    margin-top: $size208;
  }

  .scrollbar {
    padding-right: $size8;
  }

  .about-us-wrapper {
    max-width: 95%;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      max-width: 78%;
    }

    .about-us-scrollable {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
      box-sizing: content-box; /* So the width will be 100% + 17px */
    }
  }

  &--join-us {
    .form-wrapper {
      width: 100% !important;
    }

    .form-error {
      font-size: $size10;
      position: relative;
    }

    .form-wrapper--actions {
      height: $size20;

      .btn {
        font-size: $size12;

        @include media-breakpoint-up(md) {
          font-size: $size16;
        }
      }
    }

    .terms-accepted-error {
      display: none;
      right: 3%;
      padding-top: $size6;
    }

    .checkbox-container {
      padding-top: $size6;
      align-items: center;

      .checkbox {
        .uncheckedBox {
          min-width: 0.94rem;
          height: 0.94rem;
        }

        .checkedBox {
          line-height: 0;
          min-width: 0.94rem !important;
          height: 0.94rem !important;
        }
      }
    }
  }

  &.part-of-tact {
    .menu-panel {
      &-link {
        a {
          color: $blue !important;
          font-weight: 700;
        }
      }

      &-subtitle {
        margin-top: $size40;
      }

      &-measure-impact,
      &-philosophy {
        margin-top: $size18;

        p {
          letter-spacing: 0;
          margin: 0 !important;
        }
      }
    }
  }

  &.about,
  &.part-of-tact {
    .about-us {
      width: auto;
      padding-top: $size10;
      padding-right: $size32;
      padding-bottom: $size32;
      padding-left: $size32;
      position: relative;
      white-space: nowrap;

      @include media-breakpoint-up(md) {
        padding-top: $size24;
        padding-right: $size48;
        padding-bottom: $size72;
        padding-left: $size72;
      }

      &::after,
      &::before {
        content: '';
        display: inline-block;
        background-image: url('../svg/menu/about_corner.svg');
        width: $size26;
        height: $size26;
        position: absolute;
      }

      &::after {
        left: 0px;
        transform: rotate(180deg);
        bottom: 0;
      }

      &::before {
        right: 0;
        top: 0;
      }

      > p:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.cookies-policy {
  left: 0;
  bottom: 0px;
  height: $size64;
  font-size: $size10;
  background: $white;
  z-index: 31;
  opacity: 1;
  transition: opacity 1000ms ease 0s, visibility 1000ms ease 0s;

  .btn {
    box-shadow: none !important;
    font-size: $size10;
  }

  .btn-outline-primary {
    border-radius: 0px;

    &:hover {
      background: $white;
      color: $primary;
    }
  }

  &.accepted {
    opacity: 0;
    visibility: hidden;
  }
}

@include media-breakpoint-up(sm) {
  .menu {
    .legal,
    [class*='legal_'] {
      font-size: $size16;
    }
  }

  .btn-login {
    display: none;
  }

  .first-level-title {
    background: #ffffff;
  }

  .menu-bottom {
    height: auto;
    align-items: center;
    // padding-left: $size16;
  }

  .cookies-policy {
    font-size: $size12;

    .btn {
      font-size: $size12;
      line-height: $size12;
    }
  }
}

.btn-login {
  right: $size16;
  bottom: $size24;
  cursor: pointer;
  z-index: 1;

  .svg-wrap {
    height: $size32;
    width: $size44;
  }
}

@include media-breakpoint-up(md) {
  .cookies-policy {
    font-size: $size14 !important;

    .btn {
      font-size: $size14 !important;

      &.btn-accept {
        line-height: $size14;
      }
    }
  }
}
