.cover {
  position: relative;
  z-index: 1;
  height: 315px;

  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    opacity: 0;
    transition: opacity 1000ms ease 0s;
  }

  video {
    transition: opacity 1000ms ease 0s, visibility 1000ms ease 0s;
    opacity: 0;
    object-fit: cover;
    width: 100%;
  }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
  .cover {
    height: 600px;
  }
}

@include media-breakpoint-up(sm) {
  .cover {
    height: 462px;
  }
}

@include media-breakpoint-up(md) {
  .cover {
    height: auto;
    video {
      width: calc(100% - 2.5rem);
    }
  }
}

@include media-breakpoint-up(lg) {
  .cover {
    video {
      width: calc(100% - 5rem);
    }
  }
}
