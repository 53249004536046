/* 
 * base styling for primary-section
 * and secondary-section
*/
.section {
  display: flex;

  .section-left,
  .section-right {
    width: 50%;
    padding: 0 $size24;
    transition: 0.7s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.5s;

    img {
      transition: transform 0.5s;
      transition-timing-function: ease-out;
      object-fit: cover;
    }
  }

  .section-title h3 {
    font-size: $size18;
    font-weight: bold;
  }

  .section-text {
    font-size: $size14;
    font-weight: 300;
    line-height: $size32;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.animate-sections {
  .section-title h3 {
    display: inline;
    font-size: $size24;
    font-weight: 700;
    line-height: normal;
    margin: 0;

    @media (max-width: 1024px) {
      font-size: $size18;
    }
  }

  .section-text p {
    transition: transform 0.55s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.25s;
    transform: translate3d(-110%, 0, 0);
  }

  .section-left {
    clip-path: inset(0 0 0 100%);
    .section-title h3,
    .section-text p {
      transform: translate3d(110%, 0, 0);
    }
  }
  .section-right {
    clip-path: inset(0 100% 0 0);
  }

  &.animate {
    .section-left,
    .section-right {
      clip-path: inset(0 0 0 0);
    }
    .section-title h3,
    .section-text p {
      transition-delay: 0.7s;
      transform: translate3d(0, 0, 0);
    }
  }

  &.animated {
    .section-left,
    .section-right {
      clip-path: none;
    }
  }
}

.primary-section {
  align-items: center;
  position: relative;
  margin-bottom: $size64;
  clear: both;

  .section {
    flex-direction: column;
    padding: 0 $size104;
  }

  &:before {
    content: '';
    position: absolute;
    top: $size24;
    bottom: $size24;
    left: 0;
    right: 0;
    background-image: $gradient-pattern-image;
    background-size: $gradient-pattern-size;
  }

  .left-text {
    margin-top: $size40;
    font-size: $size40;
    font-weight: bold;
    opacity: 0.05;
    left: $size48;
    top: 200vh;
    position: fixed;
    display: none;

    transform: rotate(-90deg);
    transform-origin: left top 0;
    float: left;
    text-align: left;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
      margin-top: $size24;
      font-size: $size56;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 0;
      font-size: $size88;
    }
  }

  .section {
    align-items: center;
    z-index: 1;
    position: relative;
  }

  .section-left,
  .section-right {
    width: 100%;
  }
  .section-left {
    padding-left: 120px;
    max-width: 30rem;
    .section-text {
      margin-bottom: $size56;
    }
  }
  .section-right {
    text-align: center;
  }

  .section-title {
    padding-bottom: $size40;
    margin-bottom: $size40;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: $size4;
      width: $size24;
      background-color: $primary;
    }
  }

  .section-title {
    margin-bottom: $size40;
  }

  img {
    width: 458px;
    height: 327px;
  }
}

.mobile-section {
  padding: 0 $size48;
  justify-content: center;
  align-items: center;
  &::after {
    display: none;
  }
  display: flex;
}

.tablet-section {
  display: none;
}

.secondary-section {
  .section {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 65px;
      height: 400px;
      left: 0px;
      width: 2000px;
      background-image: $gradient-pattern-image;
      background-size: $gradient-pattern-size;
      z-index: -1;
    }
  }

  .section-left {
    text-align: right;

    .section-title {
      span,
      h3 {
        margin-left: initial;
        margin-right: $size32;
        text-align: right;
      }

      .title-container {
        &:before {
          left: initial;
          right: 0;
        }
      }
    }
  }

  .section-title {
    padding: $size12 0px;
    margin-bottom: $size56;
    margin-top: $size24;

    .title-container {
      margin-left: $size32;
      display: inline-flex;
      align-items: center;
      position: relative;
      min-height: 74px;
      line-height: 27px;

      &:before {
        content: '';
        display: inline-block;
        width: 74px;
        // height: 74px;
        height: 100%;
        border: 6px solid rgba($primary, 0.2);
        background-color: white;
        position: absolute;
        top: 0;
        left: -32px;
        z-index: -1;
      }
    }

    span,
    h3 {
      background-color: white;
      padding: 6px 0;
      display: inline-block;
      margin-block-end: 0;
    }
  }

  .section-text {
    margin-bottom: $size40;
    p {
      max-width: 21.25rem;
    }
  }

  .align-right {
    text-align: right;
  }

  .mobile-section {
    .section-text {
      p {
        max-width: 25rem;
      }
    }
  }

  .bottom-line {
    height: 175px;
    position: relative;
    transition: 0.7s cubic-bezier(0.6, 0.3, 0.11, 0.86) 0.5s;
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: calc(50% - 1.75rem);
      height: $size56;
      width: 1px;
      background-color: $primary;
    }
  }

  .small-image {
    display: flex;
    align-items: flex-end;
  }

  &.primary-right {
    .small-image {
      justify-content: flex-end;
      .section-text {
        justify-content: flex-end;
        display: flex;
      }
    }
  }

  img {
    width: 300px;
    height: 280px;
    &.primary-img {
      height: 449px;
    }
  }
}

.sections-header {
  font-size: $size56;
  text-align: center;
  padding: 88px 0;
  position: relative;
  z-index: 1;
  font-weight: bold;
  margin-bottom: $size48;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: $size56;
    width: 1px;
    background-color: $primary;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  .sections-header--animated {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    > div {
      display: inline-block;
      overflow: hidden;
      position: relative;
      // flex: 1;

      transition: opacity 2.9s cubic-bezier(0.23, 1, 0.32, 1);
      opacity: 0;
      transform: translateY(100%);

      animation: slide-up 2.9s cubic-bezier(0.23, 1, 0.32, 1) forwards;

      &.sections-header--animated-first {
        text-align: right;
        > h2 {
          display: inline;
          font-size: $size56;
          font-weight: 700;
          line-height: normal;
          margin: 0;

          @media (max-width: 800px) {
            font-size: $size32;
          }
        }
      }

      &.sections-header--animated-rest {
        padding-left: $size8;
        text-align: left;

        > h2 {
          display: inline;
          font-size: $size56;
          font-weight: 700;
          line-height: normal;
          margin: 0;

          @media (max-width: 800px) {
            font-size: $size32;
          }
        }
      }
    }
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 1280px) {
  .primary-section {
    padding-top: $size48;

    &:before {
      top: 0;
    }

    .section-left {
      padding: 0 $size24;
    }
  }

  .secondary-section {
    .section-left,
    .section-right {
      margin-bottom: $size40;
    }
  }
}

@media (max-width: 800px) {
  .section {
    .section-title {
      font-size: $size18;

      .title-container {
        min-height: 88px;

        &:before {
          width: 54px;
        }

        h3 {
          font-size: $size18;
          font-weight: 700;
          line-height: $size27;
          margin-block-end: 0;
        }
      }
    }

    .section-text {
      font-size: $size14;
      margin-bottom: $size48;
    }
  }

  .sections-header h2 {
    font-size: $size32;
  }

  .page-navigation {
    display: block;
    .page-navigation--main {
      margin-left: $size40;
      margin-top: 60px;
    }

    .page-tabs--button {
      margin-bottom: $size16;
    }
  }

  .page-tabs {
    direction: block;
  }
}

/**Mobile Portait**/

@media only screen and (max-width: 767px) {
  @include orientation(portrait) {
    .primary-section {
      .section {
        padding: 0px;
      }
      img {
        width: 300px;
        height: 215px;
      }

      .section-left {
        padding: 0;
        width: 300px;
      }
    }

    .section {
      flex-direction: column;

      .small-image {
        justify-content: center !important;
      }
    }

    .secondary-section {
      .section-text {
        padding-left: 0px;
      }
      .section-left,
      .section-right {
        width: 100%;
        text-align: center;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  @include orientation(portrait) {
    // .sidebar {
    //   display: none !important;
    // }
    // .sidebar-mobile {
    //   display: flex;
    // }
    .cover {
      height: 800px;
    }

    .mobile-section {
      display: flex !important;
    }

    .tablet-section {
      display: none !important;
    }

    .secondary-section {
      .small-image {
        display: flex !important;
      }
    }
    .btn-scroll-top {
      bottom: $size54 !important;
      right: $size116 !important;
    }

    .section-text {
      padding-left: 0 !important;
    }
  }
}

@include media-breakpoint-up(sm) {
  // .sidebar {
  //   display: flex;
  // }
  .btn-scroll-top {
    right: 15rem;
    bottom: $size112;
  }
  .primary-section {
    margin-bottom: $size128;
    .section {
      padding: 0;
    }
    .left-text {
      display: block;
    }
  }

  .mobile-section {
    display: none;
  }

  .tablet-section {
    display: block;
    overflow: hidden;
  }

  .secondary-section {
    .small-image {
      display: block;
    }
  }
}

@include media-breakpoint-up(md) {
  .section {
    .section-title {
      font-size: $size24;
      padding: $size18 0;
      &:before {
        width: 74px;
      }
    }

    .section-left {
      .section-text {
        p {
          padding-right: $size32;
        }
      }
    }

    .section-text {
      font-size: $size18;
      font-weight: 300;
      line-height: $size48;
    }
  }
  .primary-section {
    .section {
      flex-direction: row;
    }
    .section-left,
    .section-right {
      width: 100%;
    }

    .section-left {
      max-width: 100%;
      .section-text {
        margin-bottom: 0;
      }
    }
    .section-right {
      text-align: left;
      img {
        width: 540px;
        height: 360px;
      }
    }
  }

  .secondary-section {
    img {
      width: 480px;
      height: 449px;
      &.primary-img {
        height: 717px;
      }
    }
    .bottom-line {
      height: 336px;
    }

    .section-text {
      p {
        max-width: 32.25rem;
      }
      padding-left: $size32;
    }
  }
}

@include media-breakpoint-up(lg) {
  .primary-section {
    .section-left {
      padding-left: 172px;
    }
    .section-right {
      img {
        width: unset;
        height: unset;
      }
    }
  }

  .secondary-section {
    img {
      width: unset;
      height: unset;
      &.primary-img {
        height: unset;
      }
    }
  }
}
