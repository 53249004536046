/**
 * Basic typography style for copy text
 */
body {
  line-height: normal;
  color: $primary;
}

.reset-list,
.reset-list li {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style: none;
}

// -----------------------
// Font sizes
// -----------------------

.font-14 {
  font-size: $size14;
}

.font-36 {
  font-size: $size36;
}

.font-22 {
  font-size: $size22;
}

// -----------------------
// Text colors
// -----------------------

.text-primary-fade {
  color: rgba($primary, 0.4);
}

.text-primary-fade {
  color: rgba($primary, 0.4);
}
.text-blue {
  color: $blue;
}

.action-primary {
  color: rgba($primary, 0.4);
  transition: 0.3s all;

  &:hover {
    color: $primary;
  }
}

// -----------------------
// Text utils
// -----------------------
.cursor-pointer {
  cursor: pointer;
}

// -----------------------
// Font weights
// -----------------------
// .font-weight-bold .font-weight-normal .font-weight-light

// -----------------------
// Line height
// -----------------------
.line-height-42 {
  line-height: 43px;
}
.line-height-16 {
  line-height: 16px !important;
}
