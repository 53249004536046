.react-select {
  .react-select__indicator-separator {
    display: none;
  }

  .react-select__control {
    border-radius: 3px;
    height: 42px;
    box-shadow: initial !important;
    border-color: rgba($primary, 0.2) !important;
  }

  .react-select__control--is-focused {
    border-color: $primary !important;
  }

  .react-select__menu {
    z-index: 9999;
    box-shadow: none;
    border: 1px solid $primary;
    border-radius: 3px;
    margin-top: 2px;

    .react-select__option {
      &.react-select__option--is-focused,
      &.react-select__option--is-selected {
        background-color: $primary;
        color: #fff;
      }
    }
  }
}

.tabs-drodown {
  .react-select {
    font-size: $size12;

    .react-select__control {
      border-radius: 0px;
      border: none;
      border-bottom: 1px solid $primary !important;
      background: transparent !important;
    }
  }
}
