.daviscup-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .daviscup-modal-dialog {
    max-width: 85%;

    @include media-breakpoint-up(lg) {
      max-width: 668px;
    }

    .modal-content {
      border: none;
      box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.6);
    }

    .modal-header {
      padding: $size32 $size24 0 $size24;
      position: initial;

      @include media-breakpoint-up(sm) {
        padding: $size40 $size32 0 $size32;
      }

      .close {
        background: url('../svg/common/x.svg') no-repeat center;
        background-size: cover;
        outline: none;
        width: 18px;
        height: 18px;
        border: none;
        padding: 0;
        cursor: pointer;
        opacity: 1;

        span {
          display: none;
        }
      }
    }

    .modal-body {
      padding: $size8 $size16 $size24 $size16;

      @include media-breakpoint-up(sm) {
        padding: $size8 $size32 $size32 $size32;
      }
    }

    .daviscup-modal-inner--header {
      font-size: $size12;
      margin-bottom: $size8;

      @include media-breakpoint-up(sm) {
        font-size: $size24;
      }
    }

    .daviscup-modal-inner--brand {
      background: url('../svg/common/daviscup.svg') #fe4e02 no-repeat center;
      width: 155px;
      height: 32px;
      margin-left: -24px;
      padding: 5px;
      background-origin: content-box;
      background-size: contain;

      @include media-breakpoint-up(sm) {
        width: 185px;
        height: 40px;
        padding: 3px;
        margin-top: -2px;
        margin-left: -40px;
      }
    }

    .daviscup-modal-inner--content {
      margin-top: $size16;

      @include media-breakpoint-up(sm) {
        margin-top: $size40;
      }

      .daviscup-modal-inner--content-title {
        font-size: $size12;
        padding-bottom: $size8;
        margin-bottom: 5px;
        border-bottom: 1px solid $daviscup;

        @include media-breakpoint-up(sm) {
          font-size: $size24;
          padding-bottom: $size16;
        }
      }

      .daviscup-modal-inner--content-text {
        position: relative;
        margin-bottom: 5px;
        display: none;

        > img {
          width: 100%;
          object-fit: cover;
          height: 240px;
        }

        @include media-breakpoint-up(sm) {
          display: block;
        }

        @include media-breakpoint-up(xl) {
          > img {
            height: 256px;
          }
        }
      }
    }

    .ticket-info {
      color: $daviscup;
      font-size: $size16;
      line-height: 14px;
      bottom: 10px;
      left: 8px;

      @include media-breakpoint-up(md) {
        position: absolute;
        font-size: $size24;
        line-height: 29px;
      }
    }

    .daviscup-modal-inner--footer {
      .lang-switch {
        a {
          @extend .gotham-400;
          color: $text-color;

          &.active {
            @extend .gotham-700;
          }
        }
      }

      .btn-outline-orange {
        color: $daviscup;
        border-radius: 0;
        border: 1px solid $daviscup;
        height: $size32;
        padding: $size8 $size16;
        font-size: $size12;
        outline: none !important;

        &:hover {
          color: #fff;
          background: $daviscup;
        }

        @include media-breakpoint-up(sm) {
          height: $size48;
          padding: $size8 $size40;
          font-size: $size16;
        }
      }
    }
  }
}
