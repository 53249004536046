.input-file-wrapper {
  cursor: pointer;

  label {
    height: 40px;
    border: 1px dashed rgba($primary, 0.2);
    width: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $size32 0 $size24;
    font-size: $size12;

    &.with-file,
    &:hover {
      border: 1px solid $primary;
    }

    &:hover .placeholder {
      color: $primary;
    }

    &:hover .upload-icon {
      opacity: 1;
    }

    @include media-breakpoint-up(md) {
      height: 56px;
      font-size: $size16;
    }

    @include media-breakpoint-up(xl) {
      height: 74px;
    }
  }

  input {
    display: none;
  }

  .placeholder {
    color: rgba($primary, 0.2);
    z-index: -1;

    &.with-file {
      color: $primary;
    }
  }

  .upload-icon {
    opacity: 0.4;
    height: $size18;
    width: $size20;
  }
}
