.burger {
  transform: rotate(90deg) translate(-50%, 0);
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  width: 35px;
  height: $size20;

  @include media-breakpoint-up(md) {
    width: 53px !important;

    span {
      width: 47px !important;
    }
  }

  span {
    display: block;
    background-color: #000;
    width: 40px;
    height: 0.15em;
    border-radius: 1px;
    position: absolute;

    -webkit-transition: transform 0.3s ease-in-out;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:nth-child(2) {
      top: 8px;
      left: 0;
    }

    &:last-child {
      top: 16px;
      left: 0;
    }
  }

  &.open {
    span {
      &:first-child {
        transform: rotate(-45deg);
        top: 8px;
      }
      &:nth-child(2) {
        background-color: #fff !important;
      }
      &:last-child {
        transform: rotate(45deg);
        top: 8px;
      }
    }
  }
}
