.section-left {
  .secondary-slider {
    .slick-dots {
      display: none !important;
    }
  }
}

.secondary-slider {
  transition: all 300ms ease-out;
  width: 100%;

  .slick-list,
  .slick-track,
  .slick-slide,
  .slide-inner {
    transition: all 300ms ease-out;
  }

  .slick-arrow {
    display: none !important;
  }
  img {
    width: 552px !important;
  }

  .slick-dots {
    position: absolute;
    left: 40%;
    bottom: -$size54;
    transform: rotate(180deg);
    padding: 0;
    button {
      color: transparent;
      border-radius: 0;
      border: none;
      margin: 0 $size8;
      padding: 0;
      height: 1px;
      overflow: hidden;
      width: $size40;
      outline: none;
      box-shadow: none;
      transition: 0.3s height;
      background: $primary;
    }

    li {
      display: inline;
      list-style: none;
      width: $size8;
      &.slick-active,
      &:hover {
        button {
          height: $size10;
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .secondary-slider {
    img {
      width: 480px !important;
    }
  }
}

@media (max-width: 1280px) {
  .section-left {
    .slick-slider {
      float: right;
    }
  }

  .secondary-slider {
    width: 300px !important;

    .slick-dots {
      left: 30%;
    }

    img {
      width: 300px !important;
    }
  }
}

@media (max-width: 767px) {
  .section-left {
    .secondary-slider {
      .slick-dots {
        display: block !important;
      }
    }
  }

  .section-right {
    .secondary-slider {
      .slick-dots {
        display: none !important;
      }
    }
  }

  .section-right {
    .secondary-slider {
      margin-left: calc((100vw - 345px) / 2);
    }
  }

  .secondary-slider {
    .slick-dots {
      bottom: -$size40;
    }
  }
}
