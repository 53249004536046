.fancy-box {
  padding: 24px;
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  position: relative;
  transform: translateY(84px);
  transition: 3s all;
  transition-timing-function: ease-out;

  &.animate {
    transform: translateY(0);

    .fancy-box--inner {
      animation: fancyBoxInnerFrames 0.4s ease-out forwards;
    }
    &:before,
    &:after {
      animation: fancyBoxSudoFrames 0.8s 1s ease-out forwards;
    }

    .fancy-box--abbrev {
      animation: fancyBoxAbbrevFrames 0.4s 0.8s ease-out forwards;
    }
  }

  &--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 60px;
    background-color: white;
    position: relative;
    z-index: 1;
    border: 0 solid white;
  }

  &:before,
  &:after {
    content: '';
    width: 120px;
    height: 120px;
    background-image: $gradient-pattern-image;
    background-size: $gradient-pattern-size;
    position: absolute;
    opacity: 0;
  }

  &:before {
    top: 0;
    left: 0;
    transform: translate(48px, 48px);
  }

  &:after {
    right: 0;
    bottom: 0;
    transform: translate(-48px, -48px);
  }

  .fancy-box--abbrev {
    opacity: 0;
  }
}

@keyframes fancyBoxSudoFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
    background-image: $gradient-pattern-image;
    background-size: $gradient-pattern-size;
  }
}

@keyframes fancyBoxInnerFrames {
  0% {
    border: 0 solid white;
  }
  100% {
    border: 12px solid $primary;
  }
}

@keyframes fancyBoxAbbrevFrames {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include media-breakpoint-up(md) {
  .fancy-box {
    padding: $size32;
    min-width: 264px;
    max-width: 264px;
    height: 264px;
    margin: 0px $size80;

    &--inner {
      font-size: 76px;
    }

    &:after,
    &:before {
      width: 172px;
      height: 172px;
    }

    &.less-margin {
      padding: $size32;
      margin-right: 50px;
      margin-left: 70px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .fancy-box {
    min-width: 304px;
    max-width: 304px;
    height: 304px;
  }
}

@include media-breakpoint-up(xl) {
  .fancy-box {
    padding: 48px;
    min-width: 348px;
    max-width: 348px;
    height: 348px;
    margin-right: 137px;
    margin-left: 180px;
  }
}
