.react-card-flip {
  flex: 1;
  overflow: hidden;
}

.react-card-front,
.react-card-back {
  display: flex;
  flex-direction: column;
  padding-left: $size80;

  > div {
    //flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}
