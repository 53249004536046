// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------

.landing-wrapper {
  overflow: hidden;
  position: relative;
  height: 100%;

  > div {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 0;
  }
}
