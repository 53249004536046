// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

// Font Classes
.condensed-200 {
  font-family: 'Gotham Cond SSm A', 'Gotham Cond SSm B';
  font-style: normal;
  font-weight: 200;
}
.extra-narrow-200 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 200;
}
.extra-narrow-300 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 300;
}
.extra-narrow-500 {
  font-family: 'Gotham XNarrow SSm A', 'Gotham XNarrow SSm B';
  font-style: normal;
  font-weight: 500;
}
.narrow-200 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 200;
}
.narrow-300 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 300;
}
.narrow-400 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 400;
}
.narrow-500 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 500;
}
.narrow-700 {
  font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B';
  font-style: normal;
  font-weight: 700;
}
.gotham-200 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 200;
}
.gotham-300 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 300;
}
.gotham-400 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 400;
}
.gotham-700 {
  font-family: 'Gotham SSm A', 'Gotham SSm B';
  font-style: normal;
  font-weight: 700;
}
