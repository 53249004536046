.textarea-wrapper {
  label {
    margin-bottom: $size8;
  }

  textarea {
    border: 1px solid;
    border-color: rgba($primary, 0.2);
    border-radius: 5px;
    display: block;
    width: 100%;
    resize: none;
    transition: 0.4s border-color;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: $size16;
      color: rgba($primary, 0.2);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: $size16;
      color: rgba($primary, 0.2);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: $size16;
      color: rgba($primary, 0.2);
    }

    &:focus {
      border-color: $primary;
    }
  }
}
