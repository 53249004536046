.main-slider {
  position: absolute !important;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 100%;

  // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  // height: calc(var(--vh, 1vh) * 100);
  transition: all 300ms ease-out;

  .slick-list,
  .slick-track,
  .slick-slide,
  .slide-inner {
    // height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    // height: calc(var(--vh, 1vh) * 100);

    height: 100%;
    transition: all 300ms ease-out;
  }

  .slick-arrow {
    display: none !important;
  }

  .slide-inner {
    position: relative;
  }

  .slide-inner--image {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: center top;

    &.object-bottom {
      object-position: center bottom !important;
    }
  }

  .slide-inner--label {
    position: absolute;
    // bottom: $size32;
    bottom: 20%;
    height: $size40;
    left: 0px;
    color: white;
    font-size: $size20;
    padding-left: $size32;
    padding-right: $size24;
    line-height: 39px;
    transition: 0.8s all;
    background-image: $gradient-pattern-landing-mobile;
    background-size: 5.66px 5.66px;
    text-align: right;
    min-width: 230px;
    max-width: 230px;

    @include media-breakpoint-up(sm) {
      bottom: $size32;
    }

    @include media-breakpoint-up(md) {
      bottom: 100px;
      font-size: $size40;
      padding-left: $size80;
      height: $size80;
      line-height: $size80;
      min-width: 420px;
      max-width: 420px;
      background-image: $gradient-pattern-image-dark;
      background-size: 8.49px 8.49px;
    }

    @include mobile-landscape() {
      bottom: 10%;
    }
  }

  .slide-inner--info {
    position: absolute;
    left: $size32;
    top: 13%;
    color: white;
    transform: translate3d(-200%, 0, 0);

    @include media-breakpoint-up(md) {
      left: $size80;
      top: $size32;
    }

    @include mobile-landscape() {
      top: 30%;
    }

    &--divider {
      height: $size4;
      width: $size24;
      background-color: white;
      margin-top: $size22;
      margin-bottom: $size8;
    }
  }

  .slick-dots {
    position: absolute;
    left: $size32;
    top: 42vh;
    padding: 0;
    display: none !important;

    @include media-breakpoint-up(sm) {
      display: block !important;
    }

    @include media-breakpoint-up(md) {
      left: $size80;
    }

    button {
      padding: 0px;
      color: transparent;
      border-radius: 0px;
      border: none;
      width: 1px;
      overflow: hidden;
      height: $size28;
      margin-bottom: $size16;
      outline: none;
      box-shadow: none;
      transition: 0.3s width;
      background: white;
    }

    li {
      list-style: none;
      width: $size8;

      &.slick-active,
      &:hover {
        button {
          width: $size6;
        }
      }
    }
  }

  .slick-current {
    .slide-inner--info {
      transition: transform 0.55s cubic-bezier(0.6, 0.3, 0.11, 0.86);
      transform: translate3d(0, 0, 0);
    }
  }
}
